// @ts-check
import forEach from 'lodash/forEach';

/**
 * @typedef {import('./responses').Element} Element
 */

/**
 * @param {import("@zedoc/form-values").FormValues<Element>} elements
 * @return {number}
 */
function getMaxSequenceNo(elements) {
  let maxSequenceNo = -1;
  forEach(elements, (element) => {
    if (
      element &&
      element.sequenceNo !== undefined &&
      element.sequenceNo > maxSequenceNo
    ) {
      maxSequenceNo = element.sequenceNo;
    }
    if (element._elements) {
      const sequenceNo = getMaxSequenceNo(element._elements);
      if (sequenceNo > maxSequenceNo) {
        maxSequenceNo = sequenceNo;
      }
    }
  });
  return maxSequenceNo;
}

export default getMaxSequenceNo;
