import union from 'lodash/union';
import CalendarDateSchema from '../models/CalendarDateSchema';
import {
  FILTER_TYPES,
  FILTER_CONDITIONS,
  SORTER_TYPES,
  SORTING_ORDERS,
} from '../constants';

/**
 * @param {import('json-schema-to-ts').JSONSchema7 & object} oldSchema
 * @param {import('json-schema-to-ts').JSONSchema7 & object} newSchema
 * @returns {import('json-schema-to-ts').JSONSchema7 & object}
 */
export function mergeObjectSchemas(oldSchema, newSchema) {
  if (
    !oldSchema ||
    oldSchema.type !== 'object' ||
    !newSchema ||
    newSchema.type !== 'object'
  ) {
    throw new Error('Both schemas must be of type object');
  }
  return {
    type: 'object',
    definitions: {
      ...oldSchema.definitions,
      ...newSchema.definitions,
    },
    required: union(oldSchema.required, newSchema.required),
    properties: {
      ...oldSchema.properties,
      ...newSchema.properties,
    },
  };
}

export const collationSchema = /** @type {const} */ ({
  type: 'object',
  required: ['locale'],
  properties: {
    locale: {
      type: 'string',
      minLength: 1,
    },
    strength: {
      type: 'number',
    },
  },
});

/**
 * @param {string} schemaRef
 * @returns {import('json-schema-to-ts').JSONSchema7}
 */
export const makeFilterSchema = (schemaRef) => ({
  type: 'object',
  required: ['type'],
  properties: {
    id: {
      type: 'string',
    },
    name: {
      type: 'string',
    },
    type: {
      type: 'string',
      enum: FILTER_TYPES,
    },
    condition: {
      type: 'string',
      enum: FILTER_CONDITIONS,
    },
    state: {
      type: 'object',
      properties: {
        exclude: {
          type: 'array',
          items: {
            type: ['string', 'boolean', 'number', 'null'],
          },
        },
        include: {
          type: 'array',
          items: {
            type: ['string', 'boolean', 'number', 'null'],
          },
        },
        start: {
          bsonType: 'date',
        },
        end: {
          bsonType: 'date',
        },
        dateStart: CalendarDateSchema,
        dateEnd: CalendarDateSchema,
        timezone: {
          // NOTE: If present, it can be used to map YYYY-MM-DD strings to a timestamps
          //       before the actual condition is evaluated.
          type: 'string',
        },
        text: {
          type: 'string',
        },
        exists: {
          type: 'boolean',
        },
        tagName: {
          type: 'string',
        },
        tagType: {
          type: 'string',
        },
        threshold: {
          anyOf: [{ type: ['string', 'number'] }, { bsonType: 'date' }],
        },
      },
    },
    settings: {
      type: 'object',
      properties: {
        id: {
          type: 'string',
        }, // either custom variable id or native property key
        filters: {
          type: 'array',
          items: {
            $ref: schemaRef,
          },
        },
        namespace: {
          type: 'string',
        },
        valueKey: {
          type: 'string',
        },
        valueType: {
          type: 'string',
          enum: ['number', 'string', 'boolean', 'array'],
        },
        // NOTE: If valueType = array, this further specifies the type of array items
        arrayItemsType: {
          type: 'string',
          enum: ['number', 'string', 'boolean'],
        },
        labelKey: {
          type: 'string',
        },
        tagNameKey: {
          type: 'string',
        },
        tagTypeKey: {
          type: 'string',
        },
      },
    },
    collation: collationSchema,
  },
});

export const sorterSchema = /** @type {const} */ ({
  type: 'object',
  properties: {
    type: {
      type: 'string',
      enum: SORTER_TYPES,
    },
    order: {
      type: 'string',
      enum: SORTING_ORDERS,
    },
    settings: {
      type: 'object',
      properties: {
        id: {
          type: 'string',
        },
      },
    },
    collation: collationSchema,
  },
});

export const searchApiSchema = /** @type {const} */ ({
  type: 'object',
  definitions: {
    filter: makeFilterSchema('#/definitions/filter'),
  },
  properties: {
    filters: {
      type: 'array',
      items: { $ref: '#/definitions/filter' },
    },
    sorter: sorterSchema,
    pageIndex: {
      type: 'number',
      minimum: 0,
    }, // 0, 1, 2, 3, etc.
    resultsPerPage: {
      type: 'number',
      minimum: 0,
    },
    controlId: {
      type: 'string',
    },
    version: {
      type: 'string',
    },
  },
});

export const searchFilterOptionsApiSchema = mergeObjectSchemas(
  searchApiSchema,
  {
    type: 'object',
    required: ['filter'],
    properties: {
      searchText: {
        type: 'string',
      },
      filter: {
        $ref: '#/definitions/filter',
      },
    },
  },
);
