/* eslint-disable react/jsx-props-no-spreading */
import styled, { createGlobalStyle } from 'styled-components';
import { QuestionCircle } from 'styled-icons/fa-regular';
import { Close } from 'styled-icons/material';
import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'antd/es/modal';
import 'antd/es/modal/style/css';
import { useTranslation } from 'react-i18next';
import Tooltip from '../Tooltip';
import Button from '../Button';
import { theme, max } from '../../utilsClient/cssHelpers';
import { default as themeObject } from '../../utilsClient/theme';

const StyledModal = styled(Modal)`
  /* TODO: Remove after removing antd */
  && {
    position: static;
    padding-bottom: 0 !important;

    .ant-modal-footer button + button {
      margin-bottom: 6px;
      margin-left: 6px;
    }

    .ant-modal-footer {
      padding: ${theme('space.3')};
    }

    /* Color Theme */

    .ant-modal-content {
      position: static;
      background-color: ${theme('color.surface')};
      border-radius: ${theme('border.radius.2')};
    }

    .ant-modal-body {
      background-color: ${theme('color.background')};
      color: ${theme('color.onBackground')};
      padding: ${theme('space.3')};
      max-height: ${theme('screen.sm')}px;
      overflow-y: auto;
    }

    .ant-modal-header {
      background-color: transparent;
    }

    .ant-modal-title,
    .ant-modal-close-x {
      color: ${theme('color.onSurface')};
    }

    .ant-modal-close-x {
      line-height: 70px;
      height: 70px;
      width: 70px;
    }

    ${max('sm')`
      min-width: calc(100% - ${theme('rhythm.base') * 2});
    `}
  }
`;

const GlobalStyle = createGlobalStyle`
  ${(props) =>
    props.direction === 'rtl' &&
    `
    .ant-modal {
      text-align: initial !important;
    }

    .ant-modal-close {
      right: initial !important;
      left: 0 !important;
    }
  `}
`;

const StyledTooltip = styled(Tooltip)`
  margin-inline-start: ${theme('space.1')};
  display: flex;
`;

const ModalAntd = (props) => {
  const { t, i18n } = useTranslation();

  const {
    footer,
    okText,
    okType,
    cancelText,
    onCancel,
    onOk,
    isOkDisabled,
    confirmLoading,
    hideFooter,
    title,
    tooltip,
    width,
  } = props;

  const defaultWidth = themeObject.screen.sm;
  const direction = i18n.dir(i18n.language);

  return (
    <>
      <GlobalStyle direction={direction} />
      <StyledModal
        {...props}
        centered
        destroyOnClose
        closable={false}
        width={width || defaultWidth}
        title={
          tooltip ? (
            <div className="cluster-6 justify-between">
              <p className="flex items-center">
                <span>{title}</span>
                {tooltip && (
                  <StyledTooltip title={tooltip} size="large">
                    <QuestionCircle className="w-4 h-4" />
                  </StyledTooltip>
                )}
              </p>
              <Button
                aria-label="Close"
                type="link"
                icon={<Close />}
                onClick={onCancel}
              />
            </div>
          ) : (
            <div className="cluster-6 justify-between">
              <span>{title}</span>
              <Button
                aria-label="Close"
                type="link"
                icon={<Close />}
                onClick={onCancel}
              />
            </div>
          )
        }
        footer={
          hideFooter ? null : (
            <div
              className="cluster-4 justify-end overflow-initial flex-wrap"
              style={{ overflow: 'initial' }}
            >
              {footer || (
                <>
                  <Button data-testid="button-cancel" onClick={onCancel}>
                    {cancelText || t('cancel')}
                  </Button>
                  <Button
                    data-testid="button-ok"
                    type={okType}
                    onClick={onOk}
                    disabled={isOkDisabled}
                    loading={confirmLoading}
                  >
                    {okText || t('ok')}
                  </Button>
                </>
              )}
            </div>
          )
        }
      />
    </>
  );
};

ModalAntd.propTypes = {
  footer: PropTypes.node,
  okText: PropTypes.string,
  okType: PropTypes.string,
  cancelText: PropTypes.string,
  onCancel: PropTypes.func,
  onOk: PropTypes.func,
  isOkDisabled: PropTypes.bool,
  confirmLoading: PropTypes.bool,
  hideFooter: PropTypes.bool,
  title: PropTypes.node.isRequired,
  tooltip: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ModalAntd.defaultProps = {
  footer: null,
  okType: 'primary',
  okText: '',
  cancelText: '',
  onCancel: () => {},
  onOk: () => {},
  isOkDisabled: false,
  confirmLoading: false,
  hideFooter: false,
  tooltip: null,
  width: null,
};

export default ModalAntd;
