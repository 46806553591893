import AnswerValue from './AnswerValue';

/**
 * @typedef {import('json-schema-to-ts').FromSchema<typeof Answer>} TypeAnswer
 */

const Answer = /** @type {const} */ ({
  type: 'object',
  properties: {
    value: AnswerValue,
    other: AnswerValue,
    text1: {
      type: 'string',
    },
    text2: {
      type: 'string',
    },
    // NOTE: This field can be used when answer represents a formula
    //       evaluation result which could have ended in an error.
    error: {
      type: 'object',
    },
  },
});

export default Answer;
