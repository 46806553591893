import last from 'lodash/last';
import each from 'lodash/each';
import some from 'lodash/some';
import reverse from 'lodash/reverse';
import { QUESTION_NUMBERING_STYLE__ALPHABET } from '../../constants';
import { numbering } from '../../utils/numbering';
import createValueBox from '../../utils/createValueBox';

function createEmptySection(id) {
  return {
    id,
    level: 0,
    children: [],
    nIndexed: 0, // number if items indexed so far
  };
}

const identity = (x) => x;

class QuestionsHierarchy {
  /**
   * We are expecting the doc to have two properties: questions and rootSectionId.
   */
  constructor(
    doc,
    {
      rootNumberingStyle = QUESTION_NUMBERING_STYLE__ALPHABET,
      transform = null,
      sectionIdField = 'sectionId',
      questionIdField = 'id',
      skipNumberingField = 'skipNumbering',
      numberingStyleField = 'numberingStyle',
    } = {},
  ) {
    Object.assign(this, doc);
    Object.defineProperty(this, 'raw', {
      value: this.constructor.getRawDoc(doc),
    });

    const { rootSectionId } = this;
    const sectionsById = {
      '': createEmptySection(''),
      [rootSectionId]: createEmptySection(rootSectionId),
    };

    const questionsById = {};
    const hierarchy = {};

    if (!this.questions) {
      this.questions = [];
    } else if (transform) {
      this.questions = this.questions.map(transform);
    }

    this.questions.forEach((question) => {
      const id = question[questionIdField];
      questionsById[id] = question;
      if (question.isContainer()) {
        sectionsById[id] = createEmptySection(id);
      }
    });

    this.questions.forEach((question) => {
      const id = question[questionIdField];
      const sectionId = question[sectionIdField] || '';
      const section = sectionsById[sectionId];
      const parent = questionsById[sectionId];
      if (section) {
        const questionHierarchy = sectionsById[id] || {
          id,
        };
        if (parent) {
          questionHierarchy.parent = parent;
          questionHierarchy.parentId = sectionId;
          questionHierarchy.collectionQuestionId = parent.isCollection()
            ? parent.id
            : parent.collectionQuestionId;
          // NOTE: Parent numbering style may not be set. In that case
          //       we want to use the default numbering style.
          questionHierarchy.style =
            parent[numberingStyleField] || rootNumberingStyle;
          questionHierarchy.level = section.level + 1;
        } else {
          questionHierarchy.style = rootNumberingStyle;
          questionHierarchy.level = 1;
        }
        const skipNumbering =
          skipNumberingField && question[skipNumberingField];
        if (!skipNumbering) {
          questionHierarchy.numberingIndex = sectionsById[sectionId].nIndexed;
          section.nIndexed += 1;
        }
        questionHierarchy.index = section.children.length;
        section.children.push(question);
        hierarchy[id] = questionHierarchy;
      }
    });

    // NOTE: We are using "defineProperty" rather than assignment
    //       to make sure they're not enumerable. If not provided
    //       explicitly, "enumerable" defaults to "false".

    Object.defineProperty(this, 'meta', {
      value: {
        questionIdField,
        questionsById,
        sectionsById,
        hierarchy,
      },
    });
  }

  static getRawDoc(doc) {
    let rawDoc = doc;
    while (rawDoc instanceof QuestionsHierarchy) {
      rawDoc = rawDoc.raw;
    }
    return rawDoc;
  }

  static createClosureGenerator(sectionIdField) {
    return (questions, ids) => {
      const closure = {
        ...ids,
      };
      const parents = {};
      each(questions, (question) => {
        parents[question.id] = question[sectionIdField];
      });
      each(questions, (question) => {
        const ancestors = [question.id];
        let parentId = question[sectionIdField];
        while (parentId && !closure[parentId]) {
          ancestors.push(parentId);
          parentId = parents[parentId];
        }
        if (parentId && closure[parentId]) {
          each(ancestors, (id) => {
            closure[id] = true;
          });
        }
      });
      return closure;
    };
  }

  getQuestionsWithEmptySectionId() {
    return this.meta.sectionsById[''].children;
  }

  getSubQuestionsIncludingSelf(sectionId) {
    const question = this.getQuestionById(sectionId);
    if (question) {
      // may be null for root section
      return [question, ...this.getAllSubQuestions(sectionId)];
    }
    return this.getAllSubQuestions(sectionId);
  }

  getChildQuestions(sectionId) {
    const section = this.meta.sectionsById[sectionId || this.rootSectionId];
    if (section) {
      return section.children;
    }
    return [];
  }

  getQuestionById(questionId) {
    return this.meta.questionsById[questionId];
  }

  getQuestionsHierarchy(questionId) {
    return this.meta.hierarchy[questionId];
  }

  getSectionId(questionId) {
    const hierarchy = this.getQuestionsHierarchy(questionId);
    return hierarchy && (hierarchy.parentId || this.rootSectionId);
  }

  getIndexInSection(questionId) {
    const hierarchy = this.getQuestionsHierarchy(questionId);
    return hierarchy && hierarchy.index;
  }

  getFirstQuestion(sectionId) {
    return this.getChildQuestions(sectionId)[0];
  }

  getLastQuestion(sectionId) {
    return last(this.getChildQuestions(sectionId));
  }

  getPathForQuestionId(questionId) {
    const sequence = this.getFullNumberingForQuestionId(questionId);
    if (sequence) {
      return sequence.join('.');
    }
    return '';
  }

  getFullNumberingForQuestionId(questionId) {
    const hierarchy = this.getQuestionsHierarchy(questionId);
    if (!hierarchy) {
      return [];
    }
    if (hierarchy.numberingIndex === undefined) {
      return null;
    }
    const sequence = this.getFullNumberingForQuestionId(hierarchy.parentId);
    if (sequence) {
      return [
        ...sequence,
        numbering(hierarchy.style, hierarchy.numberingIndex),
      ];
    }
    return null;
  }

  getNumberingForQuestionId(questionId) {
    const hierarchy = this.getQuestionsHierarchy(questionId);
    if (!hierarchy || hierarchy.numberingIndex === undefined) {
      return '';
    }
    return numbering(hierarchy.style, hierarchy.numberingIndex);
  }

  getLevelInHierarchy(questionId) {
    const hierarchy = this.getQuestionsHierarchy(questionId);
    if (!hierarchy) {
      return NaN;
    }
    return hierarchy.level;
  }

  getClosestCollectionQuestionId(questionId) {
    const hierarchy = this.getQuestionsHierarchy(questionId);
    if (!hierarchy) {
      return null;
    }
    return hierarchy.collectionQuestionId;
  }

  isTopLevel(questionId) {
    const question = this.getQuestionById(questionId);
    if (question) {
      return this.getLevelInHierarchy(questionId) === 1;
    }
    return false;
  }

  isTopLevelSection(questionId) {
    const question = this.getQuestionById(questionId);
    if (question) {
      return question.isSection() && this.getLevelInHierarchy(questionId) === 1;
    }
    return false;
  }

  getNextSectionId(currentSectionId) {
    const sections = [];
    this.forEachQuestion((question) => {
      // Also check so next section is not a parent section
      if (
        question.isSection() &&
        !this.getAllSubSections(question.id).length &&
        question.isVisible()
      ) {
        sections.push(question);
      }
    });

    const currentSectionIndex = sections.findIndex(
      (section) => section.id === currentSectionId,
    );
    const nextSection = sections[currentSectionIndex + 1];

    return nextSection && nextSection.id;
  }

  /**
   * Return all questions in the hierarchy that are under the given section.
   * It must be a valid section though (possibly rootSectionId). If no value,
   * or an invalid id is provided the function will return an empty array.
   */
  getAllSubQuestions(sectionId, { stopRecursion, filterQuestions } = {}) {
    if (sectionId === undefined) {
      return [];
    }
    return this.mapQuestions(identity, {
      sectionId,
      stopRecursion,
      filterQuestions,
    });
  }

  getAllSubSections(sectionId) {
    if (sectionId === undefined) {
      return [];
    }
    const questions = [];
    this.forEachQuestion(
      (question) => {
        if (question.isSection()) {
          questions.push(question);
        }
      },
      {
        sectionId,
      },
    );
    return questions;
  }

  hasNestedContainers(sectionId) {
    const childQuestions = this.getChildQuestions(sectionId);
    return some(
      childQuestions,
      (question) => question.isSection() || question.isCollection(),
    );
  }

  hasSubSections(sectionId) {
    return this.getAllSubSections(sectionId).length > 0;
  }

  getParentSectionsIds() {
    const sectionsIds = this.mapQuestions((question) => question.id, {
      filterQuestions: (question) =>
        question.isVisible() && question.isSection(),
    });

    return sectionsIds.filter(
      (sectionId) => this.hasSubSections(sectionId) && sectionId,
    );
  }

  /**
   * Returns a set of questions that are ancestors (or equal) to any of the provided questions ids.
   * @param {String[]} listOfIds
   * @param {Object} [options]
   * @param {Function} [options.filterQuestions] - narrow down to questions satisfying the given predicate
   * @returns {Object} questionsById
   */
  getAllQuestionsInHierarchy(listOfIds, { filterQuestions } = {}) {
    const questionsById = {};
    listOfIds.forEach((id) => {
      if (!questionsById[id]) {
        let hierarchy = this.getQuestionsHierarchy(id);
        while (hierarchy && !questionsById[hierarchy.id]) {
          const question = this.getQuestionById(hierarchy.id);
          if (!filterQuestions || filterQuestions(question)) {
            questionsById[hierarchy.id] = question;
          }
          hierarchy =
            hierarchy.parentId &&
            this.getQuestionsHierarchy(hierarchy.parentId);
        }
      }
    });
    return questionsById;
  }

  /**
   * Performs reduce algorithm starting at root, up to the given question.
   * @param {String} questionId
   * @param {Function} reducer
   * @param {Object} options
   * @param {Function} options.filterQuestions
   * @param {*} options.initialValue
   */
  reduceUpToQuestion(
    questionId,
    reducer,
    { filterQuestions, initialValue } = {},
  ) {
    const valueBox = createValueBox();
    const questionIds = this.getParentIdsWhere(questionId, filterQuestions);
    if (questionId) {
      const question = this.getQuestionById(questionId);
      if (question) {
        if (!filterQuestions || filterQuestions(question)) {
          questionIds.push(questionId);
        }
      }
    }
    let currentValue = initialValue;
    for (let i = 0; i < questionIds.length; i += 1) {
      const question = this.getQuestionById(questionIds[i]);
      const key = reducer(currentValue, question, valueBox.put);
      if (valueBox.is(key)) {
        return valueBox.get();
      }
      currentValue = key;
    }
    return currentValue;
  }

  /**
   * Returns ids of all ancestors of the given question, that fulfills the given predicate.
   * The ids are returned in order from root to the question itself, and the questionId is
   * never included.
   * @param {String} questionId
   * @param {Function} predicate
   * @returns {String[]}
   */
  getParentIdsWhere(questionId, predicate) {
    const parentIds = [];
    let hierarchy = this.getQuestionsHierarchy(questionId);
    if (hierarchy) {
      hierarchy =
        hierarchy.parentId && this.getQuestionsHierarchy(hierarchy.parentId);
    }
    while (hierarchy) {
      const question = this.getQuestionById(hierarchy.id);
      if (!predicate || predicate(question)) {
        parentIds.push(hierarchy.id);
      }
      hierarchy =
        hierarchy.parentId && this.getQuestionsHierarchy(hierarchy.parentId);
    }
    return reverse(parentIds);
  }

  /**
   * Check if any of the ancestors fulfills the given predicate.
   * @param {String} questionId
   * @param {Function} predicate
   * @returns {Boolean}
   */
  someAncestor(questionId, predicate) {
    return this.reduceUpToQuestion(
      questionId,
      (currentValue, question, earlyReturn) => {
        if (questionId !== question.id && predicate(question)) {
          return earlyReturn(true);
        }
        return currentValue;
      },
      {
        initialValue: false,
      },
    );
  }

  /**
   * Check if any of the ancestors (or the question itself) fulfills the given predicate.
   * @param {String} questionId
   * @param {Function} predicate
   * @returns {Boolean}
   */
  someAncestorOrSelf(questionId, predicate) {
    return this.reduceUpToQuestion(
      questionId,
      (currentValue, question, earlyReturn) => {
        if (predicate(question)) {
          return earlyReturn(true);
        }
        return currentValue;
      },
      {
        initialValue: false,
      },
    );
  }

  /**
   * Check if the given question is ancestor of another question.
   * @param {String} questionId
   * @param {String} anotherQuestionId
   * @returns {Boolean}
   */
  isAncestorOf(questionId, anotherQuestionId) {
    return this.someAncestor(
      anotherQuestionId,
      (question) => question.id === questionId,
    );
  }

  forEachQuestion(
    action,
    {
      sectionId = this.rootSectionId,
      recursive = true,
      filterQuestions,
      stopRecursion,
    } = {},
  ) {
    let index = 0;
    this.getChildQuestions(sectionId).forEach((question) => {
      if (typeof filterQuestions !== 'function' || filterQuestions(question)) {
        action(question, index);
        index += 1;
      }
      if (
        recursive &&
        (typeof stopRecursion !== 'function' || !stopRecursion(question))
      ) {
        const questionId = question[this.meta.questionIdField];
        // TODO: Why can't we use questionId as sectionId for the recursive call?
        const hierarchy = this.getQuestionsHierarchy(questionId);
        if (hierarchy && hierarchy.id) {
          const nQuestions = this.forEachQuestion(action, {
            baseIndex: index,
            recursive,
            filterQuestions,
            stopRecursion,
            sectionId: hierarchy.id,
          });
          index += nQuestions;
        }
      }
    });
    return index;
  }

  mapQuestions(
    transform,
    {
      sectionId = this.rootSectionId,
      recursive = true,
      questions = [],
      filterQuestions,
      stopRecursion,
    } = {},
  ) {
    this.getChildQuestions(sectionId).forEach((question) => {
      if (typeof filterQuestions !== 'function' || filterQuestions(question)) {
        questions.push(transform(question, questions.length));
      }
      if (
        recursive &&
        (typeof stopRecursion !== 'function' || !stopRecursion(question))
      ) {
        const questionId = question[this.meta.questionIdField];
        const hierarchy = this.getQuestionsHierarchy(questionId);
        if (hierarchy && hierarchy.id) {
          this.mapQuestions(transform, {
            recursive,
            questions,
            filterQuestions,
            stopRecursion,
            sectionId: hierarchy.id,
          });
        }
      }
    });
    return questions;
  }
}

export default QuestionsHierarchy;
