export const VARIABLE_TYPE__STRING = 'string';
export const VARIABLE_TYPE__NUMBER = 'number';
export const VARIABLE_TYPE__BOOLEAN = 'boolean';
export const VARIABLE_TYPE__DATE = 'date';
export const VARIABLE_TYPE__TIME = 'time';
export const VARIABLE_TYPE__DATE_TIME = 'dateTime';
export const VARIABLE_TYPE__VALUE_SET = 'valueSet'; // similar to FHIR/ValueSet
export const VARIABLE_TYPE__LOOKUP_TABLE = 'lookupTable'; // similar to FHIR/ValueSet
export const VARIABLE_TYPE__IRT_CONFIG = 'irtConfig';
export const VARIABLE_TYPE__CUSTOM = 'custom';

export const VARIABLE_TYPES = [
  VARIABLE_TYPE__STRING,
  VARIABLE_TYPE__NUMBER,
  VARIABLE_TYPE__BOOLEAN,
  VARIABLE_TYPE__DATE,
  VARIABLE_TYPE__TIME,
  VARIABLE_TYPE__DATE_TIME,
  VARIABLE_TYPE__VALUE_SET,
  VARIABLE_TYPE__LOOKUP_TABLE,
  VARIABLE_TYPE__IRT_CONFIG,
  VARIABLE_TYPE__CUSTOM,
];
