import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';
import map from 'lodash/map';
import React, { useRef, useMemo, useCallback } from 'react';
import { useDDPCall, useDDPSubscription } from '@zedoc/ddp-connector';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { useTranslation } from 'react-i18next';
import { getTimezone } from '@zedoc/date';
import { useAsyncValue } from '@zedoc/react-hooks';
import {
  apiAdminGetProject,
  apiAdminCreateProject,
  apiAdminUpdateProject,
} from '../../../common/api/admin_v1';
import {
  ADMIN_CREATE_PROJECT,
  ADMIN_REVIEW_PROJECT,
  ADMIN_UPDATE_PROJECT,
} from '../../../common/permissions';
import { default as ProjectSelect } from '../../../common/selectors/Project';
import Loading from '../../../common/components/Loading';
import Button from '../../../common/components/Button';
import FormFieldSearch from '../../forms/FormFieldSearch';
import { notifyError, notifySuccess } from '../../../utils/notify';
import branding from '../../../utils/branding';
import Form from '../../forms/Form';
import usePermissionsRealm from '../../../utils/usePermissionsRealm';
import usePermission from '../../../utils/usePermission';
import FormFieldText from '../../forms/FormFieldText';
import {
  PROJECT_STATE__ACTIVE,
  PROJECT_STATE__CONFIGURED,
} from '../../../common/schemata/Project';
import { getResumeToken } from '../../../common/utilsClient/ddp/selectors';
import Blueprint from '../../../common/sdk/blueprints/Blueprint';
import createHttpClient from '../../../common/controllers/createHttpClient';
import BlueprintDraft from '../../../common/sdk/blueprints/BlueprintDraft';
import Table from '../../../common/components/Table';
import Dialog from '../../Dialog';

const getTitle = (t, latestDraft) => {
  if (!latestDraft) {
    return t('editProject');
  }
  return t('editProjectUnpublished', {
    version: latestDraft.getVersion(),
  });
};

const apiClient = createHttpClient('/api/');

const EditProject = ({ projectId, onCancel, onSubmitted, visible }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const projectProperties = useRef();

  const project = useSelector(ProjectSelect.one().whereIdEquals(projectId));

  const { ready: projectReady } = useDDPSubscription(
    projectId &&
      apiAdminGetProject.withParams({
        projectId,
      }),
  );

  const { ddpCall, ddpIsPending } = useDDPCall();

  const { state, blueprintId } = project || {};

  const resumeToken = useSelector(getResumeToken);

  const { value: latestDraft, ready: latestDraftReady } =
    useAsyncValue(async () => {
      const client = apiClient.login({
        resumeToken,
      });
      return BlueprintDraft.getMaxVersionSatisfying(blueprintId, 'x', client);
    }, [blueprintId, resumeToken]);
  const {
    value: currentBlueprint,
    // ready: currentBlueprintReady,
  } = useAsyncValue(async () => {
    const client = apiClient.login({
      resumeToken,
    });
    return Blueprint.get(blueprintId, client);
  }, [blueprintId, resumeToken]);
  const {
    value: parentBlueprint,
    // ready: parentBlueprintReady,
  } = useAsyncValue(async () => {
    const client = apiClient.login({
      resumeToken,
    });
    return Blueprint.get(currentBlueprint.props.parentId, client);
  }, [currentBlueprint, resumeToken]);
  const {
    value: latestParentDraft,
    // ready: latestParentDraftReady,
  } = useAsyncValue(async () => {
    const client = apiClient.login({
      resumeToken,
    });
    return BlueprintDraft.getMaxVersionSatisfying(
      parentBlueprint.blueprintId,
      'x',
      client,
    );
  }, [parentBlueprint, resumeToken]);

  const allReady = projectReady && latestDraftReady;

  const handleOnCancel = useCallback(() => {
    if (onCancel) {
      onCancel(false);
    }
  }, [onCancel]);

  const handleOnSave = useCallback(
    (event, options = {}) => {
      if (ddpIsPending) {
        return;
      }
      if (projectId) {
        projectProperties.current
          .submit()
          .then((formValues) => {
            const { name, description, timezone, ...other } = formValues;
            return ddpCall(
              apiAdminUpdateProject.withParams(
                omitBy(
                  {
                    projectId,
                    state:
                      state === PROJECT_STATE__CONFIGURED
                        ? PROJECT_STATE__ACTIVE
                        : null,
                    ...other,
                  },
                  isNil,
                ),
              ),
            ).then(async (result) => {
              let draft = latestDraft;
              if (blueprintId) {
                const client = apiClient.login({
                  resumeToken,
                });
                const blueprint = await Blueprint.get(blueprintId, client);
                draft = await BlueprintDraft.getMaxVersionSatisfying(
                  blueprintId,
                  'x',
                  client,
                );
                if (!draft) {
                  draft = await blueprint.createDraft();
                }
                await blueprint.patchAllDrafts(
                  {
                    name,
                    description,
                    timezone,
                  },
                  {
                    versionRange: draft.getVersion(),
                  },
                );
              }
              if (onSubmitted) {
                return onSubmitted(result, {
                  ...options,
                  goToVersion: draft && draft.props.version,
                });
              }
              return undefined;
            });
          })
          .then(onCancel)
          .then(
            notifySuccess(
              t('confirmations:editProject.success', {
                context: branding,
              }),
            ),
          )
          .catch(notifyError());
      } else {
        projectProperties.current
          .submit()
          .then((formValues) => {
            return ddpCall(
              apiAdminCreateProject.withParams(
                omitBy(
                  {
                    state: PROJECT_STATE__ACTIVE,
                    ...formValues,
                  },
                  isNil,
                ),
              ),
            ).then(async (result) => {
              let draft;
              if (result.blueprintId) {
                const client = apiClient.login({
                  resumeToken,
                });
                // NOTE: This means that there's currently no draft for that blueprint.
                //       Let's create one with some default values.
                const blueprint = await Blueprint.get(
                  result.blueprintId,
                  client,
                );
                draft = await blueprint.createDraft();
              }
              if (onSubmitted) {
                return onSubmitted(result, {
                  ...options,
                  goToVersion: draft && draft.props.version,
                });
              }
              return undefined;
            });
          })
          .then(onCancel)
          .then(notifySuccess(t('confirmations:addProject.success')))
          .catch(notifyError());
      }
    },
    [
      projectProperties,
      latestDraft,
      blueprintId,
      projectId,
      onCancel,
      onSubmitted,
      state,
      t,
      ddpCall,
      ddpIsPending,
      resumeToken,
    ],
  );

  const { permissionsDomains } = usePermissionsRealm([ADMIN_CREATE_PROJECT]);

  const schema = useMemo(() => {
    const newSchema = {
      type: 'object',
      required: ['name', 'domains'],
      properties: {
        domains: {
          type: 'array',
          items: {
            type: 'string',
            anyOf: map(permissionsDomains, (domain) => {
              return {
                const: domain._id,
                title: domain.name,
              };
            }),
          },
        },
        name: {
          type: 'string',
        },
        description: {
          type: 'string',
        },
        timezone: {
          type: 'string',
        },
        billingCode: {
          type: 'string',
        },
        blueprintId: {
          type: 'string',
        },
        // blueprintVersion: {
        //   type: 'string',
        // },
        blueprintVersionRange: {
          type: 'string',
        },
      },
    };
    return newSchema;
  }, [permissionsDomains]);

  const initialValues = useMemo(() => {
    if (!allReady) {
      return null;
    }
    const allVariables = {
      timezone: getTimezone(),
    };
    if (project) {
      const {
        ownership,
        name,
        description,
        timezone,
        billingCode,
        blueprintVersionRange,
      } = project;
      Object.assign(allVariables, {
        domains: map(ownership, 'domain'),
        name,
        description,
        timezone,
        billingCode,
        blueprintId,
        blueprintVersionRange,
      });
    }
    return allVariables;
  }, [allReady, project, blueprintId]);

  const canUpdateProject = usePermission(ADMIN_UPDATE_PROJECT, {
    relativeTo: project && project.getDomains(),
  });
  const canReviewProject = usePermission(ADMIN_REVIEW_PROJECT, {
    relativeTo: project && project.getDomains(),
  });

  const fields = useMemo(() => {
    const newFields = {
      '': {
        children: [
          'domains',
          'name',
          'description',
          'timezone',
          'billingCode',
          blueprintId && 'blueprintId',
          blueprintId && 'blueprintVersionRange',
        ],
      },
      domains: {
        label: t('forms:belongsTo.label'),
        disabled: state === PROJECT_STATE__ACTIVE,
      },
      name: {
        label: t('forms:name.label'),
        disabled: state === PROJECT_STATE__ACTIVE,
      },
      description: {
        label: t('forms:description.label'),
        render: ({
          // eslint-disable-next-line react/prop-types
          forwardedRef,
          ...props
        }) => {
          return (
            <FormFieldText
              ref={forwardedRef}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
              type="freeText"
            />
          );
        },
        disabled: state === PROJECT_STATE__ACTIVE,
      },
      timezone: {
        label: t('forms:timezone.label'),
        // disabled: state === PROJECT_STATE__ACTIVE,
        render: ({
          // eslint-disable-next-line react/prop-types
          forwardedRef,
          ...props
        }) => {
          return (
            <FormFieldSearch
              ref={forwardedRef}
              valueSetId="zedoc/timezones@1.0.0"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
            />
          );
        },
        disabled: state === PROJECT_STATE__ACTIVE,
      },
      billingCode: {
        label: t('forms:projectReferenceCode.label'),
        disabled: !canUpdateProject,
      },
      blueprintId: {
        label: t('forms:blueprintId.label'),
        disabled: true,
      },
      // blueprintVersion: {
      //   label: t('forms:version.label'),
      // },
      blueprintVersionRange: {
        label: t('forms:versionRange.label'),
        disabled: !canUpdateProject,
      },
    };
    return newFields;
  }, [t, state, canUpdateProject, blueprintId]);

  const formName = projectId ? `edit_project_${projectId}` : 'add_project';

  const confirmLoading = !allReady || ddpIsPending;

  const handleOpenDraft = (id, version) => {
    let pathname = `/settings/blueprints/${id}`;

    if (version) {
      pathname += `?version=${version}`;
    }

    return dispatch(push(pathname));
  };
  const detailsDataSource = [
    currentBlueprint && {
      ...currentBlueprint.props,
      ...latestDraft?.props,
      type: 'Current',
    },
    parentBlueprint && {
      ...parentBlueprint.props,
      ...latestParentDraft?.props,
      type: 'Parent',
    },
  ].filter((item) => item);
  const detailsColumns = [
    {
      title: t('type'),
      key: 'type',
    },
    {
      title: t('name'),
      key: 'name',
    },
    {
      title: t('forms:identifier.label'),
      key: 'blueprintId',
    },
    canReviewProject && {
      render: (_, doc) => (
        <Button
          type="link"
          onClick={() => handleOpenDraft(doc.blueprintId, doc.version)}
        >
          {t('open')}
        </Button>
      ),
    },
  ].filter((column) => column);

  return (
    <Dialog
      title={
        project || !allReady ? getTitle(t, latestDraft) : t('createProject')
      }
      size="large"
      onCancel={handleOnCancel}
      visible={visible}
      okText={t('save')}
      onOk={handleOnSave}
      loading={confirmLoading}
      isOkDisabled={!canUpdateProject}
    >
      <div className="stack-6 w-full sm:w-1/2">
        <Form
          data-testid="project-properties"
          ref={projectProperties}
          name={formName}
          initialValues={initialValues}
          schema={schema}
          fields={fields}
        />
        {blueprintId && (
          <Table
            title="Blueprint Details"
            dataSource={detailsDataSource}
            columns={detailsColumns}
          />
        )}
        {!allReady && <Loading />}
      </div>
    </Dialog>
  );
};

EditProject.propTypes = {
  projectId: PropTypes.string,
  onCancel: PropTypes.func,
  onSubmitted: PropTypes.func,
  visible: PropTypes.bool,
};

EditProject.defaultProps = {
  projectId: null,
  onCancel: null,
  onSubmitted: null,
  visible: true,
};

export default EditProject;
