import { ReloadOutlined } from '@ant-design/icons';
import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Random } from '@zedoc/random';
import Button from '../../../components/Button';
import Filters from '../../../components/Filters';
import store from '../store';
import SavedFilters from '../../../components/SavedFilters';

const createGetFilters = (storeKey) => store.get(`${storeKey}`);
const createSetFilters = (storeKey) => store.create.set(`${storeKey}`);

const FiltersToolbar = ({
  storeKey,
  dashboardId,
  presets,
  onRefresh,
  refreshNeeded,
  optionsSelector,
  optionsSubscription,
}) => {
  const [key, setKey] = useState(null);
  const dispatch = useDispatch();

  const storeKeyFilters = `${storeKey}.filters`;
  const storeKeySelectedFilterId = `${storeKey}.selectedFilterId`;
  const selectedFilterId = useSelector(
    createGetFilters(storeKeySelectedFilterId),
  );

  const filters = useSelector(createGetFilters(storeKeyFilters));

  const handleOnChange = useCallback(
    (newFilters) => {
      dispatch(createSetFilters(storeKeyFilters)(newFilters));
    },
    [dispatch, storeKeyFilters],
  );
  const handleOnChangeSavedFilters = useCallback(
    (filterId) => {
      dispatch(createSetFilters(storeKeySelectedFilterId)(filterId));
    },
    [dispatch, storeKeySelectedFilterId],
  );
  const handleResetSavedFiltersId = useCallback(() => {
    handleOnChangeSavedFilters();
  }, [handleOnChangeSavedFilters]);

  const handleOnLoadFilters = useCallback(
    (newFilters) => {
      handleOnChange(newFilters);
      // NOTE: Force re-rendering <Filters> component, to make sure
      //       that the local state stays in sync.
      setKey(Random.id());
    },
    [setKey, handleOnChange],
  );

  const handleOnRefresh = useCallback(() => {
    if (onRefresh) {
      onRefresh();
    }
  }, [onRefresh]);

  return (
    <div className="stack-2">
      <div className="cluster-2">
        <Filters
          key={key}
          presets={presets}
          value={filters}
          onChange={handleOnChange}
          onSubmit={handleResetSavedFiltersId}
          optionsSelector={optionsSelector}
          optionsSubscription={optionsSubscription}
          onResetSavedFilters={handleOnChangeSavedFilters}
          extra={
            <SavedFilters
              dashboardId={dashboardId}
              filters={filters}
              onLoadFilters={handleOnLoadFilters}
              value={selectedFilterId}
              onChange={handleOnChangeSavedFilters}
            />
          }
        />
      </div>
      {onRefresh && (
        <Button
          type="primary"
          disabled={!refreshNeeded}
          icon={<ReloadOutlined />}
          onClick={handleOnRefresh}
        />
      )}
    </div>
  );
};

FiltersToolbar.propTypes = {
  storeKey: PropTypes.string.isRequired,
  dashboardId: PropTypes.string,
  presets: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  optionsSubscription: PropTypes.func,
  optionsSelector: PropTypes.shape({
    all: PropTypes.func,
  }),
  onRefresh: PropTypes.func,
  refreshNeeded: PropTypes.bool,
};

FiltersToolbar.defaultProps = {
  presets: [],
  optionsSubscription: () => {},
  optionsSelector: null,
  dashboardId: null,
  onRefresh: null,
  refreshNeeded: false,
};

export default FiltersToolbar;
