import some from 'lodash/some';
import {
  AUDIT_EVENT_TYPE__LOGIN,
  AUDIT_EVENT_TYPE__LOGOUT,
  AUDIT_EVENT_TYPE__GENERIC_ACTION,
  AUDIT_EVENT_TYPE__SEARCH,
  AUDIT_EVENT_TYPE__REVIEW,
} from '../../constants';
import {
  ACTION_RESULTS,
  ACTION_TYPES,
  ACTION_TYPE__LOGIN,
  ACTION_TYPE__LOGOUT,
  ACTION_TYPE__SEARCH,
  ACTION_TYPE__REVIEW,
  MESSAGE_TYPE__ACTION,
} from '../constants';
import Message from '../Message';

class MessageAction extends Message {
  constructor(doc) {
    super({
      ...doc,
      type: MESSAGE_TYPE__ACTION,
    });
  }

  isOfType(actionType) {
    if (typeof actionType === 'string') {
      return this.actionType === actionType;
    }
    if (Array.isArray(actionType)) {
      return some(actionType, (type) => type === this.actionType);
    }
    return false;
  }

  isModel(Model) {
    return this.model === Model.name;
  }

  getDocId() {
    return this.docId;
  }

  getAuditLogType() {
    switch (this.actionType) {
      case ACTION_TYPE__LOGIN:
        return AUDIT_EVENT_TYPE__LOGIN;
      case ACTION_TYPE__LOGOUT:
        return AUDIT_EVENT_TYPE__LOGOUT;
      case ACTION_TYPE__SEARCH:
        return AUDIT_EVENT_TYPE__SEARCH;
      case ACTION_TYPE__REVIEW:
        return AUDIT_EVENT_TYPE__REVIEW;
      default:
        return AUDIT_EVENT_TYPE__GENERIC_ACTION;
    }
  }

  toAuditLog(previousAuditLog) {
    const auditLog = super.toAuditLog(previousAuditLog);
    const meta = {
      ...auditLog.meta,
    };
    switch (this.actionType) {
      case ACTION_TYPE__SEARCH:
        if (this.meta) {
          Object.assign(meta, this.meta);
        }
        break;
      case ACTION_TYPE__LOGIN: {
        if (this.meta && this.meta.attempt) {
          meta.attempt = this.meta.attempt;
        }
        break;
      }
      default:
      // ...
    }
    return {
      ...auditLog,
      type: this.getAuditLogType(),
      event: {
        ...auditLog.event,
        code: this.actionType,
        result: this.actionResult,
      },
      // NOTE: If docId is missing, do not create subject field because objectId is always required
      ...(this.docId && {
        subject: {
          objectId: this.docId,
          model: this.model,
        },
      }),
      meta,
    };
  }
}

MessageAction.schema = {
  type: 'object',
  required: ['type', 'actionType'],
  properties: {
    type: {
      type: 'string',
      const: MESSAGE_TYPE__ACTION,
    },
    actionType: {
      type: 'string',
      enum: ACTION_TYPES,
    },
    actionResult: {
      type: 'string',
      enum: ACTION_RESULTS,
    },
    error: {
      type: 'object',
    }, // if actionResult is "failure", error should explain the reason
    docId: {
      type: 'string',
    },
    doc: {
      type: 'object',
    },
    model: {
      type: 'string',
    },
    relatedDocId: {
      type: 'string',
    },
    relatedDoc: {
      type: 'object',
    },
    relatedModel: {
      type: 'string',
    },
    meta: {
      type: 'object',
    },
    context: {
      type: 'object',
    },
  },
};

Message.types[MESSAGE_TYPE__ACTION] = MessageAction;

export default MessageAction;
