import ApiSpec from './ApiSpec';
import Id from '../schemata/Id';
import HashedPassword from '../schemata/HashedPassword';
import {
  PROJECT_REVIEW_DASHBOARD,
  ADMIN_UPDATE_USER_PHONES,
} from '../permissions';
import { CSV_RESPONSES_EXTRA_COLUMNS } from '../models/UserDashboard';

export const DDP_COLLECTION_CURRENT_USER = 'api.currentUser.CurrentUser';
export const DDP_COLLECTION_CURRENT_USER_ROLE =
  'api.currentUser.CurrentUserRole';
export const DDP_COLLECTION_CURRENT_USER_GROUP =
  'api.currentUser.CurrentUserGroup';
export const DDP_COLLECTION_CURRENT_USER_DASHBOARD =
  'api.currentUser.CurrentUserDashboard';

export const apiCurrentUserDetails = new ApiSpec({
  name: 'api.currentUser.details',
  permissions: [],
  schema: false,
});

export const apiCurrentUserPermissionsRealm = new ApiSpec({
  name: 'api.currentUser.permissionsRealm',
  permissions: [],
  schema: {
    type: 'object',
    required: ['permissions'],
    properties: {
      scope: {
        type: 'array',
        items: {
          type: 'string',
        },
      },
      permissions: {
        type: 'array',
        items: {
          type: 'string',
        },
        minItems: 1,
      },
    },
  },
});

export const checkUserCredentials = new ApiSpec({
  name: 'api.currentUser.checkUserCredentials',
  public: true,
  schema: {
    type: 'object',
    required: ['login', 'hashedPassword'],
    properties: {
      login: {
        type: 'string',
      },
      hashedPassword: HashedPassword,
    },
  },
});

export const apiCurrentUserGetToken = new ApiSpec({
  name: 'api.currentUser.getToken',
  amqp: 'api.currentUser.getToken',
  http: 'POST /identity/token',
  public: true,
  schema: {
    type: 'object',
    required: ['grantType'],
    properties: {
      userId: Id,
      hashedPassword: HashedPassword,
      grantType: {
        type: 'string',
        enum: ['refresh_token', 'client_credentials'],
      },
      refreshToken: {
        type: 'string',
      },
    },
  },
});

export const apiCurrentUserGetRealms = new ApiSpec({
  name: 'api.currentUser.getRealms',
  amqp: 'api.currentUser.getRealms',
  permissions: [],
  schema: {
    type: 'object',
    required: ['permissions'],
    properties: {
      permissions: {
        type: 'array',
        items: {
          type: 'string',
        },
        minItems: 1,
      },
    },
  },
});

export const apiCurrentUserValidateResumeToken = new ApiSpec({
  name: 'api.currentUser.validateResumeToken',
  amqp: 'api.currentUser.validateResumeToken',
  public: true,
  schema: {
    type: 'object',
    required: ['resumeToken'],
    properties: {
      resumeToken: {
        type: 'string',
      },
    },
  },
});

export const apiCurrentUserResumeTokenKeepAlive = new ApiSpec({
  name: 'api.currentUser.resumeTokenKeepAlive',
  amqp: 'api.currentUser.resumeTokenKeepAlive',
  schema: {
    type: 'object',
    required: ['resumeToken'],
    properties: {
      resumeToken: {
        type: 'string',
      },
      mapLocation: {
        type: 'object',
        required: ['type', 'coordinates'],
        properties: {
          type: {
            type: 'string',
            enum: ['Point'],
          },
          coordinates: {
            type: 'array',
            items: {
              type: 'number',
            },
            minItems: 0,
            maxItems: 2,
          },
        },
      },
    },
  },
});

// NOTE: We are simply wrapping a method that's implemented in Meteor internals.
export const forgotPassword = new ApiSpec({
  name: 'forgotPassword',
  schema: {
    type: 'object',
    properties: {
      email: {
        type: 'string',
        format: 'email',
      },
    },
  },
});

export const setNewPassword = new ApiSpec({
  name: 'api.currentUser.setNewPassword',
  schema: {
    type: 'object',
    required: ['hashedPassword'],
    properties: {
      hashedPassword: HashedPassword,
      // old password is not required for replacing temporary password
      hashedOldPassword: HashedPassword,
    },
  },
});

export const validateNewPassword = new ApiSpec({
  name: 'api.currentUser.validateNewPassword',
  schema: {
    type: 'object',
    required: ['hashedPassword'],
    properties: {
      hashedPassword: HashedPassword,
    },
  },
});

export const isUsingTemporaryPassword = new ApiSpec({
  name: 'api.currentUser.isUsingTemporaryPassword',
  schema: {
    type: 'object',
  },
});

export const setOptions = new ApiSpec({
  name: 'api.currentUser.setOptions',
  permissions: [],
  schema: {
    type: 'object',
    properties: {
      onboarded: {
        type: 'boolean',
      },
      pinnedItems: {
        type: 'object',
        properties: {
          projects: {
            type: 'array',
            items: {
              type: 'string',
            },
          },
        },
      },
    },
  },
});

export const apiCurrentUserUserDashboards = new ApiSpec({
  name: 'api.currentUser.userDashboards',
  resources: [PROJECT_REVIEW_DASHBOARD],
  schema: {
    type: 'object',
    required: ['dashboardId'],
    properties: {
      dashboardId: {
        type: 'string',
      },
    },
  },
});

export const apiCurrentUserUpdateUserDashboard = new ApiSpec({
  name: 'api.currentUser.updateUserDashboard',
  resources: [PROJECT_REVIEW_DASHBOARD],
  schema: {
    type: 'object',
    required: ['dashboardId'],
    properties: {
      dashboardId: {
        type: 'string',
      },
      savedCharts: {
        type: 'array',
        items: {
          type: 'object',
          required: ['id', 'label'],
          properties: {
            id: {
              type: 'string',
            },
            label: {
              type: 'string',
            },
            dataProvider: {
              type: 'string',
            },
            dataProviderCleanup: {
              type: 'string',
            },
          },
        },
      },
      savedFilters: {
        type: 'array',
        items: {
          type: 'object',
          required: ['id', 'label', 'filtersJSONString'],
          properties: {
            id: {
              type: 'string',
            },
            label: {
              type: 'string',
            },
            filtersJSONString: {
              type: 'string',
            },
          },
        },
      },
      csvResponsesOptions: {
        type: 'object',
        properties: {
          questionIdAsHeader: {
            type: 'boolean',
          },
          extraColumns: {
            type: 'array',
            items: {
              type: 'string',
              enum: CSV_RESPONSES_EXTRA_COLUMNS,
            },
          },
        },
      },
    },
  },
});

export const apiCurrentUserUpdateProfile = new ApiSpec({
  name: 'api.currentUser.updateProfile',
  permissions: [ADMIN_UPDATE_USER_PHONES],
  schema: {
    type: 'object',
    properties: {
      name: {
        type: 'string',
      },
      phone: {
        type: 'string',
        format: 'phone',
      },
      picture: {
        type: 'string',
      },
    },
  },
});

export const apiCurrentUserHelpdeskContent = new ApiSpec({
  name: 'api.currentUser.helpdeskContent',
  permissions: [],
  schema: {
    type: 'object',
    properties: {
      resultId: {
        type: 'string',
      },
    },
  },
});
