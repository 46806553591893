/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
import map from 'lodash/map';
import styled, { withTheme } from 'styled-components';
import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { theme as themeHelper } from '../../utilsClient/cssHelpers';

const Component = styled.div`
  display: grid;
  grid-gap: ${(props) => themeHelper(`space.${props.space}`)(props)};
  grid-template-columns: 100%;
  align-items: ${(props) => props.align};

  &.aboveMin {
    grid-template-columns: ${(props) =>
      `repeat(${props.sizing}, minmax(${themeHelper(`measure.${props.min}`)(
        props,
      )}, 1fr))`};
  }

  @supports (width: min(20rem, 100%)) {
    .grid {
      grid-template-columns: ${(props) =>
        `repeat(${props.sizing}, minmax(min(20rem, 100%), 1fr))`};
    }
  }
`;

const Grid = ({
  children,
  space,
  min,
  align,
  sizing,
  // eslint-disable-next-line react/prop-types
  theme,
  // eslint-disable-next-line react/prop-types
  className,
}) => {
  const ref = useRef(null);

  useEffect(() => {
    if ('ResizeObserver' in window) {
      // Create a proxy element to measure and convert
      // the `min` value (which might be em, rem, etc) to `px`
      const test = document.createElement('div');
      test.style.width = theme.measure[min];
      ref.current.appendChild(test);
      const minToPixels = test.offsetWidth;
      ref.current.removeChild(test);

      const resizeObserver = new ResizeObserver((entries) => {
        map(entries, (entry) => {
          // Get the element's current dimensions
          const { contentRect } = entry;
          // `true` if the container is wider than the minimum
          const isWide = contentRect.width > minToPixels;
          // toggle the class conditionally
          ref.current?.classList.toggle('aboveMin', isWide);
        });
      });

      resizeObserver.observe(ref.current);

      return () => resizeObserver.disconnect();
    }
  }, [min, theme.measure]);

  return (
    <Component
      className={`${className} aboveMin`}
      ref={ref}
      space={space}
      min={min}
      align={align}
      sizing={sizing}
    >
      {children}
    </Component>
  );
};

Grid.propTypes = {
  children: PropTypes.node.isRequired,
  space: PropTypes.oneOf([0, 1, 2, 3, 4, 5]),
  min: PropTypes.oneOf([0, 1, 2, 3, 4, 5]),
  align: PropTypes.string,
  sizing: PropTypes.oneOf(['auto-fit', 'auto-fill']),
};

Grid.defaultProps = {
  space: 3,
  min: 1,
  align: '',
  sizing: 'auto-fit',
};

export default withTheme(Grid);
