import map from 'lodash/map';
import PropTypes from 'prop-types';
import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  USER_PROPERTY__EMAIL,
  USER_PROPERTY__NAME,
  USER_PROPERTY__PHONE,
} from '../../../common/constants';
import { ADMIN_UPDATE_USER_PHONES } from '../../../common/permissions';
import { callMethod } from '../../../common/utilsClient/ddp/actions';
import { apiCurrentUserUpdateProfile } from '../../../common/api/currentUser';
import FormFieldText from '../../../components/forms/FormFieldText';
import FormItemReadOnly from '../../../components/forms/FormItemReadOnly';
import useForm from '../../../utils/useForm';
import { notifyError, notifySuccess } from '../../../utils/notify';
import Button from '../../../components/Button';
import UpdatePassword from '../../../components/dialogs/UpdatePassword';
import usePermission from '../../../utils/usePermission';
import CurrentUser from '../../../models/CurrentUser';
import { clearValue } from '../../../utils/sanitize';

const UserProfileDetails = ({ disabledFields }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentUser = useSelector(CurrentUser.select.user());
  const canEditPhones = usePermission(ADMIN_UPDATE_USER_PHONES);
  const [isUpdatePasswordModalVisible, setIsUpdatePasswordModalVisible] =
    useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  const handleOnUpdatePasswordModalOpen = () =>
    setIsUpdatePasswordModalVisible(true);
  const handleOnUpdatePasswordModalClose = () =>
    setIsUpdatePasswordModalVisible(false);
  const handleOnEdit = () => setIsEditing(true);
  const handleOnCancel = () => setIsEditing(false);
  const handleOnFormSubmitted = () => setIsFormSubmitting(false);

  const inputs = useMemo(
    () => ({
      [USER_PROPERTY__NAME]: {
        label: t('forms:name.label'),
        type: 'text',
        autoComplete: 'name',
        defaultValue: currentUser?.getFullName(),
        disabled:
          disabledFields.includes(USER_PROPERTY__NAME) || isFormSubmitting,
        schema: {
          type: 'string',
        },
      },
      [USER_PROPERTY__PHONE]: {
        label: t('forms:phone.label'),
        type: 'text',
        autoComplete: 'tel',
        defaultValue: currentUser?.getPhoneNumber(),
        disabled:
          !canEditPhones ||
          disabledFields.includes(USER_PROPERTY__PHONE) ||
          isFormSubmitting,
        schema: {
          type: 'string',
          format: 'phone',
          optional: true,
        },
      },
      [USER_PROPERTY__EMAIL]: {
        label: t('forms:email.label'),
        type: 'text',
        defaultValue: currentUser?.getEmailAddress(),
        disabled: true,
        schema: {
          type: 'string',
          optional: true,
        },
      },
      password: {
        label: t('forms:password.label'),
        type: 'text',
        defaultValue: '********',
        disabled: true,
        schema: {
          type: 'string',
          optional: true,
        },
      },
    }),
    [t, canEditPhones, currentUser, disabledFields, isFormSubmitting],
  );

  const { fields, handleSubmit } = useForm({
    inputs,
    onSubmit: (values) => {
      setIsFormSubmitting(true);
      const params = {};
      if (!disabledFields.includes(USER_PROPERTY__NAME)) {
        params.name = values.name || '';
      }

      if (!disabledFields.includes(USER_PROPERTY__PHONE)) {
        params.phone = values.phone || '';
      }

      dispatch(
        callMethod(apiCurrentUserUpdateProfile, clearValue(params, 'name')),
      )
        .then(() => {
          notifySuccess(t('confirmations:updateUser.success'))();
          handleOnCancel();
          handleOnFormSubmitted();
        })
        .catch((err) => {
          notifyError()(err);
          handleOnCancel();
          handleOnFormSubmitted();
        });
    },
  });

  return (
    <>
      <form className="stack-6" onSubmit={handleSubmit}>
        <div className="cluster-4 justify-end">
          {isEditing ? (
            <div className="cluster-2">
              <Button htmlType="submit" type="ghost" loading={isFormSubmitting}>
                {t('save')}
              </Button>
              <Button
                type="ghost"
                onClick={handleOnCancel}
                disabled={isFormSubmitting}
              >
                {t('cancel')}
              </Button>
            </div>
          ) : (
            <Button type="ghost" onClick={handleOnEdit}>
              {t('edit')}
            </Button>
          )}
        </div>
        <div className="grid gap-6 grid-cols-2">
          {isEditing
            ? fields.map((field) => {
                if (field.name === 'password') {
                  return (
                    <div className="cluster-2 items-end">
                      <div className="flex-1">
                        <FormFieldText
                          key={field.name}
                          input={field.input}
                          meta={field.meta}
                          id={field.id}
                          name={field.name}
                          type={field.type}
                          required={field.required}
                          autoComplete={field.autoComplete}
                          label={field.label}
                          placeholder={field.placeholder}
                          size={field.size}
                          disabled={field.disabled}
                          schema={field.schema}
                          isNewPresentation
                        />
                      </div>
                      <Button
                        onClick={handleOnUpdatePasswordModalOpen}
                        disabled={isFormSubmitting}
                      >
                        {t('update')}
                      </Button>
                    </div>
                  );
                }

                return (
                  <FormFieldText
                    key={field.name}
                    input={field.input}
                    meta={field.meta}
                    id={field.id}
                    name={field.name}
                    type={field.type}
                    required={field.required}
                    autoComplete={field.autoComplete}
                    label={field.label}
                    placeholder={field.placeholder}
                    size={field.size}
                    disabled={field.disabled}
                    schema={field.schema}
                    isNewPresentation
                  />
                );
              })
            : map(inputs, (input, key) => (
                <FormItemReadOnly key={key} label={input.label}>
                  <span>{input.defaultValue}</span>
                </FormItemReadOnly>
              ))}
        </div>
      </form>
      <UpdatePassword
        visible={isUpdatePasswordModalVisible}
        onClose={handleOnUpdatePasswordModalClose}
      />
    </>
  );
};

UserProfileDetails.propTypes = {
  disabledFields: PropTypes.arrayOf(PropTypes.string),
};

UserProfileDetails.defaultProps = {
  disabledFields: [],
};

export default UserProfileDetails;
