import map from 'lodash/map';
import { Random } from '@zedoc/random';
import {
  VARIABLE_ID__STUDY_NO,
  VARIABLE_ID__PARTICIPATION_TRACK_DATE_DEDUCED_FROM_MILESTONE_SCHEDULE,
  VARIABLE_ID__ACTIVITY_STATE,
  VARIABLE_ID__ACTIVITY_MILESTONE_ID,
  VARIABLE_ID__ACTIVITY_DATE_START,
  VARIABLE_ID__ACTIVITY_DATE_END,
  DASHBOARD_PERSPECTIVE__PATIENTS,
  PROJECT_DASHBOARD_TYPE__TIMELINE,
  PROJECT_DASHBOARD_TYPE__ANALYTICS,
} from '../../constants';
import settings from '../../settings';

const defaultTimezone =
  (settings.private && settings.private.defaultTimezone) || 'UTC';

const variables = [
  {
    id: VARIABLE_ID__PARTICIPATION_TRACK_DATE_DEDUCED_FROM_MILESTONE_SCHEDULE,
  },
  {
    id: VARIABLE_ID__STUDY_NO,
  },
  {
    id: VARIABLE_ID__ACTIVITY_STATE,
  },
  {
    id: VARIABLE_ID__ACTIVITY_MILESTONE_ID,
  },
  {
    id: VARIABLE_ID__ACTIVITY_DATE_START,
  },
  {
    id: VARIABLE_ID__ACTIVITY_DATE_END,
  },
];

const defaultDashboards = [
  {
    type: PROJECT_DASHBOARD_TYPE__TIMELINE,
    title: 'Patient Milestones',
    cards: [],
  },
  {
    type: PROJECT_DASHBOARD_TYPE__ANALYTICS,
    title: 'Clinical Analytics',
    perspective: DASHBOARD_PERSPECTIVE__PATIENTS,
    variables: 'gender = patient:gender\ndob = patient:dob',
    charts: [
      // NOTE: Unfortunately, "participation" namespace is no longer accessible
      //       if dashboard perspective is "patients".
      // {
      //   type: 'amcharts3',
      //   title: 'Baseline',
      //   settings: JSON.stringify({
      //     type: 'serial',
      //     categoryField: 'value',
      //     startDuration: 1,
      //     graphs: [
      //       {
      //         type: 'column',
      //         valueField: 'total',
      //         fillAlphas: 1,
      //       },
      //     ],
      //   }),
      //   dataProvider: 'value = :baseline total = sum of 1',
      //   dataProviderCleanup: 'data = :data | x ascending by x.value',
      // },
      {
        type: 'amcharts3',
        title: 'Gender',
        settings: JSON.stringify({
          type: 'pie',
          valueField: 'total',
          titleField: 'value',
        }),
        dataProvider: 'value = :gender\ntotal = sum of 1',
      },
      {
        type: 'amcharts3',
        title: 'Age',
        settings: JSON.stringify({
          type: 'serial',
          categoryField: 'value',
          startDuration: 1,
          graphs: [
            {
              type: 'column',
              valueField: 'total',
              fillAlphas: 1,
            },
          ],
        }),
        dataProvider: 'value = AGE(:dob)\ntotal = sum of 1',
        dataProviderCleanup: 'data = :data | x ascending by x.value',
      },
    ],
  },
];

function createDashboards(dashboards) {
  return map(dashboards, (dashboard) => {
    return {
      ...dashboard,
      charts: map(dashboard.charts, (chart) => ({
        id: Random.id(),
        ...chart,
      })),
      cards: map(dashboard.cards, (card) => ({
        id: Random.id(),
        ...card,
      })),
      id: Random.id(),
    };
  });
}

const createDefaultConfiguration = ({
  name,
  description,
  timezone = defaultTimezone,
}) => {
  const configuration = {
    name,
    description,
    variables,
    timezone,
    tracks: [
      {
        id: Random.id(),
        name: 'Default',
      },
    ],
    dashboards: createDashboards(defaultDashboards),
  };

  return configuration;
};

export default createDefaultConfiguration;
