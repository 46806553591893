import React, { useEffect, Suspense } from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { DDPConnector, DDPProvider } from '@zedoc/ddp-connector';
import ConfigProvider from 'antd/es/config-provider';
import { notifyError, logger } from '../../utils/notify';
import Loading from '../../common/components/Loading';
import Empty from '../../common/components/base/Empty';
import Router from '../../routes/Router';
import ThemeProvider from '../ThemeProvider';
import LanguageProvider from '../LanguageProvider';
import NotificationsProvider from '../NotificationsProvider';
import { setQueryParam } from '../../store/router';

const propTypes = {
  store: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  history: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  persistor: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  ddpConnector: PropTypes.instanceOf(DDPConnector).isRequired,
};

const subscribe = (emitter, name, listener) => {
  emitter.on(name, listener);
  return () => {
    emitter.removeListener(name, listener);
  };
};

const App = ({ store, history, persistor, ddpConnector }) => {
  // NOTE: We don't want implicit loginErrors, e.g. "No login token"
  // useEffect(() => {
  //   return subscribe(ddpConnector, 'loginError', notifyError());
  // }, [ddpConnector]);
  useEffect(() => {
    return subscribe(ddpConnector, 'resumeLoginError', (err) => {
      store.dispatch(setQueryParam('session_expired', 'true'));
      logger.error('Resume login failed', {
        stack: err.stack,
      });
    });
  }, [store, ddpConnector]);
  useEffect(() => {
    return subscribe(ddpConnector, 'logoutError', notifyError());
  }, [ddpConnector]);
  useEffect(() => {
    return subscribe(ddpConnector, 'error', notifyError());
  }, [ddpConnector]);
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <DDPProvider ddpConnector={ddpConnector}>
          <ThemeProvider>
            <ConfigProvider renderEmpty={() => <Empty />}>
              <Suspense fallback={<Loading />}>
                <LanguageProvider>
                  <NotificationsProvider>
                    <Router history={history} />
                  </NotificationsProvider>
                </LanguageProvider>
              </Suspense>
            </ConfigProvider>
          </ThemeProvider>
        </DDPProvider>
      </PersistGate>
    </Provider>
  );
};

App.propTypes = propTypes;

export default App;
