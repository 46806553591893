/* eslint-disable react/prop-types */
import { PlusIcon, XIcon } from '@heroicons/react/outline';
import React from 'react';
// import Tag from '../common/components/Tag';
import Button from '../components/Button';
import FormFieldPhoneNumber from '../components/forms/FormFieldPhoneNumber';
import FormFieldCheckbox from '../components/forms/FormFieldCheckbox';
import FormFieldSelect from '../components/forms/FormFieldSelect';
import FormFieldText from '../components/forms/FormFieldText';
import FormFieldWrapper from '../components/forms/FormFieldWrapper';
import Select from '../components/Select';
// import { getValue } from '../components/Select/utils';

// FIXME: Add prop types

const renderFormField = ({
  testId,
  name,
  input,
  meta,
  id,
  type,
  required,
  autoComplete,
  label,
  placeholder,
  size,
  suffix,
  schema,

  options,
  disabled,
  mode,
  showSearch,

  // 'react-select' specific
  isMulti,
  onAfterChange,

  readOnly,

  // 'collection' specific
  items: collectionItems,
  createText,
  onCreate,
  onRemove,
  renderExtra,
  itemIndex,
}) => {
  if (!type) {
    return null;
  }

  if (type === 'collection') {
    return (
      <div className="stack-6">
        <span className="font-medium">{label}</span>
        {collectionItems?.map((items, i) => {
          // NOTE: Disable remove if at least one item cannot be removed
          const cannotRemove = !!items.find((item) => item.canRemove === false);
          const handleOnRemove = () => {
            if (!cannotRemove) {
              onRemove(i);
            }
          };
          return (
            // eslint-disable-next-line react/no-array-index-key
            <div key={i} className="stack-6">
              <div className="cluster-4 items-center">
                <div className="flex-1 grid gap-4 grid-cols-2">
                  {items.map((item, j) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div key={j}>
                      {renderFormField({ ...item, itemIndex: i, key: `${j}` })}
                    </div>
                  ))}
                </div>
                {!disabled && (
                  <Button
                    type="tertiary"
                    icon={<XIcon />}
                    onClick={handleOnRemove}
                    disabled={cannotRemove}
                  />
                )}
              </div>
              {renderExtra && renderExtra(i)}
            </div>
          );
        })}
        {!readOnly && !disabled && (
          <Button
            type="ghost"
            size="medium"
            className="self-start"
            icon={<PlusIcon />}
            onClick={onCreate}
          >
            {createText}
          </Button>
        )}
      </div>
    );
  }

  if (type === 'react-select') {
    // TODO: Move read only preview into dedicated FormFieldSelect as with other components
    // const optionsArray = getValue(
    //   options,
    //   input.value,
    //   (opt) => opt.value,
    //   isMulti,
    // );

    // const readOnlyPreview = isMulti ? (
    //   <div className="cluster-2">
    //     {optionsArray?.map((option) => (
    //       <Tag>{option.label}</Tag>
    //     ))}
    //   </div>
    // ) : (
    //   input.value
    // );
    const handleOnAfterChange = (val) =>
      onAfterChange && onAfterChange(val, itemIndex);

    return (
      <FormFieldWrapper label={label} meta={meta} required={required}>
        {/* {readOnly ? (
          readOnlyPreview
        ) : ( */}
        <Select
          data-testid={testId}
          options={options}
          value={input.value}
          onChange={input.onChange}
          onAfterChange={handleOnAfterChange}
          placeholder={placeholder}
          isMulti={isMulti}
          isDisabled={disabled || readOnly}
          hasError={meta && meta.error && meta.submitFailed}
        />
        {/* )} */}
      </FormFieldWrapper>
    );
  }

  if (type === 'select') {
    return (
      <FormFieldSelect
        data-testid={testId}
        name={name}
        label={label}
        required={required}
        options={options}
        disabled={disabled}
        mode={mode}
        showSearch={showSearch}
        input={input}
        meta={meta}
      />
    );
  }

  if (type === 'checkbox') {
    return (
      <FormFieldCheckbox
        data-testid={testId}
        name={name}
        label={label}
        required={required}
        disabled={disabled}
        input={input}
        meta={meta}
      />
    );
  }

  if (type === 'phone') {
    return (
      <FormFieldPhoneNumber
        data-testid={testId}
        name={name}
        label={label}
        required={required}
        readOnly={readOnly}
        disabled={disabled}
        input={input}
        meta={meta}
      />
    );
  }

  return (
    <FormFieldText
      data-testid={testId}
      // key={name}
      // ref={ref}
      // input={input}
      input={input}
      meta={meta}
      id={id}
      name={name}
      type={type}
      required={required}
      autoComplete={autoComplete}
      label={label}
      disabled={disabled}
      readOnly={readOnly}
      placeholder={placeholder}
      size={size}
      suffix={suffix}
      schema={schema}
      isNewPresentation
    />
  );
};

export default renderFormField;
