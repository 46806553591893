import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDDPSubscription } from '@zedoc/ddp-connector';
import { createSelector } from 'reselect';
import Loading from '../common/components/Loading';
import { default as ProjectSelect } from '../common/selectors/Project';
import { apiZedocMyProjects } from '../common/api/zedoc';
import { apiCurrentUserDetails } from '../common/api/currentUser';
import CurrentUser from '../models/CurrentUser';

const HomePageRedirect = ({ location }) => {
  const redirectTo = useSelector(
    createSelector(
      CurrentUser.select.isOnboarded(),
      ProjectSelect.all().whereOneOfMembersIs(CurrentUser.select.user()),
      (isCurrentUserOnboarded, projects) => {
        if (location && location.hash) {
          return null;
        }
        if (!isCurrentUserOnboarded) {
          return '/welcome';
        }
        if (projects.length === 1) {
          return `/projects/${projects[0]._id}`;
        }
        return '/projects';
      },
    ),
  );
  const { ready: myProjectsReady, error: myProjectsError } = useDDPSubscription(
    apiZedocMyProjects.withParams({}),
  );
  const { ready: userDetailsReady, error: userDetailsError } =
    useDDPSubscription(apiCurrentUserDetails.withParams());
  const subscriptionsReady =
    (myProjectsReady || myProjectsError) &&
    (userDetailsReady || userDetailsError);
  if (!subscriptionsReady) {
    return <Loading />;
  }
  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }
  return null;
};

HomePageRedirect.propTypes = {
  location: PropTypes.shape({
    hash: PropTypes.string,
  }).isRequired,
};

export default HomePageRedirect;
