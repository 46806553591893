import ApiSpec from '../ApiSpec';
import Id from '../../schemata/Id';
import {
  RESOURCE_TYPE__PROJECT,
  PATIENT_REVIEW_PATIENT,
  PATIENT_CREATE_PATIENT,
  PATIENT_UPDATE_PATIENT,
  PATIENT_DELETE_PATIENT,
  PATIENT_ACCESS_PATIENT_PII_VARIABLES,
  PATIENT_MILESTONE_ATTACH_ANSWERS_SHEET,
  PATIENT_MILESTONE_CREATE_ANSWERS_SHEET,
  PATIENT_CREATE_PATIENT_NOTE,
  PATIENT_UPDATE_PATIENT_NOTE,
  PATIENT_DELETE_PATIENT_NOTE,
  ORGANIZATION_ACCESS_VARIABLE,
} from '../../permissions';
import {
  QUESTIONNAIRE_ASSIGNEE_TYPE_VALUES,
  ANSWERS_SHEET_NAVIGATION_TYPES,
} from '../../constants';
import { ACTION_TYPE__REVIEW } from '../../messages';

// SUBSCRIPTIONS

export const one = new ApiSpec({
  name: 'api.collections.Recipients.one',
  actionType: ACTION_TYPE__REVIEW,
  permissions: [PATIENT_REVIEW_PATIENT],
  schema: {
    type: 'object',
    required: ['recipientId'],
    properties: {
      recipientId: Id,
    },
  },
});

// QUERIES/ MUTATIONS

export const insert = new ApiSpec({
  name: 'api.collections.Recipients.insert',
  resources: [
    {
      action: PATIENT_CREATE_PATIENT,
      inAllOf: 'domains',
      variables: {
        withPii: PATIENT_ACCESS_PATIENT_PII_VARIABLES,
      },
      creating: true,
    },
    {
      variables: {
        variablesRealm: ORGANIZATION_ACCESS_VARIABLE,
      },
    },
  ],
  schema: {
    type: 'object',
    required: ['domains'],
    properties: {
      domains: {
        type: 'array',
        items: {
          type: 'string',
        },
      },
      name: {
        type: 'object',
        properties: {
          text: {
            type: 'string',
          },
          family: {
            type: 'string',
          },
          given: {
            type: 'array',
            items: {
              type: 'string',
            },
          },
        },
      },
      identifiers: {
        type: 'array',
        required: ['value', 'namespace'],
        properties: {
          value: {
            type: 'string',
            minLength: 1,
          },
          namespace: {
            type: 'string',
            minLength: 1,
          },
        },
      },
      emails: {
        type: 'array',
        items: {
          type: 'object',
          required: ['address'],
          properties: {
            address: Id,
            type: {
              type: 'string',
            },
          },
        },
      },
      phones: {
        type: 'array',
        items: {
          type: 'object',
          required: ['number'],
          properties: {
            number: {
              type: 'string',
              minLength: 1,
            },
            type: {
              type: 'string',
            },
          },
        },
      },
      ethnicity: {
        type: 'string',
      },
      gender: {
        type: 'string',
      },
      birthDate: {
        type: 'string',
      },
    },
  },
});

export const update = new ApiSpec({
  name: 'api.collections.Recipients.update',
  resources: [
    {
      action: PATIENT_UPDATE_PATIENT,
      variables: {
        withPii: PATIENT_ACCESS_PATIENT_PII_VARIABLES,
      },
    },
    {
      variables: {
        canCreateIn: PATIENT_CREATE_PATIENT,
        canDeleteIn: PATIENT_DELETE_PATIENT,
        variablesRealm: ORGANIZATION_ACCESS_VARIABLE,
      },
    },
  ],
  schema: {
    type: 'object',
    required: ['domains', 'recipientId'],
    properties: {
      domains: {
        type: 'array',
        items: {
          type: 'string',
        },
      },
      recipientId: Id,
      name: {
        type: 'object',
        properties: {
          text: {
            type: 'string',
          },
          family: {
            type: 'string',
          },
          given: {
            type: 'array',
            items: {
              type: 'string',
            },
          },
        },
      },
      identifiers: {
        type: 'array',
        items: {
          type: 'object',
          required: ['value', 'namespace'],
          properties: {
            value: {
              type: 'string',
              minLength: 1,
            },
            namespace: {
              type: 'string',
              minLength: 1,
            },
          },
        },
      },
      emails: {
        type: 'array',
        items: {
          type: 'object',
          required: ['address'],
          properties: {
            address: {
              type: 'string',
              minLength: 1,
            },
            type: {
              type: 'string',
            },
          },
        },
      },
      phones: {
        type: 'array',
        items: {
          type: 'object',
          required: ['number'],
          properties: {
            number: {
              type: 'string',
              minLength: 1,
            },
            type: {
              type: 'string',
            },
          },
        },
      },
      ethnicity: {
        type: 'string',
      },
      gender: {
        type: 'string',
      },
      birthDate: {
        type: 'string',
      },
    },
  },
});

export const removeCascade = new ApiSpec({
  name: 'api.collections.Recipients.removeCascade',
  permissions: [PATIENT_DELETE_PATIENT],
  schema: {
    type: 'object',
    required: ['recipientId'],
    properties: {
      recipientId: Id,
    },
  },
});

export const addAnswersSheets = new ApiSpec({
  name: 'api.collections.Recipients.addAnswersSheets',
  resources: [
    {
      type: RESOURCE_TYPE__PROJECT,
    },
    {
      action: PATIENT_MILESTONE_ATTACH_ANSWERS_SHEET,
    },
    {
      action: PATIENT_MILESTONE_CREATE_ANSWERS_SHEET,
      inAllOf: 'activity.ownership',
      creating: true,
    },
  ],
  schema: {
    type: 'object',
    required: ['activityId', 'recipientId', 'questionnaires'],
    properties: {
      activityId: Id,
      recipientId: Id,
      questionnaires: {
        type: 'array',
        items: {
          type: 'object',
          required: ['identifier', 'assigneeType'],
          properties: {
            identifier: {
              type: 'string',
              minLength: 1,
            },
            assigneeType: {
              type: 'string',
              enum: QUESTIONNAIRE_ASSIGNEE_TYPE_VALUES,
            },
            navigationType: {
              type: 'string',
              enum: ANSWERS_SHEET_NAVIGATION_TYPES,
            },
            delegatedToPatient: {
              type: 'boolean',
            },
          },
        },
        minItems: 1,
      },
    },
  },
});

export const insertNote = new ApiSpec({
  name: 'api.collections.Recipients.insertNote',
  permissions: [PATIENT_CREATE_PATIENT_NOTE],
  schema: {
    type: 'object',
    required: ['recipientId', 'content'],
    properties: {
      recipientId: Id,
      content: {
        type: 'string',
        minLength: 1,
      },
    },
  },
});

export const updateNote = new ApiSpec({
  name: 'api.collections.Recipients.updateNote',
  permissions: [PATIENT_UPDATE_PATIENT_NOTE],
  schema: {
    type: 'object',
    required: ['recipientId', 'id', 'content'],
    properties: {
      recipientId: Id,
      id: {
        type: 'string',
        minLength: 1,
      },
      content: {
        type: 'string',
        minLength: 1,
      },
    },
  },
});

export const removeNote = new ApiSpec({
  name: 'api.collections.Recipients.removeNote',
  permissions: [PATIENT_DELETE_PATIENT_NOTE],
  schema: {
    type: 'object',
    required: ['recipientId', 'id'],
    properties: {
      recipientId: Id,
      id: {
        type: 'string',
        minLength: 1,
      },
    },
  },
});
