import {
  QUESTION_TYPE__SELECT_MANY,
  QUESTION_TYPE__SELECT_ONE,
  QUESTION_TYPE__SHORT_TEXT,
  QUESTION_TYPE__COLLECTION,
  QUESTION_TYPE__EMPTY,
  QUESTION_TYPE__SECTION,
  QUESTION_TYPE__MATRIX,
  QUESTION_TYPE__CAT_SECTION,
  QUESTION_TYPE__FREE_TEXT,
  QUESTION_TYPE__NUMBER,
  QUESTION_TYPE__SCALE,
  QUESTION_TYPE__DATE,
  QUESTION_TYPE__TIME,
  QUESTION_TYPE__UPLOAD,
  QUESTION_TYPE__FORMULA,
  QUESTION_TYPE__BLUETOOTH_DEVICE,
  CITATION_TYPE__NOT_REQUIRED,
  CITATION_TYPE__FIRST_SCREEN,
  CITATION_TYPE__ALL_SCREENS,
} from '@zedoc/questionnaire';

import { CHART_TYPE__SERIAL_CHART, CHART_TYPE__PIE_CHART } from './Chart';

export const FORM_BUILDER_RELEASE_TYPE__PATCH = 'Patch';
export const FORM_BUILDER_RELEASE_TYPE__MINOR = 'Minor';
export const FORM_BUILDER_RELEASE_TYPE__MAJOR = 'Major';
export const FORM_BUILDER_RELEASE_TYPES = [
  FORM_BUILDER_RELEASE_TYPE__PATCH,
  FORM_BUILDER_RELEASE_TYPE__MINOR,
  FORM_BUILDER_RELEASE_TYPE__MAJOR,
];

export const FORM_BUILDER_RELEASE_TYPE_OPTIONS = [
  {
    value: FORM_BUILDER_RELEASE_TYPE__PATCH,
    label: 'Patch',
  },
  {
    value: FORM_BUILDER_RELEASE_TYPE__MINOR,
    label: 'Minor',
  },
  {
    value: FORM_BUILDER_RELEASE_TYPE__MAJOR,
    label: 'Major',
  },
];

export const FORM_BUILDER_LIBRARY = [
  {
    section: 'Questions',
    prefabs: [
      {
        type: QUESTION_TYPE__SELECT_ONE,
        label: 'Select One',
        title: 'You can select one option only',
      },
      {
        type: QUESTION_TYPE__SELECT_MANY,
        label: 'Select Many',
        title: 'You can select as many options as you want',
      },
      {
        type: QUESTION_TYPE__SHORT_TEXT,
        label: 'Text Box',
        title: 'The answer must fit in one line',
      },
      {
        type: QUESTION_TYPE__FREE_TEXT,
        label: 'Free Text',
        title: 'The answer can be a larger chunk of text',
      },
      {
        type: QUESTION_TYPE__NUMBER,
        label: 'Number',
        title: 'The answer should be a numeric value',
      },
      // NOTE: This does not have a good support yet, so hiding it for now
      // {
      //   type: QUESTION_TYPE__SCORES,
      //   label: 'Scoring',
      //   title: 'Give score to each item',
      // },
      {
        type: QUESTION_TYPE__SCALE,
        label: 'Scale',
        title: 'Please select value on scale',
        hasDescription: true,
        description: `\
- Step 1
- Step 2
- Step 3
`,
        settings: {
          minValue: 0,
          maxValue: 100,
          verticalLayout: true,
        },
      },
      {
        type: QUESTION_TYPE__DATE,
        label: 'Date',
        title: 'Please enter a valid date',
      },
      {
        type: QUESTION_TYPE__TIME,
        label: 'Time',
        title: 'Please enter a valid time',
      },
      {
        type: QUESTION_TYPE__UPLOAD,
        label: 'Upload',
        title: 'Please upload a file',
      },
      {
        type: QUESTION_TYPE__FORMULA,
        label: 'Formula',
        title: '[value will be evaluated]',
      },
      {
        type: QUESTION_TYPE__BLUETOOTH_DEVICE,
        label: 'Device',
        title: 'Connect to device',
      },
    ],
  },
  {
    section: 'Organizers',
    prefabs: [
      {
        type: QUESTION_TYPE__EMPTY,
        label: 'Title',
        title: 'This is a title question',
        skipNumbering: true,
      },
      {
        type: QUESTION_TYPE__SECTION,
        label: 'Section',
        title: 'This is a section of my questionnaire',
      },
      {
        type: QUESTION_TYPE__COLLECTION,
        label: 'Collection',
        title: 'Allows collecting multiple items as a response',
      },
      {
        type: QUESTION_TYPE__MATRIX,
        label: 'Matrix',
        title: 'Group a couple of questions',
      },
      {
        type: QUESTION_TYPE__CAT_SECTION,
        label: 'CAT Section',
        title: 'IRT-based adaptive section',
      },
    ],
  },
];

export const FORM_BUILDER_CHARTS_LIBRARY = [
  {
    section: 'Basic',
    prefabs: [
      {
        type: CHART_TYPE__SERIAL_CHART,
        title: 'Serial chart',
      },
      {
        type: CHART_TYPE__PIE_CHART,
        title: 'Pie chart',
      },
    ],
  },
];

export const CITATION_TYPE_OPTIONS = [
  {
    value: CITATION_TYPE__NOT_REQUIRED,
    label: 'No citation required',
  },
  {
    value: CITATION_TYPE__FIRST_SCREEN,
    label: 'Citation on the first screen',
  },
  {
    value: CITATION_TYPE__ALL_SCREENS,
    label: 'Citation on all screens',
  },
];

export const QUESTIONNAIRE_VISIBILITY__PRIVATE = 'private';
export const QUESTIONNAIRE_VISIBILITY__LIMITED = 'limited';
export const QUESTIONNAIRE_VISIBILITY__PUBLIC = 'public';

export const ALLOWED_QUESTIONNAIRE_VISIBILITY_TYPES = [
  QUESTIONNAIRE_VISIBILITY__PRIVATE,
  QUESTIONNAIRE_VISIBILITY__LIMITED,
  QUESTIONNAIRE_VISIBILITY__PUBLIC,
];

export const QUESTIONNAIRE_VISIBILITY_TYPE_OPTIONS = [
  {
    value: QUESTIONNAIRE_VISIBILITY__PRIVATE,
    label: 'Private',
  },
  {
    value: QUESTIONNAIRE_VISIBILITY__LIMITED,
    label: 'Limited',
  },
  {
    value: QUESTIONNAIRE_VISIBILITY__PUBLIC,
    label: 'Public',
  },
];
