import ApiSpec from './ApiSpec';

export const apiReportsGetReports = new ApiSpec({
  name: 'api.reports.getReports',
  permissions: ['no_one_can_do_this_at_the_moment'],
});

export const apiReportsAllReportSchedules = new ApiSpec({
  name: 'api.reports.allReportSchedules',
  permissions: ['no_one_can_do_this_at_the_moment'],
  schema: {
    type: 'object',
    properties: {
      isAutomated: {
        type: 'boolean',
      },
    },
  },
});

export const apiReportsUpsertReportSchedule = new ApiSpec({
  name: 'api.reports.upsertReportSchedule',
  permissions: ['no_one_can_do_this_at_the_moment'],
});

export const apiReportsGetReport = new ApiSpec({
  name: 'api.reports.getReport',
  permissions: ['no_one_can_do_this_at_the_moment'],
  schema: {
    type: 'object',
    properties: {
      powerBIReportId: {
        type: 'string',
      },
    },
  },
});
