import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import keyBy from 'lodash/keyBy';
import isUndefined from 'lodash/isUndefined';
import omitBy from 'lodash/omitBy';
import { findMaxMatchingVersion } from '@zedoc/questionnaire';
import { EJSON } from '@zedoc/ejson';
import {
  apiBlueprintsDeleteDraft,
  apiBlueprintsGetDraft,
  apiBlueprintsGetDrafts,
  apiBlueprintsUpdateDraft,
} from '../../api/blueprints';
import BlueprintRelease from './BlueprintRelease';
import { BLUEPRINT_RELEASE_SOURCE_TYPE__PROJECT_JSON } from './constants';

class BlueprintDraft {
  constructor(props, client) {
    this.client = client;
    this.replace(props);
  }

  replace(props = {}) {
    this.props = {
      ...props,
    };
    return this;
  }

  update(newProps) {
    this.props = {
      ...this.props,
      ...newProps,
    };
    return this;
  }

  getVersion() {
    return this.props.version;
  }

  getProjectDetails() {
    const { source, sourceType } = this.props;
    if (sourceType !== BLUEPRINT_RELEASE_SOURCE_TYPE__PROJECT_JSON) {
      return {};
    }
    try {
      const { name, description, timezone, logoUrl } = EJSON.parse(source);
      return omitBy(
        {
          name,
          description,
          timezone,
          logoUrl,
        },
        isUndefined,
      );
    } catch (err) {
      return {};
    }
  }

  async call(apiSpec, params) {
    if (!this.client) {
      throw new Error(
        `Cannot call ${apiSpec.getName()} because client is not specified.`,
      );
    }
    return this.client.call(apiSpec, params);
  }

  async save() {
    const { blueprintId, version } = this.props;
    const props = await this.call(apiBlueprintsUpdateDraft, {
      blueprintId,
      version,
      ...this.props,
    });
    return this.replace(props);
  }

  async delete() {
    const { blueprintId, version } = this.props;
    await this.client.call(apiBlueprintsDeleteDraft, {
      blueprintId,
      version,
    });
  }

  async publish() {
    const release = new BlueprintRelease(this.props, this.client);
    return release.save();
  }

  async compile() {
    const release = new BlueprintRelease(this.props, this.client);
    return release.compile();
  }

  async getProjectConfiguration() {
    let configuration;
    try {
      ({ configuration } = await this.compile());
    } catch (err) {
      // ...
    }
    return configuration;
  }

  static async get(blueprintId, version, client) {
    const props = await client.call(apiBlueprintsGetDraft, {
      blueprintId,
      version,
    });
    return new BlueprintDraft(props, client);
  }

  static async getMaxVersionSatisfying(blueprintId, versionRange, client) {
    // TODO: Optimize this because we don't need to fetch all releases of course.
    //       It would be just fine to fetch the latest one only.
    const drafts = await client.call(apiBlueprintsGetDrafts, {
      blueprintId,
    });
    if (isEmpty(drafts)) {
      return undefined;
    }
    const versions = map(drafts, 'version');
    const byVersion = keyBy(drafts, 'version');
    const maxVersion = findMaxMatchingVersion(versionRange || 'x', versions);
    return new BlueprintDraft(byVersion[maxVersion], client);
  }
}

export default BlueprintDraft;
