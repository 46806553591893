import ApiSpec from './ApiSpec';
import {
  PROJECT_VIEW_ANALYTICS,
  PROJECT_REVIEW_DASHBOARD,
  PATIENT_MILESTONE_SEARCH_ANSWERS_SHEET,
  PATIENT_SEARCH_PATIENT,
  RESOURCE_TYPE__PROJECT,
  PATIENT_MILESTONE_SEARCH_ACTIVITY,
  PROJECT_PROFILE_SEARCH_PARTICIPATION,
} from '../permissions';
import {
  makeFilterSchema,
  mergeObjectSchemas,
  searchApiSchema,
  searchFilterOptionsApiSchema,
} from './common';
import Id from '../schemata/Id';

export const apiAnalyticsDashboardFilterOptions = new ApiSpec({
  name: 'api.analytics.dashboardFilterOptions',
  resources: [
    PROJECT_VIEW_ANALYTICS,
    PROJECT_REVIEW_DASHBOARD,
    {
      variables: {
        searchPatient: PATIENT_SEARCH_PATIENT,
        searchParticipation: PROJECT_PROFILE_SEARCH_PARTICIPATION,
        searchActivity: PATIENT_MILESTONE_SEARCH_ACTIVITY,
        searchAnswersSheet: PATIENT_MILESTONE_SEARCH_ANSWERS_SHEET,
      },
    },
  ],
  schema: mergeObjectSchemas(searchFilterOptionsApiSchema, {
    type: 'object',
    required: ['projectId', 'dashboardId'],
    properties: {
      projectId: Id,
      dashboardId: Id,
    },
  }),
});

export const apiAnalyticsDashboardProgram = new ApiSpec({
  name: 'api.analytics.dashboardProgram',
  resources: [
    PROJECT_REVIEW_DASHBOARD,
    {
      type: RESOURCE_TYPE__PROJECT,
    },
  ],
  schema: {
    type: 'object',
    required: ['dashboardId'],
    properties: {
      dashboardId: Id,
    },
  },
});

export const apiAnalyticsDataProvidersForDashboard = new ApiSpec({
  name: 'api.analytics.dataProvidersForDashboard',
  resources: [
    PROJECT_VIEW_ANALYTICS,
    PROJECT_REVIEW_DASHBOARD,
    {
      variables: {
        searchPatient: PATIENT_SEARCH_PATIENT,
        searchParticipation: PROJECT_PROFILE_SEARCH_PARTICIPATION,
        searchActivity: PATIENT_MILESTONE_SEARCH_ACTIVITY,
        searchAnswersSheet: PATIENT_MILESTONE_SEARCH_ANSWERS_SHEET,
      },
    },
  ],
  schema: {
    type: 'object',
    definitions: {
      filter: makeFilterSchema('#/definitions/filter'),
    },
    required: ['projectId', 'dashboardId'],
    properties: {
      controlId: {
        type: 'string',
      },
      projectId: Id,
      dashboardId: Id,
      filters: {
        type: 'array',
        items: {
          $ref: '#/definitions/filter',
        },
      },
    },
  },
});

export const apiAnalyticsDataProvidersForDashboardForceRefresh = new ApiSpec({
  name: 'api.analytics.dataProvidersForDashboardForceRefresh',
  resources: [PROJECT_VIEW_ANALYTICS, PROJECT_REVIEW_DASHBOARD],
  schema: {
    type: 'object',
    required: ['projectId', 'dashboardId'],
    properties: {
      projectId: Id,
      dashboardId: Id,
    },
  },
});

export const apiAnalyticsSearchFactsTable = new ApiSpec({
  name: 'api.analytics.searchFactsTable',
  resources: [
    PROJECT_VIEW_ANALYTICS,
    PROJECT_REVIEW_DASHBOARD,
    {
      variables: {
        searchPatient: PATIENT_SEARCH_PATIENT,
        searchParticipation: PROJECT_PROFILE_SEARCH_PARTICIPATION,
        searchActivity: PATIENT_MILESTONE_SEARCH_ACTIVITY,
        searchAnswersSheet: PATIENT_MILESTONE_SEARCH_ANSWERS_SHEET,
      },
    },
  ],
  schema: mergeObjectSchemas(searchApiSchema, {
    type: 'object',
    required: ['projectId', 'dashboardId'],
    properties: {
      projectId: Id,
      dashboardId: Id,
    },
  }),
});

export const apiAnalyticsSearchFactsTableAndRunChartQuery = new ApiSpec({
  name: 'api.analytics.searchFactsTableAndRunChartQuery',
  resources: [
    PROJECT_VIEW_ANALYTICS,
    PROJECT_REVIEW_DASHBOARD,
    {
      variables: {
        searchPatient: PATIENT_SEARCH_PATIENT,
        searchParticipation: PROJECT_PROFILE_SEARCH_PARTICIPATION,
        searchActivity: PATIENT_MILESTONE_SEARCH_ACTIVITY,
        searchAnswersSheet: PATIENT_MILESTONE_SEARCH_ANSWERS_SHEET,
      },
    },
  ],
  schema: mergeObjectSchemas(searchApiSchema, {
    type: 'object',
    required: ['projectId', 'dashboardId'],
    properties: {
      projectId: Id,
      dashboardId: Id,
      dataProvider: {
        type: 'string',
      },
      dataProviderCleanup: {
        type: 'string',
      },
    },
  }),
});
