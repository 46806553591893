/* eslint-disable react/prop-types */
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { compose } from 'redux';
import Box from '../../../../common/components/primitives/Box';
import List from '../../../../common/components/List';
import Button from '../../../../components/Button';
import Modal from '../../../../components/dialogs/Modal';
import ActivityButton from './ActivityButton';
import useBooleanState from '../../../../utils/hooks/useBooleanState';

export const MilestonesModalActivity = ({
  patientRecord,
  activity,
  onMilestoneClick,
  isDischarged,
}) => {
  const { state, activityId } = activity;
  const milestoneId = activity && activity.milestoneId;
  const { recipientId } = patientRecord;

  return (
    <ActivityButton
      patientRecord={patientRecord}
      state={state}
      activityId={activityId}
      milestoneId={milestoneId}
      recipientId={recipientId}
      onMilestoneClick={onMilestoneClick}
      isDischarged={isDischarged}
    />
  );
};

const MilestonesModal = compose()(
  ({ activities, patientRecord, onMilestoneClick, isDischarged }) => {
    const { t } = useTranslation();
    const [createModal, showCreateModal, hideCreateModal] =
      useBooleanState(false);

    const handleMilestoneClick = (args) => {
      onMilestoneClick(args);
      hideCreateModal();
    };

    const onCreateModal = () => {
      showCreateModal();
    };

    return !isEmpty(activities) ? (
      <>
        <Button
          size="medium"
          type="ghost"
          onClick={onCreateModal}
          className={isDischarged ? 'relative z-10 pointer-events-auto' : ''}
        >
          {t('moreWithCount', {
            count: activities.length,
          })}
        </Button>
        {createModal && (
          <Modal
            title={t('milestones')}
            onOk={hideCreateModal}
            onCancel={hideCreateModal}
            visible
          >
            <Box.Primary>
              <List
                dataSource={activities}
                renderItem={(activity) => (
                  <MilestonesModalActivity
                    key={activity.activityId}
                    patientRecord={patientRecord}
                    activity={activity}
                    onMilestoneClick={handleMilestoneClick}
                    isDischarged={isDischarged}
                  />
                )}
              />
            </Box.Primary>
          </Modal>
        )}
      </>
    ) : (
      <div
        style={{
          width: 75,
        }}
      />
    );
  },
);

MilestonesModal.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  activities: PropTypes.array,
};

MilestonesModal.defaultProps = {
  activities: [],
};

export default MilestonesModal;
