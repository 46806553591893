import PropTypes from 'prop-types';
import React, { useMemo, useCallback } from 'react';
import map from 'lodash/map';
import { useSelector } from 'react-redux';
import Project from '../../../../common/selectors/Project';
import EAPPFilterOption from '../../../../common/models/EAPPFilterOption';
import { apiZedocSearchEAPPsFilterOptions } from '../../../../common/api/zedoc';
import createSelectors from '../../../../common/selectors/createSelectors';
import { selectAllProjectMilestones } from '../../selectors';
import defaultFilters from './defaultFilters';
import FiltersToolbar from '../FiltersToolbar';
import {
  setTimezone,
  setMilestoneJsonSchema,
} from '../../../../utils/useProjectFilters';

const EAPPFilterOptionSelect = createSelectors(EAPPFilterOption, {});

const ConnectedFilters = ({
  storeKey,
  projectId,
  recipientId,
  dashboardId,
  participationId,
  activityId,
  answersSheetId,
  // eslint-disable-next-line react/prop-types
  hideColumns,
}) => {
  const timezone = useSelector(
    Project.one().whereIdEquals(projectId).toTimezone(),
  );
  const projectMilestones = useSelector(selectAllProjectMilestones);
  const presets = useMemo(() => {
    const defaultFiltersWithHideColumns = defaultFilters.filter((el) => {
      // eslint-disable-next-line react/prop-types
      if (Array.isArray(hideColumns) && hideColumns.length > 0) {
        return (
          el.name &&
          // eslint-disable-next-line react/prop-types
          !hideColumns.some(
            (item) =>
              item.toLowerCase().trim() === el.name.toLowerCase().trim(),
          )
        );
      }
      return true;
    });
    const allPresets = map(defaultFiltersWithHideColumns, (preset) => {
      const withMilestoneSchema = setMilestoneJsonSchema(
        preset,
        projectMilestones,
      );
      return setTimezone(withMilestoneSchema, timezone);
    });
    return allPresets;
  }, [hideColumns, timezone, projectMilestones]);
  const getOptionsSubscription = useCallback(
    ({
      searchText,
      type,
      condition,
      state,
      settings,
      pageIndex,
      resultsPerPage,
    }) => {
      const params = {
        projectId,
        controlId: '$meta.id',
        searchText,
        filter: {
          type,
          condition,
          state,
          settings,
        },
        pageIndex,
        resultsPerPage,
      };

      if (recipientId) {
        params.recipientId = recipientId;
      }

      if (participationId) {
        params.participationId = participationId;
      }

      if (activityId) {
        params.activityId = activityId;
      }

      if (answersSheetId) {
        params.answersSheetId = answersSheetId;
      }

      return apiZedocSearchEAPPsFilterOptions.withParams(params);
    },
    [projectId, recipientId, participationId, activityId, answersSheetId],
  );
  return (
    <FiltersToolbar
      storeKey={storeKey}
      dashboardId={dashboardId}
      presets={presets}
      optionsSelector={EAPPFilterOptionSelect}
      optionsSubscription={getOptionsSubscription}
    />
  );
};

ConnectedFilters.propTypes = {
  storeKey: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  recipientId: PropTypes.string,
  participationId: PropTypes.string,
  activityId: PropTypes.string,
  answersSheetId: PropTypes.string,
  dashboardId: PropTypes.string.isRequired,
  hideColumns: PropTypes.arrayOf(PropTypes.string),
};

ConnectedFilters.defaultProps = {
  recipientId: null,
  participationId: null,
  activityId: null,
  answersSheetId: null,
  hideColumns: [],
};

export default ConnectedFilters;
