import { SyncOutlined } from '@ant-design/icons';
import flatten from 'lodash/flatten';
import map from 'lodash/map';
import moment from 'moment';
import React, { useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useDDPCall } from '@zedoc/ddp-connector';
import { slugify } from '@zedoc/text';
import { default as QuestionnaireRecordSelect } from '../../common/selectors/QuestionnaireRecord';
import { default as QuestionnairesRepositoryMetadataEntrySelect } from '../../common/selectors/QuestionnairesRepositoryMetadataEntry';
import Stack from '../../common/components/primitives/Stack';
import Box from '../../common/components/primitives/Box';
import Sidebar from '../../common/components/primitives/Sidebar';
import Text from '../../common/components/base/Text';
import { default as CommonComponentsList } from '../../common/components/List';
import Highlighter from '../../common/components/Highlighter';
import Button from '../../components/Button';
import PageBar from '../../components/Layout/PageBar';
import { apiZedocSearchQuestionnaireRecords } from '../../common/api/zedoc';
import { apiAdminRefreshQuestionnairesRepository } from '../../common/api/admin';
import {
  FILTER_TYPE__PROPERTY,
  FILTER_CONDITION__SEARCH_TERMS,
} from '../../common/constants';
import { ORGANIZATION_UPDATE_QUESTIONNAIRE } from '../../common/permissions';
import ConnectedFilters from './ConnectedFilters';
import { notifyError, notifySuccess } from '../../utils/notify';
import cleanFilters from '../../utils/cleanFilters';
import useDocumentTitle from '../../utils/useDocumentTitle';
import usePagination from '../../utils/usePagination';
import usePermission from '../../utils/usePermission';
import { getFilters, getSorter } from './store';
import MatchedProperties from '../../components/MatchedProperties';
import flattenFilters from '../../utils/flattenFilters';
import useDateTimeFormat from '../../utils/useDateTimeFormat';

const selectRepositoryMetadata =
  QuestionnairesRepositoryMetadataEntrySelect.one();

const List = () => {
  const rawFilters = useSelector(getFilters);
  const filters = useMemo(
    () => cleanFilters(rawFilters, { anyOf: true }),
    [rawFilters],
  );
  const sorter = useSelector(getSorter);
  const metadata = useSelector(selectRepositoryMetadata);
  const canModifyQuestionnaires = usePermission([
    ORGANIZATION_UPDATE_QUESTIONNAIRE,
  ]);
  const { t } = useTranslation();
  useDocumentTitle([t('questionnaires')]);
  const {
    ready: subscriptionsReady,
    items: questionnaires,
    totalItems,
    paginationKey,
    pageSize,
    renderPagination,
  } = usePagination({
    selector: QuestionnaireRecordSelect,
    getSubscription: (currentPage, resultsPerPage) =>
      apiZedocSearchQuestionnaireRecords.withParams({
        filters,
        sorter,
        controlId: '$meta.id',
        pageIndex: currentPage - 1,
        resultsPerPage,
      }),
  });
  const { ddpCall, ddpIsPending } = useDDPCall();
  const callFetchFromRepository = useCallback(
    () =>
      ddpCall(apiAdminRefreshQuestionnairesRepository.withParams({}))
        .then(
          notifySuccess(
            ({ nChanged, nCreated }) =>
              `${t('newQuestionnaires')}: ${nCreated}, ${t(
                'changedQuestionnaires',
              )}: ${nChanged}`,
          ),
        )
        .catch(notifyError()),
    [ddpCall, t],
  );
  const highlight = useMemo(
    () =>
      flatten(
        map(flattenFilters(filters), (filter) => {
          if (
            filter.type === FILTER_TYPE__PROPERTY &&
            filter.condition === FILTER_CONDITION__SEARCH_TERMS &&
            filter.settings &&
            filter.settings.id === 'searchTerms' &&
            filter.state &&
            filter.state.text
          ) {
            return filter.state.text.split(/\s+/).filter(Boolean);
          }
          return [];
        }),
      ),
    [filters],
  );
  const lastUpdatedAt = useDateTimeFormat({
    date: metadata?.lastUpdatedAt,
    options: { dateStyle: 'long', timeStyle: 'short' },
  });

  return (
    <Stack>
      <PageBar
        title={t('questionnaires')}
        extra={
          <>
            {metadata && (
              <span className="text-sm">
                {t('updatedAt')} {lastUpdatedAt}
              </span>
            )}
            <Button
              data-testid="button-sync"
              icon={<SyncOutlined />}
              onClick={callFetchFromRepository}
              loading={ddpIsPending}
              disabled={!canModifyQuestionnaires}
            >
              {t('sync')}
            </Button>
          </>
        }
      />
      <Sidebar>
        <ConnectedFilters />
      </Sidebar>
      <Box.Primary boxShadow="base">
        <CommonComponentsList
          data-testid="table-questionnaires"
          title={t('totalWithCount', { count: totalItems })}
          dataSource={questionnaires}
          loading={!subscriptionsReady}
          pageSize={pageSize}
          renderItem={(questionnaire) => (
            <div key={questionnaire._id}>
              <Text.Link
                data-testid={`item-${slugify(questionnaire._id)}`}
                to={`/forms/sandbox/${encodeURIComponent(
                  questionnaire.getLatestVersionId(),
                )}`}
                size="large"
                importance="high"
              >
                <Highlighter
                  text={questionnaire.getTitle()}
                  highlight={highlight}
                />
              </Text.Link>
              <Text.Paragraph>
                <Highlighter
                  text={questionnaire.getNamespace()}
                  highlight={highlight}
                />
                /
                <Highlighter
                  text={questionnaire.getIdentifier()}
                  highlight={highlight}
                />
                {', '}
                {`${t('version')} ${questionnaire.getVersion()}`}
              </Text.Paragraph>
              <MatchedProperties
                rawFilters={rawFilters}
                allMatchedProperties={
                  paginationKey &&
                  questionnaire[paginationKey] &&
                  questionnaire[paginationKey].filters
                }
              />
            </div>
          )}
          renderPagination={renderPagination}
        />
      </Box.Primary>
    </Stack>
  );
};

List.propTypes = {};
List.defaultProps = {};

export default List;
