import { MailOutlined, DownloadOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import find from 'lodash/find';
import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';
import { ddp } from '@zedoc/ddp-connector';
import List from '../../../common/components/List';
import Text from '../../../common/components/base/Text';
import Button from '../../../common/components/Button';
import Cluster from '../../../common/components/primitives/Cluster';
import Box from '../../../common/components/primitives/Box';
import Sidebar from '../../../common/components/primitives/Sidebar';
import ShareReport from './ShareReport';

import {
  apiReportsGetReports,
  apiReportsAllReportSchedules,
} from '../../../common/api/reportSchedule';

import { default as ReportScheduleSelect } from '../../../common/selectors/ReportSchedule';

import { setReports } from '../actions';
import { selectReports } from '../selectors';

import { callMethod } from '../../../common/utilsClient/ddp/actions';
import useBooleanState from '../../../utils/hooks/useBooleanState';

const ReportsDirectory = compose(
  ddp({
    subscriptions: () => [
      apiReportsAllReportSchedules.withParams({
        isAutomated: true,
      }),
    ],
    selectors: () => ({
      reportSchedules: ReportScheduleSelect.all(),
    }),
  }),
  connect(
    (state) => ({
      reports: selectReports(state),
    }),
    (dispatch) => ({
      getReports: () =>
        dispatch(callMethod(apiReportsGetReports)).then(({ reports }) => {
          dispatch(setReports(reports));
        }),
    }),
  ),
  lifecycle({
    componentDidMount() {
      this.props.getReports();
    },
  }),
)(({ reports, reportSchedules }) => {
  const [createShareModal, showCreateShareModal, hideCreateShareModal] =
    useBooleanState(false);
  const [modalId, setModalId] = useState(null);

  const onCreateShareModal = ({ id }) => {
    setModalId(id);
    showCreateShareModal();
  };

  const getPeriod = ({ id }) => {
    const reportSchedule = find(reportSchedules, {
      powerBIReportId: id,
    });
    if (reportSchedule && reportSchedule.isAutomated) {
      return reportSchedule.schedule.period;
    }
    return '';
  };

  const getDay = ({ id }) => {
    const reportSchedule = find(reportSchedules, {
      powerBIReportId: id,
    });
    if (reportSchedule && reportSchedule.isAutomated) {
      return reportSchedule.schedule.day;
    }
    return '';
  };

  return (
    <>
      <List
        dataSource={reports}
        renderItem={({ name, id, downloadUrl }) => (
          <List.Item key={id}>
            <Box.Primary>
              <Sidebar
                sidebar={
                  <Cluster>
                    <Text.Span importance="low">
                      {getPeriod({
                        id,
                      })}
                    </Text.Span>
                    <Text.Span importance="low">
                      {getDay({
                        id,
                      })}
                    </Text.Span>
                    <Button
                      type="link"
                      icon={<MailOutlined />}
                      onClick={() =>
                        onCreateShareModal({
                          id,
                        })
                      }
                    />
                    <Text.Link target="_new" href={downloadUrl}>
                      <DownloadOutlined />
                    </Text.Link>
                  </Cluster>
                }
              >
                <Text.Paragraph importance="high">{name}</Text.Paragraph>
              </Sidebar>
            </Box.Primary>
          </List.Item>
        )}
      />
      {createShareModal && (
        <ShareReport
          handleHide={hideCreateShareModal}
          powerBIReportId={modalId}
        />
      )}
    </>
  );
});

ReportsDirectory.propTypes = {};

ReportsDirectory.defaultProps = {};

export default ReportsDirectory;
