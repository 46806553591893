import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ADMIN_SEARCH_ROLE } from '../../common/permissions';
import RolesFilters from './components/RolesFilters';
import RolesTable from './components/RolesTable';
import useDocumentTitle from '../../utils/useDocumentTitle';
import DialogCreateRole from './components/DialogCreateRole';
import { setSelectedRole } from './store';
import Card from '../../components/Card';
import usePermissionsRealm from '../../utils/usePermissionsRealm';

const SettingsRoles = () => {
  const { t } = useTranslation();
  useDocumentTitle([t('settings'), t('roles')]);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      setSelectedRole({
        name: '',
        permissions: [],
        belongsTo: '',
      }),
    );
  }, [dispatch]);
  const { domainsOptions } = usePermissionsRealm(ADMIN_SEARCH_ROLE);
  return (
    <Card>
      <DialogCreateRole />
      <div className="stack-4" data-testid="roles-table">
        <RolesFilters domains={domainsOptions} />
        <RolesTable domains={domainsOptions} />
      </div>
    </Card>
  );
};

export default SettingsRoles;
