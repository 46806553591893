import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import { Menu, Transition } from '@headlessui/react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import React, { Fragment, useMemo } from 'react';
import { ChevronDownIcon, TrashIcon } from '@heroicons/react/outline';
import useElementTruncated from '../utils/useElementTruncated';
import Tooltip from '../common/components/Tooltip';
import Button from './Button';

const MenuItem = ({
  testId,
  className,
  label,
  icon,
  onClick,
  disabled,
  isActive,
  onRemove,
}) => {
  const { ref, isElementTruncated } = useElementTruncated();

  return (
    <Menu.Item data-testid={testId} as="li" disabled={disabled}>
      {() => (
        <div className="cluster-1">
          <button
            type="button"
            className={`${className} ${
              isActive
                ? 'bg-primary-800 text-on-primary-800'
                : !disabled && 'hover:bg-primary-800 hover:text-on-primary-800'
            } cluster-2 items-center text-start w-full rounded-md px-2 py-2 text-sm disabled:cursor-not-allowed disabled:text-on-disabled`}
            onClick={onClick}
            disabled={disabled}
          >
            {icon}
            <Tooltip
              title={isElementTruncated ? label : ''}
              containerClassName="truncate"
            >
              <span ref={ref} className="truncate">
                {label}
              </span>
            </Tooltip>
          </button>
          {onRemove && (
            <button
              data-testid="dropdown-item-remove"
              type="button"
              className="hover:bg-danger-500 hover:text-on-danger-500 rounded-md px-2 py-2"
              onClick={onRemove}
            >
              <TrashIcon className="h-5 w-5" />
            </button>
          )}
        </div>
      )}
    </Menu.Item>
  );
};

MenuItem.propTypes = {
  testId: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  icon: PropTypes.node,
  onClick: PropTypes.func,
  onRemove: PropTypes.func,
  disabled: PropTypes.bool,
  isActive: PropTypes.bool,
};
MenuItem.defaultProps = {
  testId: null,
  className: '',
  label: null,
  icon: null,
  onClick: () => {},
  onRemove: null,
  disabled: false,
  isActive: false,
};

// TODO: Should we add aria-hidden="true" to buttons?
const Dropdown = ({
  'data-testid': testId,
  as,
  label,
  icon,
  type,
  position,
  items,
  activeId,
}) => {
  const { t } = useTranslation();

  const menuItems = useMemo(() => {
    const hasNestedItems = items.some((elem) => isArray(elem));

    if (hasNestedItems) {
      return items.map((nestedItems, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <ul key={i} className="p-1 stack-1">
          {nestedItems.map((item, j) => {
            const props = {
              ...item,
              isActive: activeId && activeId === item.id,
            };
            // eslint-disable-next-line react/jsx-props-no-spreading, react/no-array-index-key
            return <MenuItem key={j} {...props} />;
          })}
        </ul>
      ));
    }

    return (
      <ul className="p-1 stack-1">
        {items.map((item, i) => {
          const props = { ...item, isActive: activeId && activeId === item.id };
          // eslint-disable-next-line react/jsx-props-no-spreading, react/no-array-index-key
          return <MenuItem key={i} {...props} />;
        })}
      </ul>
    );
  }, [activeId, items]);

  const menuItemsPosition = useMemo(() => {
    switch (position) {
      case 'bottom-end':
        return 'inline-end-0 mbs-2';
      case 'right-end':
        return 'block-end-0 inline-start-full mis-2';
      default:
        return '';
    }
  }, [position]);

  const menuButtonProps = as
    ? {}
    : {
        type,
        'data-testid': testId,
        icon: icon || <ChevronDownIcon className="h-5 w-5" />,
      };

  return (
    <Menu as="div" className="relative">
      <div>
        <Menu.Button
          as={as || Button}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...menuButtonProps}
        >
          {label}
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        {/* FIXME: Double check menu.items styling */}
        <Menu.Items
          className={`absolute z-10 w-56 ${menuItemsPosition} origin-top-right divide-y divide-gray-100 rounded-md bg-surface shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
        >
          {isEmpty(items) ? (
            <ul className="p-1 stack-1">
              <MenuItem
                label={t('empty')}
                className="justify-center"
                disabled
              />
            </ul>
          ) : (
            menuItems
          )}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

const DropdownItem = PropTypes.shape({
  testId: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  icon: PropTypes.node,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
});

Dropdown.propTypes = {
  'data-testid': PropTypes.string,
  as: PropTypes.elementType,
  label: PropTypes.string,
  icon: PropTypes.node,
  // FIXME: Change to oneOf
  type: PropTypes.string,
  position: PropTypes.oneOf(['bottom-end', 'right-end']),
  items: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.arrayOf(DropdownItem), DropdownItem]),
  ),
  activeId: PropTypes.string,
};

Dropdown.defaultProps = {
  'data-testid': 'dropdown',
  as: null,
  label: null,
  icon: null,
  type: 'secondary',
  position: 'bottom-end',
  items: [],
  activeId: null,
};

export default Dropdown;
