import ApiSpec from '../ApiSpec';
import {
  PROJECT_REVIEW_PROJECT,
  PROJECT_VIEW_COLLABORATORS,
} from '../../permissions';
import Id from '../../schemata/Id';

// QUERIES/ MUTATIONS

export const getProjectNames = new ApiSpec({
  name: 'api.aggregations.Projects.getProjectNames',
  permissions: [PROJECT_REVIEW_PROJECT],
  schema: {
    type: 'object',
    additionalProperties: false,
  },
});

export const getQuestionnaireNames = new ApiSpec({
  name: 'api.aggregations.Projects.getQuestionnaireNames',
  permissions: [PROJECT_REVIEW_PROJECT],
  schema: {
    type: 'object',
    properties: {
      projectId: Id,
    },
  },
});

export const getMilestoneNames = new ApiSpec({
  name: 'api.aggregations.Projects.getMilestoneNames',
  permissions: [PROJECT_REVIEW_PROJECT],
  schema: {
    type: 'object',
    additionalProperties: false,
  },
});

export const getUserNames = new ApiSpec({
  name: 'api.aggregations.Projects.getUserNames',
  permissions: [PROJECT_VIEW_COLLABORATORS],
  schema: {
    type: 'object',
    properties: {
      projectId: Id,
    },
  },
});
