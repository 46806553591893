import { encodeQuery, urlJoin } from '@zedoc/url';
import { EJSON } from '@zedoc/ejson';
import ClientSafeError from '../utils/ClientSafeError';

const defaultFetch = typeof fetch === 'function' ? fetch : undefined;

const createHttpClient = (
  baseUrl = '',
  fetchFn = defaultFetch,
  credentials = {},
) => {
  const { resumeToken } = credentials;
  const login = (newCredentials) => {
    return createHttpClient(baseUrl, fetchFn, newCredentials);
  };
  const call = async (apiSpec, params) => {
    if (!fetchFn) {
      throw new Error('Fetch() was not found, please provide one.');
    }
    const method = apiSpec.getMethod();
    if (!method) {
      throw new Error(`Method ${apiSpec.getName()} cannot be called via HTTP`);
    }
    // TODO: Later on pick proper api version based on apiSpec.
    const pathname = `/v1${apiSpec.toPath(params)}`;
    const bodyIsAllowed =
      method === 'post' || method === 'put' || method === 'patch';
    const options = {
      method: method.toUpperCase(),
      headers: {
        'X-Auth-Token': resumeToken,
        'Content-Type': 'application/json',
      },
    };
    let url = urlJoin(baseUrl, pathname);
    if (bodyIsAllowed) {
      options.body = EJSON.stringify(params);
    } else {
      url += encodeQuery(apiSpec.getQuery(params));
    }
    return fetchFn(url, options).then((response) => {
      if (response.ok) {
        return response.json();
      }
      return response
        .json()
        .then(({ error: { errorType, reason, details } }) =>
          Promise.reject(new ClientSafeError(errorType, reason, details)),
        );
    });
  };

  return {
    call,
    login,
  };
};

export default createHttpClient;
