import filter from 'lodash/filter';
import includes from 'lodash/includes';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useDDPSubscription } from '@zedoc/ddp-connector';
import { useReconcile } from '@zedoc/react-hooks';
import { apiZedocProjectVariables } from '../common/api/zedoc';
import Variable from '../common/models/Variable';
import { default as VariableSelect } from '../common/selectors/Variable';

const constant = (x) => () => x;

/**
 * @param {string | undefined} projectId
 * @param {object} [options]
 * @param {string[]} [options.allowedScopes]
 * @returns {{ variables: Variable[], variablesReady: boolean }}
 */
const useProjectVariables = (projectId, options = {}) => {
  const { ready: variablesReady } = useDDPSubscription(
    projectId &&
      apiZedocProjectVariables.withParams({
        projectId,
      }),
  );

  const empty = useMemo(() => [], []);

  const variables = useSelector(
    useMemo(() => {
      if (!projectId) {
        return constant([]);
      }
      return VariableSelect.all()
        .satisfying((variable) => {
          return !!(
            variable &&
            variable[`_project_${projectId}`] &&
            variable[`_project_${projectId}`].index >= 0
          );
        })
        .sort({
          [`_project_${projectId}.index`]: 1,
        })
        .map(
          constant((variable) => {
            const {
              [`_project_${projectId}`]: { compulsory, disableUserEdits },
            } = variable;
            return new Variable({
              ...variable.raw,
              compulsory,
              disableUserEdits,
            });
          }),
        );
    }, [projectId]),
  );

  const allowedScopes = useReconcile(options.allowedScopes);
  const allowedVariables = useMemo(() => {
    if (!allowedScopes) {
      return variables;
    }
    return filter(variables, (variable) => {
      return includes(allowedScopes, variable.scopeName);
    });
  }, [variables, allowedScopes]);

  return useReconcile({
    variables: variablesReady ? allowedVariables : empty,
    variablesReady,
  });
};

export default useProjectVariables;
