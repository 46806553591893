import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import PropTypes from 'prop-types';
import React, { useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ACTIVITY_STATE__COMPLETED,
  ACTIVITY_STATE__ACTIVE,
  ACTIVITY_STATE__ABORTED,
  ACTIVITY_STATE__CANCELED,
  ACTIVITY_STATE__EXPIRED,
  ACTIVITY_STATE__SCHEDULED,
  ACTIVITY_STATE__PLANNED,
  ACTIVITY_STATE__OMITTED,
  ACTIVITY_STATE__SUSPENDED,
} from '../../common/constants';
import Button from '../Button';
import CalendarMilestone from './CalendarMilestone';
import {
  getCurrentMonthDays,
  getIsCurrentDay,
  getNextMonthDays,
  getPrevMonthDays,
  getWeekDays,
} from './helpers';
import useCalendarItems from './useCalendarItems';

const Calendar = ({ activities, className, onShowMoreActivities }) => {
  const { t, i18n } = useTranslation();

  const { language } = i18n;
  const languageDirection = i18n.dir(language);

  const [currentDate, setCurrentDate] = useState();

  const dayNames = getWeekDays(language);

  useEffect(() => {
    setCurrentDate(new Date());
  }, []);

  const handleOnSelectPrevMonth = () =>
    setCurrentDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth() - 1),
    );
  const handleOnSelectNextMonth = () =>
    setCurrentDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth() + 1),
    );
  const handleOnSelectToday = () => setCurrentDate(new Date());

  const currentMonth = useMemo(() => {
    const month = currentDate?.toLocaleDateString(language, {
      month: 'long',
      year: 'numeric',
    });

    return month ? month[0].toUpperCase() + month.slice(1) : '';
  }, [currentDate, language]);

  const prevMonthDays = useMemo(
    () => getPrevMonthDays(currentDate).map((date) => date.getDate()),
    [currentDate],
  );
  const currentMonthDays = useMemo(
    () => getCurrentMonthDays(currentDate).map((date) => date.getDate()),
    [currentDate],
  );
  const nextMonthDays = useMemo(
    () => getNextMonthDays(currentDate).map((date) => date.getDate()),
    [currentDate],
  );

  const calendarItems = useCalendarItems({
    currentDate,
    activities,
    onShowMoreActivities,
  });

  return (
    <div className={`bg-surface border border-divider rounded-md ${className}`}>
      <div className="p-4 cluster-4 justify-between items-center">
        <div className="cluster-4 items-center">
          <Button
            data-testid="calendar-select-previous-month"
            type="tertiary"
            icon={
              languageDirection === 'rtl' ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )
            }
            onClick={handleOnSelectPrevMonth}
          />
          <span
            data-testid="calendar-current-month"
            className="text-xl font-semibold"
          >
            {currentMonth}
          </span>
          <Button
            data-testid="calendar-select-next-month"
            type="tertiary"
            icon={
              languageDirection === 'rtl' ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )
            }
            onClick={handleOnSelectNextMonth}
          />
        </div>
        <Button
          data-testid="calendar-select-current-month"
          size="medium"
          onClick={handleOnSelectToday}
        >
          {t('today')}
        </Button>
      </div>
      <div className="z-calendar">
        {dayNames.map((name) => (
          <div key={name} className="z-calendar__day-name">
            {name}
          </div>
        ))}
        {prevMonthDays.map((day) => (
          <div
            key={day}
            className="z-calendar__day border-b p-2 text-xs text-on-disabled"
          >
            {day}
          </div>
        ))}
        {currentMonthDays.map((day) => (
          <div key={day} className="z-calendar__day border-b p-2 text-xs">
            <span
              className={`${
                getIsCurrentDay(currentDate, day)
                  ? 'border w-6 h-6 flex justify-center items-center rounded-full'
                  : ''
              }`}
            >
              {day}
            </span>
          </div>
        ))}
        {nextMonthDays.map((day) => (
          <div
            key={day}
            className="z-calendar__day border-b p-2 text-xs text-on-disabled"
          >
            {day}
          </div>
        ))}
        {calendarItems.map(
          ({
            activityId,
            name,
            state,
            isEmpty,
            isNonActionable,
            isDisabled,
            dateStartDay,
            dateEndDay,
            weekStart,
            onClick,
            showContinuationToPrevDate,
            showContinuationToNextDate,
            className: milestoneClassName,
            grid,
          }) => (
            <CalendarMilestone
              key={`${activityId}_${grid.rowStart}-${grid.columnStart}-${grid.columnEnd}`}
              name={name}
              state={state}
              isEmpty={isEmpty}
              isNonActionable={isNonActionable}
              isDisabled={isDisabled}
              onClick={onClick}
              dateStartDay={dateStartDay}
              dateEndDay={dateEndDay}
              weekStart={weekStart}
              showContinuationToPrevDate={showContinuationToPrevDate}
              showContinuationToNextDate={showContinuationToNextDate}
              className={milestoneClassName}
              grid={grid}
            />
          ),
        )}
      </div>
    </div>
  );
};

Calendar.propTypes = {
  className: PropTypes.string,
  activities: PropTypes.arrayOf(
    PropTypes.shape({
      dateStart: PropTypes.instanceOf(Date),
      dateEnd: PropTypes.instanceOf(Date),
      name: PropTypes.string,
      isEmpty: PropTypes.bool,
      isNonActionable: PropTypes.bool,
      onClick: PropTypes.func,
      state: PropTypes.oneOf([
        ACTIVITY_STATE__COMPLETED,
        ACTIVITY_STATE__ACTIVE,
        ACTIVITY_STATE__ABORTED,
        ACTIVITY_STATE__CANCELED,
        ACTIVITY_STATE__EXPIRED,
        ACTIVITY_STATE__SCHEDULED,
        ACTIVITY_STATE__PLANNED,
        ACTIVITY_STATE__OMITTED,
        ACTIVITY_STATE__SUSPENDED,
      ]),
    }),
  ),
  onShowMoreActivities: PropTypes.func,
};

Calendar.defaultProps = {
  className: '',
  activities: [],
  onShowMoreActivities: () => {},
};

export default Calendar;
