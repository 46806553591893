import { checkSchema } from '@zedoc/check-schema';
import Formula from '../Formula';
import { FORMULA_TYPE__QUESTIONS_WHERE_ANSWER_IS } from '../../../constants';

const settingsSchema = {
  type: 'object',
  required: ['allowedValues'],
  properties: {
    allowedValues: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
  },
};

class FormulaQuestionsWhereAnswerIs extends Formula {
  validate() {
    if (!this.settings) {
      return this.constructor.NotConfigured;
    }
    if (checkSchema(settingsSchema, this.settings.allowedValues)) {
      return this.constructor.NotConfigured;
    }
    return undefined;
  }

  compile(questionsHierarchy) {
    const compiled = {
      ...this,
      settings: {
        allowedValues: this.settings.allowedValues,
      },
    };
    if (this.meta && this.meta.mapToMetadataKey && this.meta.metadataKey) {
      compiled.settings.mappings = {};
      questionsHierarchy.forEachQuestion((question) => {
        const values = question.getMetadataAsFormValues();
        if (values[this.meta.metadataKey]) {
          compiled.settings.mappings[question.id] =
            values[this.meta.metadataKey].value;
        }
      });
    }
    return compiled;
  }

  evaluate(scope) {
    const results = [];
    const allowedValues = {};
    this.settings.allowedValues.forEach((x) => {
      allowedValues[x] = true;
    });
    const transform = this.settings.mappings
      ? (x) => this.settings.mappings[x]
      : (x) => x;
    scope.forEachAnswer((answer, id) => {
      if (Array.isArray(answer.value)) {
        if (answer.value.some((x) => !!allowedValues[x])) {
          results.push(transform(id));
        }
      } else if (typeof answer.value === 'string') {
        if (allowedValues[answer.value]) {
          results.push(transform(id));
        }
      }
    });
    return {
      value: results,
    };
  }

  toMongoExpression() {
    // TODO: Apply metadata mapping
    return {
      $map: {
        as: 'response',
        in: '$$response.questionId',
        input: {
          $filter: {
            input: '$responses',
            as: 'response',
            cond: {
              $or: [
                {
                  $and: [
                    {
                      $not: {
                        $isArray: '$$response.answer.value',
                      },
                    },
                    {
                      $or: this.settings.allowedValues.map((value) => ({
                        $eq: [
                          {
                            $literal: value,
                          },
                          '$$response.answer.value',
                        ],
                      })),
                    },
                  ],
                },
                {
                  $and: [
                    {
                      $isArray: '$$response.answer.value',
                    },
                    {
                      $or: this.settings.allowedValues.map((value) => ({
                        $in: [
                          {
                            $literal: value,
                          },
                          '$$response.answer.value',
                        ],
                      })),
                    },
                  ],
                },
              ],
            },
          },
        },
      },
    };
  }
}

Formula.types[FORMULA_TYPE__QUESTIONS_WHERE_ANSWER_IS] =
  FormulaQuestionsWhereAnswerIs;

export default FormulaQuestionsWhereAnswerIs;
