import reduce from 'lodash/reduce';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Text from '../base/Text';
import Popover from '../Popover';
import Stack from '../primitives/Stack';
import Cluster from '../primitives/Cluster';
import Clipboard from '../Clipboard/index';
import { detailsPropTypes, detailsDefaultProps } from './propTypes';

const EnvironmentDetails = ({ details }) => {
  const { t } = useTranslation();
  const text = reduce(
    details,
    (string, { label, value }) => string.concat(`**${label}:** ${value} \n\n`),
    `**URL:** ${window.location.href} \n\n`,
  );
  const content = (
    <Stack space={2}>
      <Stack space={0}>
        {details.map(({ label, value }) => (
          <Text.Paragraph key={value} size="small">
            <Text.Span importance="high">{label}</Text.Span>
            {': '}
            <Text.Span>{value}</Text.Span>
          </Text.Paragraph>
        ))}
      </Stack>
      <hr />
      <Cluster justify="space-between">
        <Clipboard text={text} />
        <Text.Link
          href="https://theclinician.zendesk.com/hc/en-us/requests/new"
          target="_blank"
          rel="noopener"
          size="small"
        >
          {t('reportBug')}
        </Text.Link>
      </Cluster>
    </Stack>
  );

  return (
    <Popover content={content} placement="bottomRight" trigger="click">
      <button type="button" className="text-xs underline hover:no-underline">
        {t('details')}
      </button>
    </Popover>
  );
};

EnvironmentDetails.propTypes = detailsPropTypes;
EnvironmentDetails.defaultProps = detailsDefaultProps;

export default EnvironmentDetails;
