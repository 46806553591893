import styled from 'styled-components';
import PropTypes from 'prop-types';
import React from 'react';
import { withProps } from 'recompose';
import {
  PropTypesInput,
  PropTypesMeta,
  PropTypesOption,
} from '@zedoc/react-questionnaire';
import Select from '../../inputs/Select';
import FormFieldWrapper from '../FormFieldWrapper';

const StyledSelect = styled(Select)`
  width: 100%;
`;

const FormFieldSelect = withProps(({ mode, input, onSelect }) => ({
  // in case of antd, the component already supports
  // onSelect event, so we don't need to emulate it inside onChange
  onSelect: (value) => {
    if (onSelect) {
      // Our custom hook
      onSelect({
        value,
      });
    }
  },
  onClear: () => {
    input.onChange(null);
  },
  input: {
    ...input,
    value: mode === 'multiple' ? input.value || [] : input.value,
    onChange: (value) => {
      input.onChange(value);
    },
  },
}))(
  ({
    label,
    required,
    meta,
    input: { value, onChange },
    onClear,
    extra,
    disabled,
    options,
    multiple,
    placeholder,
    showSearch,
    allowClear,
    shouldWrapText,
    'data-testid': datatestid,
  }) => (
    <FormFieldWrapper
      label={label}
      required={required}
      meta={meta}
      extra={extra}
      disabled={disabled}
    >
      <StyledSelect
        data-testid={datatestid}
        value={value}
        onChange={onChange}
        onClear={onClear}
        disabled={disabled}
        options={options}
        multiple={multiple}
        placeholder={placeholder}
        showSearch={showSearch}
        allowClear={allowClear}
        shouldWrapText={shouldWrapText}
      />
    </FormFieldWrapper>
  ),
);

FormFieldSelect.propTypes = {
  input: PropTypesInput.isRequired,
  meta: PropTypesMeta.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypesOption),
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  showSearch: PropTypes.bool,
  allowClear: PropTypes.bool,
  shouldWrapText: PropTypes.bool,
  'data-testid': PropTypes.string,
};

FormFieldSelect.defaultProps = {
  options: [],
  disabled: false,
  placeholder: null,
  showSearch: false,
  allowClear: false,
  shouldWrapText: false,
  'data-testid': null,
};

export default FormFieldSelect;
