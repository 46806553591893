import React, { useRef, useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import {
  useDDPSubscription,
  createEntitiesSelectors,
} from '@zedoc/ddp-connector';
import { useSelector } from 'react-redux';
import Sidebar from '../../../../common/components/primitives/Sidebar';
import ProjectDashboard from '../../../../common/selectors/ProjectDashboard';
import {
  apiAnalyticsDashboardProgram,
  apiAnalyticsSearchFactsTableAndRunChartQuery,
} from '../../../../common/api/analytics';
import Table from '../../../../common/components/Table';
import Text from '../../../../common/components/base/Text';
import Tooltip from '../../../../common/components/Tooltip';
import { createGetFilters } from './store';
import usePagination from '../../../../utils/usePagination';
import cleanFilters from '../../../../utils/cleanFilters';
import {
  FILTER_TYPE__PROPERTY,
  FILTER_CONDITION__INCLUDE,
  DASHBOARD_PERSPECTIVE__ACTIVITIES,
  DASHBOARD_PERSPECTIVE__ASSIGNMENTS,
  DASHBOARD_PERSPECTIVE__RESPONSES,
  DASHBOARD_PERSPECTIVE__PARTICIPATIONS,
  DASHBOARD_PERSPECTIVE__PATIENTS,
} from '../../../../common/constants';
import Alert from '../../../../common/components/Alert';
import CodeMirror from '../../../../components/forms/CodeMirror';
import SavedCharts from '../../../../components/SavedCharts';
import ChartEditor from './ChartEditor';

const ActivitySelect = createEntitiesSelectors(
  'projections.DashboardsFactsTable.Activities',
);

const AnswersSheetSelect = createEntitiesSelectors(
  'projections.DashboardsFactsTable.AnswersSheets',
);

const ParticipationSelect = createEntitiesSelectors(
  'projections.DashboardsFactsTable.Participations',
);

const RecipientSelect = createEntitiesSelectors(
  'projections.DashboardsFactsTable.Recipients',
);

const pageSizeOptions = ['5', '10', '25', '50'];

const getSelector = (perspective) => {
  switch (perspective) {
    case DASHBOARD_PERSPECTIVE__ACTIVITIES: {
      return ActivitySelect;
    }
    case DASHBOARD_PERSPECTIVE__ASSIGNMENTS:
    case DASHBOARD_PERSPECTIVE__RESPONSES: {
      return AnswersSheetSelect;
    }
    case DASHBOARD_PERSPECTIVE__PARTICIPATIONS: {
      return ParticipationSelect;
    }
    case DASHBOARD_PERSPECTIVE__PATIENTS: {
      return RecipientSelect;
    }
    default: {
      return undefined;
    }
  }
};

const getCode = (error) => {
  let code;
  if (typeof error.code === 'string') {
    [code] = error.code.split('_');
  } else {
    code = error.code;
  }
  return code;
};

const getMessage = (error) => {
  let msg;
  if (error.message) {
    const code = getCode(error);
    msg = `[${code}] ${error.message}`;
  } else {
    msg = error.code;
  }
  return msg;
};

const Variable = ({ variable }) => {
  if (!variable) {
    return null;
  }
  const { value, error } = variable;
  if (error) {
    const message = getMessage(error);
    const code = getCode(error);
    return (
      <Tooltip title={message}>
        <Text.Span type="danger" importance="high">
          {code}
        </Text.Span>
      </Tooltip>
    );
  }
  return <Text.Span>{JSON.stringify(value)}</Text.Span>;
};

Variable.propTypes = {
  variable: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    value: PropTypes.any,
    error: PropTypes.shape({
      code: PropTypes.string,
      message: PropTypes.string,
    }),
  }).isRequired,
};

const AnalyticsTable = ({ dashboardId, recipientId, projectId }) => {
  const [currentChart, setCurrentChart] = useState({
    dataProvider: '',
    dataProviderCleanup: '',
  });
  const [chartId, setChartId] = useState(null);

  const { ready: programReady } = useDDPSubscription(
    dashboardId &&
      apiAnalyticsDashboardProgram.withParams({
        dashboardId,
      }),
  );

  const rawFilters = useSelector(
    createGetFilters(`dashboards.${dashboardId}.filters`),
  );
  const filters = useMemo(() => {
    const allFilters = cleanFilters(rawFilters, { anyOf: true });
    if (recipientId) {
      allFilters.push({
        type: FILTER_TYPE__PROPERTY,
        condition: FILTER_CONDITION__INCLUDE,
        settings: {
          id: 'recipientId',
        },
        state: {
          include: [recipientId],
        },
      });
    }
    return allFilters;
  }, [recipientId, rawFilters]);
  const dashboard = useSelector(
    ProjectDashboard.one().whereIdEquals(dashboardId),
  );
  const {
    ready: subscriptionsReady,
    items,
    subscriptionId,
    getPaginationProps,
  } = usePagination({
    debounceMs: 500,
    pageSizeOptions,
    selector: getSelector(dashboard?.perspective),
    getSubscription: (currentPage, resultsPerPage) =>
      apiAnalyticsSearchFactsTableAndRunChartQuery.withParams({
        projectId,
        dashboardId,
        dataProvider: currentChart?.dataProvider,
        dataProviderCleanup: currentChart?.dataProviderCleanup,
        filters,
        controlId: '$meta.id',
        pageIndex: currentPage - 1,
        resultsPerPage,
      }),
  });

  const chartKey = subscriptionId && `_chart_${subscriptionId}`;
  const chartDataProviderKey =
    subscriptionId && `_chartDataProvider_${subscriptionId}`;

  const baseColumns = useMemo(() => {
    return map(
      dashboard?.program?.variables?.outputs,
      ({ name, namespace }) => {
        const key = `${namespace}:${name}`;
        return {
          title: name,
          key,
          render: (_, doc) => {
            const variable = doc.variables && doc.variables[key];
            return <Variable variable={variable} />;
          },
        };
      },
    );
  }, [dashboard?.program]);

  const dataProviderColumns = useMemo(() => {
    return map(
      dashboard?.[chartKey]?.program?.outputs,
      ({ name, namespace }) => {
        const key = `${namespace}:${name}`;
        return {
          title: name,
          key: `${chartKey}.${key}`,
          render: (_, doc) => {
            const variable =
              doc[chartKey]?.variables && doc[chartKey]?.variables[key];
            return <Variable variable={variable} />;
          },
        };
      },
    );
  }, [dashboard, chartKey]);

  const chartDataAsString = useMemo(() => {
    if (!dashboard?.[chartDataProviderKey]) {
      return '';
    }
    return map(dashboard?.[chartDataProviderKey]?.dataProvider, (row) => {
      return JSON.stringify(row);
    }).join('\n');
  }, [dashboard, chartDataProviderKey]);

  const allColumns = useMemo(() => {
    return [...baseColumns, ...dataProviderColumns];
  }, [baseColumns, dataProviderColumns]);

  const editorRef = useRef(null);
  const handleOnLoadChart = useCallback(
    (chart) => {
      setCurrentChart(chart);
      editorRef.current?.setValue(chart);
    },
    [editorRef, setCurrentChart],
  );

  if (!programReady) {
    return null;
  }

  return (
    <>
      {dashboard?.programError && (
        <Alert type="danger" message={dashboard?.programError} />
      )}
      {chartKey && dashboard?.[chartKey]?.programError && (
        <Alert type="danger" message={dashboard?.[chartKey]?.programError} />
      )}
      <Sidebar
        align="top"
        sidebar={
          <SavedCharts
            dashboardId={dashboardId}
            currentChart={currentChart}
            onLoadChart={handleOnLoadChart}
            value={chartId}
            onChange={setChartId}
          />
        }
      >
        <ChartEditor
          ref={editorRef}
          dashboardId={dashboardId}
          value={currentChart}
          onChange={setCurrentChart}
        />
      </Sidebar>
      <Table
        dataSource={items}
        columns={allColumns}
        loading={!subscriptionsReady}
        pagination={getPaginationProps()}
      />
      {chartDataAsString && (
        <CodeMirror value={chartDataAsString} editable={false} />
      )}
    </>
  );
};

AnalyticsTable.propTypes = {
  projectId: PropTypes.string.isRequired,
  dashboardId: PropTypes.string.isRequired,
  recipientId: PropTypes.string,
};

AnalyticsTable.defaultProps = {
  recipientId: null,
};

export default AnalyticsTable;
