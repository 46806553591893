import has from 'lodash/has';
import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { AnimatePresence, motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import Dialog from './Dialog';
import Button from './Button';
import { UI_SIZES, UI_SIZE__MEDIUM } from '../ui/schemata';

const DialogWithSteps = ({
  'data-testid': testId,
  title,
  size,
  tooltip,
  visible,
  loading,
  isOkDisabled,
  isNextDisabled,
  okText,
  onOk,
  onCancel,
  onNext,
  steps,
}) => {
  const { t } = useTranslation();

  const [currentStepKey, setCurrentStepKey] = useState(steps[0]?.key);

  const currentStep = useMemo(() => {
    return steps.find((step) => step.key === currentStepKey);
  }, [steps, currentStepKey]);

  const isFirstStep = useMemo(() => {
    const idx = steps.findIndex((step) => step.key === currentStepKey);

    return idx === 0;
  }, [steps, currentStepKey]);

  const handleOnNext = () => {
    const currentIdx = steps.findIndex((step) => step.key === currentStepKey);
    const nextStep = steps[currentIdx + 1];

    if (!isNextDisabled) {
      if (nextStep && has(currentStep, 'isValid') && currentStep.isValid) {
        setCurrentStepKey(nextStep.key);
      }

      onNext();
    }
  };

  const handleOnOk = () => {
    if (!isOkDisabled) {
      onOk();
    }
  };

  const handleOnBack = () => {
    const currentIdx = steps.findIndex((step) => step.key === currentStepKey);
    const prevStep = steps[currentIdx - 1];

    if (prevStep) {
      setCurrentStepKey(prevStep.key);
    }
  };

  const handleOnCancel = () => {
    // FIXME: Make same value as the one used inside "useState"
    setCurrentStepKey(steps[0]?.key);
    onCancel();
  };

  const backVariants = {
    initial: {
      y: '200%',
    },
    animate: {
      y: 0,
    },
    exit: {
      y: '200%',
    },
  };

  // TODO: Add RTL support
  const contentVariants = {
    initial: {
      x: isFirstStep ? '100%' : '-100%',
    },
    animate: {
      x: 0,
    },
    exit: {
      x: isFirstStep ? '-100%' : '100%',
    },
  };

  return (
    <Dialog
      data-testid={testId}
      title={title}
      size={size}
      tooltip={tooltip}
      loading={loading}
      visible={visible}
      onCancel={handleOnCancel}
      containsAnimations
      footer={
        <div className="cluster-4">
          <AnimatePresence>
            {!isFirstStep && (
              <motion.div
                initial="initial"
                animate="animate"
                exit="exit"
                variants={backVariants}
                transition={{
                  type: 'spring',
                  bounce: 0.1,
                  duration: 400 / 1000,
                }}
              >
                <Button
                  data-testid="button-back"
                  type="ghost"
                  disabled={loading}
                  onClick={handleOnBack}
                >
                  {t('back')}
                </Button>
              </motion.div>
            )}
          </AnimatePresence>
          {isFirstStep ? (
            <Button
              data-testid="button-next"
              type="primary"
              loading={loading}
              disabled={isNextDisabled}
              onClick={handleOnNext}
            >
              {t('next')}
            </Button>
          ) : (
            <Button
              data-testid="button-ok"
              type="primary"
              loading={loading}
              disabled={isOkDisabled}
              onClick={handleOnOk}
            >
              {okText || t('ok')}
            </Button>
          )}
        </div>
      }
    >
      <AnimatePresence initial={false}>
        <motion.div
          key={currentStepKey}
          initial="initial"
          animate="animate"
          exit="exit"
          variants={contentVariants}
          transition={{
            type: 'spring',
            damping: 16,
            duration: 400 / 1000,
          }}
          className="absolute top-0 inset-x-0 p-6"
        >
          {currentStep?.content}
        </motion.div>
      </AnimatePresence>
    </Dialog>
  );
};

DialogWithSteps.propTypes = {
  'data-testid': PropTypes.string,
  title: PropTypes.string.isRequired,
  size: PropTypes.oneOf(UI_SIZES),
  tooltip: PropTypes.string,
  visible: PropTypes.bool,
  loading: PropTypes.bool,
  isNextDisabled: PropTypes.bool,
  isOkDisabled: PropTypes.bool,
  okText: PropTypes.string,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  onNext: PropTypes.func,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      content: PropTypes.node.isRequired,
      isValid: PropTypes.bool,
    }),
  ),
};

DialogWithSteps.defaultProps = {
  'data-testid': 'dialog-with-steps',
  size: UI_SIZE__MEDIUM,
  tooltip: null,
  visible: false,
  loading: false,
  isNextDisabled: false,
  isOkDisabled: false,
  okText: null,
  onOk: null,
  onCancel: () => {},
  onNext: () => {},
  steps: [],
};

export default DialogWithSteps;
