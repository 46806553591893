import moment from 'moment';
import React from 'react';
import isDate from 'lodash/isDate';
import isNil from 'lodash/isNil';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createUtcToLocalTime } from '@zedoc/date';
import { createStructuredSelector, createSelector } from 'reselect';
import { property } from '@zedoc/selectors';
import { default as ProjectSelect } from '../../common/selectors/Project';
import Tooltip from '../../common/components/Tooltip';
import useDateTimeFormat from '../../utils/useDateTimeFormat';

const getDateTimeFormatOptions = ({ showTime, short, timeZone }) => {
  if (showTime) {
    return { dateStyle: 'short', timeStyle: 'short', timeZone };
  }

  if (short) {
    return { dateStyle: 'medium', timeZone };
  }

  return { dateStyle: 'long', timeZone };
};

const yearMonthDayToLocalTs = (timezone, yearMonthDay) => {
  const utcToLocalTime = createUtcToLocalTime(timezone, {
    // NOTE: What "strict" mean in this case is that we are trying to find an exact
    //       timestamp representation for the clock time, e.g. "YYYY-MM-DDT00:000",
    //       but this may not exist sometimes, e.g. in case of DST switch,
    //       for example: Pacific/Auckland timezone, 2020-09-28T02:30.
    noStrict: true,
  });
  const m = moment.utc(yearMonthDay);
  if (!m.isValid()) {
    return null;
  }
  const utcTs = m.valueOf();
  return utcToLocalTime(utcTs);
};

const LocalDate = connect(() => {
  const selectProject = ProjectSelect.one().whereIdMatchesProp('projectId');
  const selectMoment = createSelector(
    selectProject,
    property('date'),
    (project, date) => {
      if (!project || isNil(date)) {
        return moment.invalid();
      }
      let m;
      if (isDate(date)) {
        m = moment(date);
      } else if (typeof date === 'string') {
        const localTs = yearMonthDayToLocalTs(project.getTimezone(), date);
        if (!localTs) {
          m = moment.invalid();
        } else {
          m = moment(localTs);
        }
      }
      if (m.isValid()) {
        return project.getMomentInLocalTime(m.toDate());
      }
      return moment.invalid();
    },
  );

  return createStructuredSelector({
    project: selectProject,
    m: selectMoment,
    options: createSelector(
      property('showTime'),
      property('short'),
      selectProject,
      (showTime, short, project) =>
        getDateTimeFormatOptions({
          showTime,
          short,
          timeZone: project?.getTimezone(),
        }),
    ),
  });
})(({ project, options, m, className, showTimezone }) => {
  const dateTimeFormat = useDateTimeFormat({
    date: m.toDate(),
    options,
  });
  const localDate = m.isValid() ? dateTimeFormat : 'N/A';

  return showTimezone ? (
    <Tooltip
      containerClassName={className}
      title={project && project.getTimezone()}
    >
      {localDate}
    </Tooltip>
  ) : (
    <span className={className}>{localDate}</span>
  );
});

LocalDate.propTypes = {
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  projectId: PropTypes.string.isRequired,
  className: PropTypes.string,
  showTime: PropTypes.bool,
  short: PropTypes.bool,
  showTimezone: PropTypes.bool,
};

LocalDate.defaultProps = {
  date: null,
  className: '',
  showTime: false,
  short: false,
  showTimezone: false,
};

export default LocalDate;
