import map from 'lodash/map';
import filter from 'lodash/filter';
import {
  PROJECT_DASHBOARD_TYPE__ANALYTICS,
  MILESTONE_DEPENDENCY_CONDITION__QUESTIONNAIRE_ASSIGNEES_COMPLETED,
} from '../common/constants';

const filterDashboards = (dashboard) => {
  switch (dashboard.type) {
    case PROJECT_DASHBOARD_TYPE__ANALYTICS: {
      if (!dashboard.perspective) {
        // NOTE: Previously this was interpreted as "chartBuilder",
        //       but that type of dashboard is not used anymore.
        return false;
      }
      return true;
    }
    case 'actionItems':
    case 'general':
    case 'patient':
    case 'CAT':
    case 'NLP':
    case 'chartBuilder':
    case 'externalIFrame':
    case 'demographics': {
      return false;
    }
    default: {
      return true;
    }
  }
};

const filterMilestones = () => {
  return true;
};

const cleanDashboard = (dashboard) => {
  switch (dashboard.type) {
    case 'milestonesTable': {
      return {
        ...dashboard,
        type: 'timeline',
      };
    }
    default: {
      // ...
    }
  }
  return dashboard;
};

const cleanMilestoneDependency = (dependency) => {
  if (dependency.condition) {
    return dependency;
  }
  return {
    ...dependency,
    condition:
      MILESTONE_DEPENDENCY_CONDITION__QUESTIONNAIRE_ASSIGNEES_COMPLETED,
  };
};

const cleanMilestone = (milestone) => {
  if (!milestone.dependencies) {
    return milestone;
  }
  return {
    ...milestone,
    dependencies: map(milestone.dependencies, cleanMilestoneDependency),
  };
};

const cleanProjectProperties = (properties) => {
  const { editorVersion, dashboards, milestones, ...other } = properties;
  return {
    ...other,
    milestones: filter(milestones, filterMilestones).map(cleanMilestone),
    dashboards: filter(dashboards, filterDashboards).map(cleanDashboard),
  };
};

export default cleanProjectProperties;
