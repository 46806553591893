import './styles.css';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import { useTranslation } from 'react-i18next';
import Group from './Group';
import GroupHeading from './GroupHeading';
import SelectContainer from './SelectContainer';
import Option from './Option';
import MultiValue from './MultiValue';
import { getValue } from './utils';

const IndicatorSeparator = () => null;

const Select = ({
  'data-testid': testId,
  options,
  value,
  onChange,
  onAfterChange,
  inputValue,
  onInputChange,
  placeholder,
  isMulti,
  isDisabled,
  hasError,
}) => {
  const { t } = useTranslation();

  const handleOnChange = (res) => {
    const val = isMulti ? res.map((o) => o.value) : res.value;

    onChange(val);
    onAfterChange(val);
  };

  const memoValue = useMemo(
    () => getValue(options, value, (opt) => opt.value, isMulti),
    [options, value, isMulti],
  );

  // TODO: Improve disabled styling
  return (
    <ReactSelect
      testId={testId}
      // NOTE: Passing key because sometimes the component is not re-rendered on value change
      // this is to make sure it does
      // https://github.com/JedWatson/react-select/issues/3066
      key={JSON.stringify(memoValue)}
      className="z-react-select-container"
      classNamePrefix="z-react-select"
      components={{
        SelectContainer,
        Group,
        GroupHeading,
        Option,
        IndicatorSeparator,
        MultiValue,
      }}
      options={options}
      value={memoValue}
      placeholder={placeholder}
      isDisabled={isDisabled}
      isMulti={isMulti}
      closeMenuOnSelect={!isMulti}
      onChange={handleOnChange}
      inputValue={inputValue}
      onInputChange={onInputChange}
      menuPosition="fixed"
      menuPortalTarget={document.body}
      noOptionsMessage={() => t('noOptions')}
      hideSelectedOptions={false}
      styles={{
        control: (baseStyles) => ({
          ...baseStyles,
          ...(hasError
            ? {
                // 'box-shadow': '0px 0px 0px 2px var(--color-danger-800)',
                // 'border-width': '0px',
                border: '1px solid var(--color-danger-800) !important',
              }
            : {}),
        }),
      }}
      // NOTE: Useful prop for DOM debugging to keep the menu open
      // menuIsOpen
    />
  );
};

Select.propTypes = {
  'data-testid': PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          label: PropTypes.string,
        }),
      ),
    }),
  ),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  onChange: PropTypes.func,
  onAfterChange: PropTypes.func,
  placeholder: PropTypes.string,
  isDisabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  inputValue: PropTypes.string,
  onInputChange: PropTypes.func,
  hasError: PropTypes.bool,
};

Select.defaultProps = {
  'data-testid': 'select',
  options: [],
  value: null,
  onChange: () => {},
  onAfterChange: () => {},
  placeholder: null,
  isDisabled: false,
  isMulti: false,
  inputValue: undefined,
  onInputChange: null,
  hasError: false,
};

export default Select;
