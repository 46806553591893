import { ChevronDownIcon } from '@heroicons/react/outline';
import keyBy from 'lodash/keyBy';
import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { motion, AnimatePresence } from 'framer-motion';
import { FILTER_CONDITION__SEARCH_TERMS } from '../common/constants';
import flattenFilters from '../utils/flattenFilters';
import Highlighter from '../common/components/Highlighter';

const MatchedProperties = ({ rawFilters, allMatchedProperties }) => {
  const { t } = useTranslation();
  const propertiesByFilterId = useMemo(() => {
    return groupBy(allMatchedProperties, 'filterId');
  }, [allMatchedProperties]);
  const filtersById = useMemo(
    () => keyBy(flattenFilters(rawFilters), 'id'),
    [rawFilters],
  );
  const [isVisible, setIsVisible] = useState(false);
  const handleOnToggleFiltersList = () => setIsVisible(!isVisible);
  if (isEmpty(propertiesByFilterId)) {
    return null;
  }
  return (
    <div>
      <button
        data-testid="matched-properties-toggle"
        type="button"
        className="cluster-2 items-center hover:underline"
        onClick={handleOnToggleFiltersList}
      >
        <span className="text-sm">
          {t('filtersMatching', {
            count: Object.keys(propertiesByFilterId).length,
          })}
        </span>
        <ChevronDownIcon
          className={`h-4 w-4 transform transition-transform ${
            isVisible ? ' rotate-180' : ''
          }`}
        />
      </button>
      <AnimatePresence initial={false}>
        {isVisible && (
          <motion.ul
            data-testid="matched-properties-list"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { height: 'auto' },
              collapsed: { height: 0 },
            }}
            className="list-disc list-inside stack-1 overflow-hidden"
          >
            {map(propertiesByFilterId, (matchedProperties, filterId) => {
              const filter = filtersById[filterId];
              if (
                filter &&
                filter.condition !== FILTER_CONDITION__SEARCH_TERMS
              ) {
                return (
                  <li
                    data-testid={`matched-properties-item-${filter.name}`}
                    className="text-sm font-medium first:mt-4"
                    key={filterId}
                  >
                    <span>
                      {filter.name}
                      {/* eslint-disable-next-line react/jsx-curly-brace-presence */}
                      {':'}
                      &nbsp;
                    </span>
                    {map(matchedProperties, ({ key, value }) => {
                      const label = filter.meta?.labels?.[value];
                      return (
                        <Highlighter
                          key={key}
                          text={`${label ?? value}`}
                          highlight={`${label ?? value}`}
                        />
                      );
                    })}
                  </li>
                );
              }
              return null;
            })}
          </motion.ul>
        )}
      </AnimatePresence>
    </div>
  );
};

MatchedProperties.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  rawFilters: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  allMatchedProperties: PropTypes.array,
};

MatchedProperties.defaultProps = {
  rawFilters: [],
  allMatchedProperties: [],
};

export default MatchedProperties;
