import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useRef, useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDDPSubscription, useDDPCall } from '@zedoc/ddp-connector';
import { useSelector, useDispatch } from 'react-redux';
import { slugify } from '@zedoc/text';
import { YEAR_MONTH_DAY } from '../../../common/constants';
import { default as ProjectSelect } from '../../../common/selectors/Project';
import Text from '../../../common/components/base/Text';
import Button from '../../../common/components/Button';
import Stack from '../../../common/components/primitives/Stack';
import InputDate from '../../../common/components/InputDate';
import Modal from '../../../components/dialogs/Modal';
import FormItem from '../../../common/components/FormItem';
import Switch from '../../../common/components/Switch';
import Select from '../../../components/inputs/Select';
import { notifySuccess } from '../../../utils/notify';
import { apiZedocOneProject } from '../../../common/api/zedoc';
import { apiCurrentUserUpdateUserDashboard } from '../../../common/api/currentUser';
import { closeDownloadResponsesCSVDialog } from '../actions';
import {
  getDownloadResponsesCSVDialogVisible,
  getDownloadResponsesCSVDialogOptions,
} from '../selectors';
import { CSV_RESPONSES_EXTRA_COLUMNS } from '../../../common/models/UserDashboard';
import useDownload from '../../../utils/useDownload';

const EXTRA_COLUMNS_OPTIONS = CSV_RESPONSES_EXTRA_COLUMNS.map((value) => ({
  value,
  label: value,
}));

const DownloadResponsesCSV = ({ projectId }) => {
  useDDPSubscription(
    apiZedocOneProject.withParams({
      projectId,
    }),
  );

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const project = useSelector(ProjectSelect.one().whereIdEquals(projectId));
  const isVisible = useSelector(getDownloadResponsesCSVDialogVisible);
  const { dashboardId, csvResponsesOptions } = useSelector(
    getDownloadResponsesCSVDialogOptions,
  );
  const [questionIdAsHeader, setQuestionIdAsHeader] = useState(false);
  const [extraColumns, setExtraColumns] = useState([]);
  const [dateStart, setDateStart] = useState(
    moment().subtract(90, 'days').format(YEAR_MONTH_DAY),
  );
  const [dateEnd, setDateEnd] = useState('');
  const urlRef = useRef();

  const isStartDateAfterEndDate = useMemo(
    () => moment(dateStart).isAfter(dateEnd),
    [dateStart, dateEnd],
  );
  const isEndDateBeforeStartDate = useMemo(
    () => moment(dateEnd).isBefore(dateStart),
    [dateStart, dateEnd],
  );

  useEffect(() => {
    if (csvResponsesOptions) {
      setQuestionIdAsHeader(csvResponsesOptions.questionIdAsHeader);
      setExtraColumns(csvResponsesOptions.extraColumns);
    }
  }, [csvResponsesOptions]);

  const { ddpCall } = useDDPCall();
  const download = useDownload();

  const handleOnCancel = () => dispatch(closeDownloadResponsesCSVDialog());
  const handleOnConfirm = () => {
    if (isEndDateBeforeStartDate || isStartDateAfterEndDate) {
      return;
    }

    const timezone = project && project.getTimezone();
    const query = {};

    if (dateStart) {
      query.dateStart = dateStart;
    }

    if (dateEnd) {
      query.dateEnd = dateEnd;
    }

    if (timezone) {
      query.timezone = timezone;
    }

    if (questionIdAsHeader) {
      query.questionIdAsHeader = true;
    }

    if (extraColumns?.length > 0) {
      query.extra = extraColumns;
    }

    if (dashboardId) {
      ddpCall(
        apiCurrentUserUpdateUserDashboard.withParams({
          dashboardId,
          csvResponsesOptions: {
            questionIdAsHeader,
            extraColumns,
          },
        }),
      ).catch(() => {
        // Ignore error
      });
    }

    const path = `/api/v1/csvResponses/projects/${project?._id}`;

    download(path, {
      query,
      name: slugify(project?.getName() ?? ''),
    });

    setTimeout(() => {
      notifySuccess(t('confirmations:downloadResponsesCSV.success'))();
    }, 1000);

    handleOnCancel();
  };

  return (
    <Modal
      title={t('confirmations:downloadResponsesCSV.title')}
      tooltip={t('confirmations:downloadResponsesCSV.tooltip')}
      onCancel={handleOnCancel}
      footer={
        <>
          <Button
            data-testid="button-ok"
            type="primary"
            disabled={isEndDateBeforeStartDate || isStartDateAfterEndDate}
            onClick={handleOnConfirm}
          >
            {t('confirmAndDownload')}
          </Button>
        </>
      }
      visible={isVisible}
    >
      <a ref={urlRef} href="/" className="hidden" hidden="true">
        &nbsp;
      </a>
      <Stack>
        <FormItem
          label={t('forms:dateStart.label')}
          help={
            isEndDateBeforeStartDate
              ? t('forms:dateStart.errorCannotBeAfterEndDate')
              : ''
          }
          validateStatus="danger"
        >
          <InputDate
            data-testid="form-field-start-date"
            onChange={setDateStart}
            value={dateStart}
            max={dateEnd}
          />
        </FormItem>
        <FormItem
          label={t('forms:dateEnd.label')}
          help={
            isStartDateAfterEndDate
              ? t('forms:dateEnd.errorCannotBeBeforeStartDate')
              : ''
          }
          validateStatus="danger"
        >
          <InputDate
            data-testid="form-field-end-date"
            onChange={setDateEnd}
            value={dateEnd}
            min={dateStart}
          />
        </FormItem>
        {project && (
          <Text.Paragraph>
            * {project.timezone} {t('forms:timezone.label')}
          </Text.Paragraph>
        )}
        <FormItem label={t('forms:questionIdAsHeader.label')}>
          <Switch
            data-testid="form-field-question-id-as-header"
            checked={questionIdAsHeader}
            onChange={setQuestionIdAsHeader}
          />
        </FormItem>
        <FormItem label={t('forms:extraColumns.label')}>
          <Select
            data-testid="form-field-extra-columns"
            value={extraColumns}
            onClear={() => setExtraColumns([])}
            onChange={setExtraColumns}
            options={EXTRA_COLUMNS_OPTIONS}
            multiple
          />
        </FormItem>
      </Stack>
    </Modal>
  );
};

DownloadResponsesCSV.propTypes = {
  projectId: PropTypes.string.isRequired,
};

export default DownloadResponsesCSV;
