import { isoLangs } from '@zedoc/iso-langs';
import map from 'lodash/map';
import {
  FILTER_TYPE__PROPERTY,
  // FILTER_TYPE__TAG,
  FILTER_CONDITION__TEXT,
  FILTER_CONDITION__INCLUDE,
  FILTER_CONDITION__EXCLUDE,
  FILTER_CONDITION__EXISTS,
  FILTER_CONDITION__DOES_NOT_EXIST,
  // FILTER_CONDITION__EQUALS,
  // FILTER_CONDITION__NOT_EQUAL,
  // FILTER_CONDITION__MINIMUM,
  // FILTER_CONDITION__MAXIMUM,
  // FILTER_CONDITION__EXCLUSIVE_MINIMUM,
  // FILTER_CONDITION__EXCLUSIVE_MAXIMUM,
  FILTER_CONDITION__DATE_EQUALS,
  FILTER_CONDITION__DATE_NOT_EQUAL,
  FILTER_CONDITION__DATE_AFTER,
  FILTER_CONDITION__DATE_BEFORE,
  FILTER_CONDITION__DATE_SAME_OR_AFTER,
  FILTER_CONDITION__DATE_SAME_OR_BEFORE,
  VARIABLE_ID__NAME_FIRST,
  VARIABLE_ID__NAME_LAST,
} from '../../../../common/constants';
import { FILTER_CATEGORY__DASHBOARD } from '../../../../components/Filters';

const defaultFilters = [
  {
    name: 'Full name',
    type: FILTER_TYPE__PROPERTY,
    condition: FILTER_CONDITION__TEXT,
    settings: {
      id: 'name.text',
    },
    meta: {
      category: FILTER_CATEGORY__DASHBOARD,
      conditions: [
        FILTER_CONDITION__TEXT,
        FILTER_CONDITION__EXISTS,
        FILTER_CONDITION__DOES_NOT_EXIST,
      ],
    },
  },
  {
    name: 'First name',
    type: FILTER_TYPE__PROPERTY,
    condition: FILTER_CONDITION__TEXT,
    settings: {
      id: 'name.given.0',
    },
    meta: {
      category: FILTER_CATEGORY__DASHBOARD,
      conditions: [
        FILTER_CONDITION__TEXT,
        FILTER_CONDITION__EXISTS,
        FILTER_CONDITION__DOES_NOT_EXIST,
      ],
      includeIfVariableIdPresent: VARIABLE_ID__NAME_FIRST,
    },
  },
  {
    name: 'Last name',
    type: FILTER_TYPE__PROPERTY,
    condition: FILTER_CONDITION__TEXT,
    settings: {
      id: 'name.family',
    },
    meta: {
      category: FILTER_CATEGORY__DASHBOARD,
      conditions: [
        FILTER_CONDITION__TEXT,
        FILTER_CONDITION__EXISTS,
        FILTER_CONDITION__DOES_NOT_EXIST,
      ],
      includeIfVariableIdPresent: VARIABLE_ID__NAME_LAST,
    },
  },
  {
    name: 'Gender',
    type: FILTER_TYPE__PROPERTY,
    condition: FILTER_CONDITION__INCLUDE,
    settings: {
      id: 'gender',
    },
    meta: {
      category: FILTER_CATEGORY__DASHBOARD,
      conditions: [
        FILTER_CONDITION__INCLUDE,
        FILTER_CONDITION__EXCLUDE,
        FILTER_CONDITION__EXISTS,
        FILTER_CONDITION__DOES_NOT_EXIST,
      ],
    },
  },
  {
    name: 'Ethnicity',
    type: FILTER_TYPE__PROPERTY,
    condition: FILTER_CONDITION__INCLUDE,
    settings: {
      id: 'ethnicity',
    },
    meta: {
      category: FILTER_CATEGORY__DASHBOARD,
      conditions: [
        FILTER_CONDITION__INCLUDE,
        FILTER_CONDITION__EXCLUDE,
        FILTER_CONDITION__EXISTS,
        FILTER_CONDITION__DOES_NOT_EXIST,
      ],
    },
  },
  {
    name: 'Date of birth',
    type: FILTER_TYPE__PROPERTY,
    condition: FILTER_CONDITION__DATE_EQUALS,
    settings: {
      id: 'birthDate',
      valueType: 'string',
    },
    meta: {
      category: FILTER_CATEGORY__DASHBOARD,
      conditions: [
        FILTER_CONDITION__DATE_EQUALS,
        FILTER_CONDITION__DATE_NOT_EQUAL,
        FILTER_CONDITION__DATE_SAME_OR_AFTER,
        FILTER_CONDITION__DATE_SAME_OR_BEFORE,
        FILTER_CONDITION__DATE_AFTER,
        FILTER_CONDITION__DATE_BEFORE,
      ],
    },
  },
  {
    name: 'Any identifier',
    type: FILTER_TYPE__PROPERTY,
    condition: FILTER_CONDITION__TEXT,
    settings: {
      id: 'identifiers.value',
    },
    meta: {
      category: FILTER_CATEGORY__DASHBOARD,
      conditions: [
        FILTER_CONDITION__TEXT,
        FILTER_CONDITION__EXISTS,
        FILTER_CONDITION__DOES_NOT_EXIST,
      ],
    },
  },
  {
    name: 'Email',
    type: FILTER_TYPE__PROPERTY,
    condition: FILTER_CONDITION__TEXT,
    settings: {
      id: 'emails.address',
    },
    meta: {
      category: FILTER_CATEGORY__DASHBOARD,
      conditions: [
        FILTER_CONDITION__TEXT,
        FILTER_CONDITION__EXISTS,
        FILTER_CONDITION__DOES_NOT_EXIST,
      ],
    },
  },
  {
    name: 'Phone',
    type: FILTER_TYPE__PROPERTY,
    condition: FILTER_CONDITION__TEXT,
    settings: {
      id: 'phones.number',
    },
    meta: {
      category: FILTER_CATEGORY__DASHBOARD,
      conditions: [
        FILTER_CONDITION__TEXT,
        FILTER_CONDITION__EXISTS,
        FILTER_CONDITION__DOES_NOT_EXIST,
      ],
    },
  },
  {
    name: 'Language',
    type: FILTER_TYPE__PROPERTY,
    condition: FILTER_CONDITION__INCLUDE,
    settings: {
      id: 'languagePreference',
      valueType: 'array',
    },
    meta: {
      jsonSchema: {
        anyOf: map(isoLangs, ({ name }, value) => ({
          const: value,
          title: name,
        })),
      },
      category: FILTER_CATEGORY__DASHBOARD,
      conditions: [
        FILTER_CONDITION__INCLUDE,
        FILTER_CONDITION__EXCLUDE,
        FILTER_CONDITION__TEXT,
        FILTER_CONDITION__EXISTS,
        FILTER_CONDITION__DOES_NOT_EXIST,
      ],
    },
  },
];

export default defaultFilters;
