import { useRef, useEffect, useCallback } from 'react';

/**
 * @param {object} [options]
 * @param {boolean} [options.noInitialDelay]
 */
const useDelay = (options = {}) => {
  const { noInitialDelay = false } = options;
  const ref = useRef({
    cancel: () => {},
    isInitial: true,
  });
  const delay = useCallback(
    (ms) => {
      return new Promise((resolve) => {
        let fulfilled = false;
        const complete = () => {
          if (!fulfilled) {
            fulfilled = true;
            ref.current = null;
            resolve(true);
          }
        };
        if (ref.current) {
          ref.current.cancel();
        }
        if (ref.current && ref.current.isInitial && noInitialDelay) {
          complete();
          return;
        }
        const handle = setTimeout(complete, ms);
        ref.current = {
          cancel: () => {
            if (!fulfilled) {
              fulfilled = true;
              clearTimeout(handle);
              resolve(false);
            }
          },
        };
      });
    },
    [noInitialDelay],
  );
  useEffect(() => {
    return () => {
      if (ref.current) {
        ref.current.cancel();
        ref.current = null;
      }
    };
  }, [ref]);
  return delay;
};

export default useDelay;
