import ApiSpec from './ApiSpec';
import { PATIENT_MILESTONE_COMPLETE_ANSWERS_SHEET } from '../permissions';
import { ACTION_TYPE__REVIEW } from '../messages';
import Id from '../schemata/Id';
import { default as ResponseSchema } from '../schemata/Response';

export const answersSheetAndQuestionnaire = new ApiSpec({
  name: 'api.forms.answersSheetAndQuestionnaire',
  actionType: ACTION_TYPE__REVIEW,
  permissions: [PATIENT_MILESTONE_COMPLETE_ANSWERS_SHEET],
  schema: {
    type: 'object',
    required: ['answersSheetId'],
    properties: {
      answersSheetId: Id,
    },
  },
});

export const complete = new ApiSpec({
  name: 'api.forms.complete',
  permissions: [PATIENT_MILESTONE_COMPLETE_ANSWERS_SHEET],
  schema: {
    type: 'object',
    required: ['answersSheetId'],
    properties: {
      answersSheetId: Id,
      validateOnly: {
        type: 'boolean',
      },
    },
  },
});

export const currentSessions = new ApiSpec({
  name: 'api.forms.currentSessions',
  permissions: [PATIENT_MILESTONE_COMPLETE_ANSWERS_SHEET],
  schema: {
    type: 'object',
    required: ['answersSheetId'],
    properties: {
      answersSheetId: Id,
    },
  },
});

export const getInitialValues = new ApiSpec({
  name: 'api.forms.getInitialValues',
  permissions: [PATIENT_MILESTONE_COMPLETE_ANSWERS_SHEET],
  schema: {
    type: 'object',
    required: ['answersSheetId'],
    properties: {
      answersSheetId: Id,
    },
  },
});

export const heartbeat = new ApiSpec({
  name: 'api.forms.heartbeat',
  permissions: [PATIENT_MILESTONE_COMPLETE_ANSWERS_SHEET],
  schema: {
    type: 'object',
    required: ['answersSheetId'],
    properties: {
      answersSheetId: Id,
    },
  },
});

export const pauseAndSaveDraft = new ApiSpec({
  name: 'api.forms.pauseAndSaveDraft',
  permissions: [PATIENT_MILESTONE_COMPLETE_ANSWERS_SHEET],
  schema: {
    type: 'object',
    properties: {
      answersSheetId: Id,
    },
  },
});

export const resolveConflicts = new ApiSpec({
  name: 'api.forms.resolveConflicts',
  permissions: [PATIENT_MILESTONE_COMPLETE_ANSWERS_SHEET],
  schema: {
    type: 'object',
    required: ['answersSheetId'],
    properties: {
      answersSheetId: Id,
      resolution: {
        type: 'array',
        items: ResponseSchema,
      },
      initialResolution: {
        type: 'array',
        items: ResponseSchema,
      },
    },
  },
});

export const saveProgress = new ApiSpec({
  name: 'api.forms.saveProgress',
  permissions: [PATIENT_MILESTONE_COMPLETE_ANSWERS_SHEET],
  schema: {
    type: 'object',
    required: ['newResponses'],
    properties: {
      answersSheetId: Id,
      newResponses: {
        type: 'array',
        items: ResponseSchema,
      },
    },
  },
});

export const undoLastChanges = new ApiSpec({
  name: 'api.forms.undoLastChanges',
  permissions: [PATIENT_MILESTONE_COMPLETE_ANSWERS_SHEET],
  schema: {
    type: 'object',
    required: ['answersSheetId'],
    properties: {
      answersSheetId: Id,
    },
  },
});
