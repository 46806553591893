import React, { useState, useCallback } from 'react';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import { useDDPSubscription } from '@zedoc/ddp-connector';
import { createSelector } from 'reselect';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { callMethod } from '../../../common/utilsClient/ddp/actions';
import { notifyError, notifySuccess } from '../../../utils/notify';
import {
  apiAdminOneUser,
  apiAdminCreateUser,
  apiAdminUpdateUser,
  apiAdminAllRoles,
  apiAdminAllUsersGroups,
} from '../../../common/api/admin';
import { default as PermissionsDomainSelect } from '../../../common/selectors/PermissionsDomain';
import { default as RoleSelect } from '../../../common/selectors/Role';
import {
  ADMIN_CREATE_USER,
  ADMIN_DELETE_USER,
  ADMIN_UPDATE_USER,
} from '../../../common/permissions';
import { default as UsersGroupSelect } from '../../../common/selectors/UsersGroup';
import EditUser from '../../../components/dialogs/EditUser';
import {
  getActiveUser,
  getActiveUserId,
  getEditUserDialogVisible,
  closeEditUserDialog,
} from '../store';
import PermissionsDomain from '../../../common/models/PermissionsDomain';
import usePermissionsRealm from '../../../utils/usePermissionsRealm';
import { clearValue } from '../../../utils/sanitize';

const selectRolesByPermissionDomain = createSelector(
  RoleSelect.all(),
  (roles) => groupBy(roles, 'belongsTo'),
);

const selectGroupsByPermissionDomain = createSelector(
  UsersGroupSelect.all(),
  (groups) => groupBy(groups, 'belongsTo'),
);

const selectPermissionDomains = PermissionsDomainSelect.all().sort({
  name: 1,
});

const createOptionsSelector = (selectByPermissionsDomain) =>
  createSelector(
    selectPermissionDomains,
    selectByPermissionsDomain,
    (permissionsDomains, byPermissionsDomain) =>
      map(permissionsDomains, (permissionDomain) => ({
        key: permissionDomain._id,
        label: permissionDomain.getName(),
        options: map(byPermissionsDomain[permissionDomain._id], (item) => ({
          value: item._id,
          label: item.getName(),
        })),
        isCollapsible: true,
      })),
  );

export default () => {
  const userId = useSelector(getActiveUserId);
  const [isConfirmLoading, setConfirmLoading] = useState(false);

  useDDPSubscription(userId && apiAdminOneUser.withParams({ userId }));
  useDDPSubscription(apiAdminAllRoles.withParams());
  useDDPSubscription(apiAdminAllUsersGroups.withParams());

  const open = useSelector(getEditUserDialogVisible);
  const user = useSelector(getActiveUser);

  const rolesDb = useSelector(RoleSelect.all().byId());
  const groupsDb = useSelector(UsersGroupSelect.all().byId());

  const { domains: createUserRealm } = usePermissionsRealm([ADMIN_CREATE_USER]);
  const { domains: updateUserRealm } = usePermissionsRealm([ADMIN_UPDATE_USER]);
  // NOTE: Normally deleteUserRealm will be smaller than createUserRealm,
  //       so the intersection is no-op. However, part of our business logic
  //       depends on this inclusion and things will get ugly if this condition
  //       is not met. So for the sake of safety, we prefer to take the intersection.
  const { domains: deleteUserRealm } = usePermissionsRealm([
    ADMIN_CREATE_USER,
    ADMIN_DELETE_USER,
  ]);

  const groupedRolesOptions = useSelector(
    createOptionsSelector(selectRolesByPermissionDomain),
  );
  const groupedGroupsOptions = useSelector(
    createOptionsSelector(selectGroupsByPermissionDomain),
  );
  const permissionsDomainsOptions = useSelector(
    createSelector(selectPermissionDomains, (permissionsDomains) =>
      map(permissionsDomains, (permissionDomain) => ({
        value: permissionDomain._id,
        label: permissionDomain.getName(),
      })),
    ),
  );

  const disabled = user
    ? !PermissionsDomain.belongsToRealm(
        user.getTopLevelDomains(),
        updateUserRealm,
      )
    : false;

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const onCreate = useCallback(
    (data) => {
      setConfirmLoading(true);

      dispatch(callMethod(apiAdminCreateUser, clearValue(data, 'name')))
        .then(notifySuccess(t('confirmations:addUser.success')))
        .then(() => dispatch(closeEditUserDialog()))
        .catch(notifyError())
        .then(() => setConfirmLoading(false));
    },
    [setConfirmLoading, dispatch, t],
  );

  const onUpdate = useCallback(
    (data) => {
      setConfirmLoading(true);

      dispatch(callMethod(apiAdminUpdateUser, clearValue(data, 'name')))
        .then(notifySuccess(t('confirmations:updateUser.success')))
        .then(() => dispatch(closeEditUserDialog()))
        .catch(notifyError())
        .then(() => setConfirmLoading(false));
    },
    [setConfirmLoading, dispatch, t],
  );

  const onCancel = useCallback(() => {
    dispatch(closeEditUserDialog());
  }, [dispatch]);

  return (
    <EditUser
      open={open}
      user={user}
      rolesDb={rolesDb}
      groupsDb={groupsDb}
      groupedRolesOptions={groupedRolesOptions}
      groupedGroupsOptions={groupedGroupsOptions}
      permissionsDomainsOptions={permissionsDomainsOptions}
      createUserRealm={createUserRealm}
      deleteUserRealm={deleteUserRealm}
      onCancel={onCancel}
      onCreate={onCreate}
      onUpdate={onUpdate}
      disabled={disabled}
      isConfirmLoading={isConfirmLoading}
    />
  );
};
