import { NULL_ANSWERS, RESPONSE_SOURCES } from '@zedoc/questionnaire';
import Answer from './Answer';

/**
 * @typedef {import('json-schema-to-ts').FromSchema<typeof Response>} TypeResponse
 */

const Response = /** @type {const} */ ({
  type: 'object',
  required: ['questionId'],
  properties: {
    questionId: {
      type: 'string',
    },
    answer: Answer,
    whyEmpty: {
      type: 'string',
      enum: NULL_ANSWERS,
    },
    hierarchyKey: {
      type: 'string',
    },
    source: {
      type: 'string',
      enum: RESPONSE_SOURCES,
    },
    editedTs: {
      type: 'number',
    },
  },
});

export default Response;
