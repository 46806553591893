import get from 'lodash/get';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, withHandlers, withProps } from 'recompose';
import { Field, FieldArray, reduxForm, submit } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { QUESTION_TYPE__FREE_TEXT } from '@zedoc/questionnaire';
import { QUESTIONNAIRE_ASSIGNEE_TYPE_OPTIONS } from '../../../common/constants';
import ProjectMilestone from '../../../common/models/ProjectMilestone';
import Stack from '../../../common/components/primitives/Stack';
import Text from '../../../common/components/base/Text';
import Modal from '../Modal';
import FormFieldText from '../../forms/FormFieldText';
import FormFieldSelect from '../../forms/FormFieldSelect';
import FormFieldList from '../../forms/FormFieldList';
import { makeJsonSchemaFormValidator } from '../../../utils/errors';

const QuestionnaireProperties = connect()(
  // ...
  ({ member, questionnaireOptions }) => {
    const { t } = useTranslation('forms');

    return (
      <Stack>
        <Field
          data-testid="form-field-questionnaire-label"
          component={FormFieldSelect}
          name={`${member}.identifier`}
          type="text"
          label={t('questionnaire.label')}
          options={questionnaireOptions}
          showSearch
        />
        <Field
          data-testid="form-field-assigneeType-label"
          component={FormFieldSelect}
          name={`${member}.assigneeType`}
          type="text"
          label={t('assigneeType.label')}
          options={QUESTIONNAIRE_ASSIGNEE_TYPE_OPTIONS.map((option) => ({
            ...option,
            label: t(option.label),
          }))}
          showSearch
        />
      </Stack>
    );
  },
);

const QuestionnairesList = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <FormFieldList {...props} properties={QuestionnaireProperties} />
);

const DEFAULT_FORM = 'EditMilestone';
const EditMilestoneForm = reduxForm({
  form: DEFAULT_FORM,
  validate: makeJsonSchemaFormValidator({
    type: 'object',
    required: ['name', 'questionnaires'],
    properties: {
      name: {
        type: 'string',
        title: 'Name',
        minLength: 1,
      },
      description: {
        type: 'string',
        title: 'Description',
      },
      questionnaires: {
        type: 'array',
        items: {
          type: 'object',
          required: ['identifier', 'assigneeType'],
          properties: {
            identifier: {
              type: 'string',
              title: 'Questionnaire',
            },
            assigneeType: {
              type: 'string',
              title: 'Assignee type',
            },
          },
        },
        minItems: 1,
      },
    },
  }),
})(({ form, error, handleSubmit, onSubmit, questionnaireOptions }) => {
  const { t } = useTranslation();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack>
        {error && <Text.Paragraph type="danger">{error}</Text.Paragraph>}
        <Field
          component={FormFieldText}
          type="text"
          name="name"
          label={t('forms:name.label')}
        />
        <Field
          component={FormFieldText}
          type={QUESTION_TYPE__FREE_TEXT}
          name="description"
          label={t('forms:description.label')}
        />
        <FieldArray
          name="questionnaires"
          label={t('questionnaires')}
          component={QuestionnairesList}
          props={{
            form,
            questionnaireOptions,
          }}
        />
      </Stack>
    </form>
  );
});

const EditMilestone = compose(
  withProps(({ milestone }) => ({
    milestone,
    initialValues: {
      ...(milestone && {
        name: milestone.name || '',
        description: milestone.description || '',
        questionnaires: milestone.questionnaires || [],
      }),
    },
  })),
  withHandlers({
    onSubmit:
      ({ milestone, onCreate, onUpdate }) =>
      (data) =>
        milestone
          ? onUpdate({
              ...data,
              milestoneId: milestone._id,
            })
          : onCreate(data),
  }),
  connect(
    () => ({}),
    (dispatch, { form }) => ({
      doSubmit: () => dispatch(submit(form)),
    }),
  ),
)(
  ({
    form,
    open,
    milestone,
    onCancel,
    onSubmit,
    doSubmit,
    initialValues,
    questionnaireOptions,
  }) => {
    const { t } = useTranslation();

    return (
      <Modal
        title={milestone ? t('editMilestone') : t('addMilestone')}
        visible={open}
        onOk={doSubmit}
        onCancel={onCancel}
      >
        <EditMilestoneForm
          form={form}
          onSubmit={onSubmit}
          initialValues={initialValues}
          questionnaireOptions={questionnaireOptions}
        />
      </Modal>
    );
  },
);

EditMilestone.propTypes = {
  form: PropTypes.string,
  open: PropTypes.bool.isRequired,
  milestone: PropTypes.instanceOf(ProjectMilestone),
  questionnaireOptions: get(FormFieldSelect.propTypes, 'options'),
  onCancel: PropTypes.func,
  onCreate: PropTypes.func,
  onUpdate: PropTypes.func,
};

EditMilestone.defaultProps = {
  form: DEFAULT_FORM,
  milestone: null,
  onCancel: () => {},
  onCreate: () => {},
  onUpdate: () => {},
};

export default EditMilestone;
