import { AUDIT_EVENT_TYPE__GENERIC_MUTATION } from '../../constants';
import { MESSAGE_TYPE__MUTATION, MUTATION_TYPES } from '../constants';
import Message from '../Message';

class MessageMutation extends Message {
  constructor(doc) {
    super({
      ...doc,
      type: MESSAGE_TYPE__MUTATION,
    });
  }

  isModel(Model) {
    return this.model === Model.name;
  }

  getDocId() {
    return this.docId;
  }

  getMutationType() {
    return this.mutationType;
  }

  // eslint-disable-next-line
  getAuditLogType() {
    return AUDIT_EVENT_TYPE__GENERIC_MUTATION;
  }

  toAuditLog(previousAuditLog) {
    const auditLog = super.toAuditLog(previousAuditLog);
    return {
      ...auditLog,
      type: this.getAuditLogType(),
      event: {
        ...auditLog.event,
        code: this.mutationType,
      },
      subject: {
        objectId: this.docId,
        model: this.model,
      },
    };
  }
}

MessageMutation.schema = {
  type: 'object',
  required: ['type', 'mutationType', 'docId', 'collection'],
  properties: {
    type: {
      type: 'string',
      enum: [MESSAGE_TYPE__MUTATION],
    },
    mutationType: {
      type: 'string',
      enum: MUTATION_TYPES,
    },
    doc: {
      type: 'object',
    },
    docId: {
      type: 'string',
      minLength: 1,
    },
    previous: {
      type: 'object',
    },
    fieldNames: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
    collection: {
      type: 'string',
      minLength: 1,
    },
    model: {
      type: 'string',
    },
    meta: {
      type: 'object',
    },
    context: {
      type: 'object',
    },
  },
};

Message.types[MESSAGE_TYPE__MUTATION] = MessageMutation;

export default MessageMutation;
