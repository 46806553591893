import { DotsVerticalIcon } from '@heroicons/react/outline';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import React, { useRef, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDDPSubscription } from '@zedoc/ddp-connector';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { slugify } from '@zedoc/text';
import {
  apiZedocPatientServiceSyncProgress,
  apiZedocSearchPatientRecords,
} from '../../../../common/api/zedoc';
import {
  PATIENT_CREATE_PATIENT,
  PATIENT_ATTACH_PARTICIPATION,
  PROJECT_ATTACH_PARTICIPATION,
  PROJECT_PROFILE_CREATE_PARTICIPATION,
  PROJECT_IMPORT_PARTICIPANTS,
  PROJECT_FORCE_SYNCHRONIZATION,
  ADMIN_UPDATE_PROJECT,
  PROJECT_EXPORT_PARTICIPANTS,
} from '../../../../common/permissions';
import Dropdown from '../../../../common/components/Dropdown';
import Menu from '../../../../common/components/Menu';
import Button from '../../../../components/Button';
import Stack from '../../../../common/components/primitives/Stack';
import { default as PatientRecordSelect } from '../../../../common/selectors/PatientRecord';
import ProjectTable from '../ProjectTable';
import ProjectInfo from '../ProjectTable/ProjectInfo';
import branding from '../../../../utils/branding';
import { openEditProjectDialog } from '../../actions';
import Card from '../../../../components/Card';
import { useRealm } from '../../../../utils/usePermission';
import ConnectedFilters from './ConnectedFilters';
import cleanFilters from '../../../../utils/cleanFilters';
import usePagination from '../../../../utils/usePagination';
import store from '../../store';

const ProjectMilestones = compose()(
  ({
    onAddMilestone,
    onDownloadResponsesCSV,
    validate,
    onAddPatient,
    project,
    projectId,
    dashboardId,
    variables,
    onCsvUpload,
    csvInputSchemas,
    onForceSyncToProms,
  }) => {
    const { t } = useTranslation();
    const inputRef = useRef();

    const { ready: progressReady } = useDDPSubscription(
      apiZedocPatientServiceSyncProgress.withParams({
        projectId,
      }),
    );

    const rawFilters = useSelector(
      store.get(`dashboards.${dashboardId}.filters`),
    );

    const filters = useMemo(
      () => cleanFilters(rawFilters, { anyOf: true }),
      [rawFilters],
    );

    const {
      ready: patientsReady,
      items: patients,
      totalItems: nPatients,
      paginationKey,
      getPaginationProps,
    } = usePagination({
      selector: PatientRecordSelect,
      getSubscription: (currentPage, resultsPerPage) =>
        projectId &&
        apiZedocSearchPatientRecords.withParams({
          projectId,
          filters,
          controlId: '$meta.id',
          pageIndex: currentPage - 1,
          resultsPerPage,
        }),
    });

    const [inputSchemaId, setInputSchemaId] = useState(null);
    const handleFileInputOnChange = useCallback(
      (event) => {
        const formData = new FormData();
        formData.append('patients', event.target.files[0]);
        // eslint-disable-next-line no-param-reassign
        event.target.value = '';
        onCsvUpload(projectId, inputSchemaId, formData);
      },
      [onCsvUpload, projectId, inputSchemaId],
    );
    const dispatch = useDispatch();
    const handleEditProject = useCallback(() => {
      dispatch(openEditProjectDialog({}));
    }, [dispatch]);

    const createRealm = useRealm(
      [
        PATIENT_CREATE_PATIENT,
        PATIENT_ATTACH_PARTICIPATION,
        PROJECT_ATTACH_PARTICIPATION,
        PROJECT_PROFILE_CREATE_PARTICIPATION,
      ],
      {
        scope: project ? project.getDomains() : null,
      },
    );

    // const items = [
    //   {
    //     label: 'Alphabetically: A-Z',
    //   },
    //   {
    //     label: 'Alphabetically: Z-A',
    //   },
    //   {
    //     label: 'Date: Earlier- Latest',
    //   },
    //   {
    //     label: 'Date: Latest- Earlier',
    //   },
    // ];
    const projectTableHeaderExtra = (
      <div className="cluster-4">
        {/* <Dropdown
          data-testid="saved-filters-list"
          as={({ onClick }) => {
            return (
              <Button
                type="tertiary"
                className="cluster-2 items-center"
                onClick={onClick}
              >
                <span>Sort by</span>
                <ChevronDownIcon />
              </Button>
            );
          }}
          items={items}
        /> */}
        <Button
          data-testid="add-patient"
          onClick={onAddPatient}
          disabled={isEmpty(createRealm)}
        >
          {t('addRecipients', {
            count: 1,
            context: branding,
          })}
        </Button>
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item
                data-testid="additional-menu-milestone-add"
                key="milestone-add"
                onClick={onAddMilestone}
              >
                {t('addMilestone')}
              </Menu.Item>
              <Menu.Item
                data-testid="additional-menu-download-csv"
                key="download-csv"
                onClick={onDownloadResponsesCSV}
                disabled={
                  !validate(PROJECT_EXPORT_PARTICIPANTS, {
                    relativeTo: project && project.getDomains(),
                  })
                }
              >
                {t('downloadResponsesCSV')}
              </Menu.Item>
              <Menu.Item
                data-testid="additional-menu-download-csv-cat-irt"
                key="download-csv-cat-irt"
                disabled
              >
                {t('downloadResponsesCSV')} {t('projects:catIrt')}
              </Menu.Item>
              {map(csvInputSchemas, ({ _id, name }) => {
                return (
                  <Menu.Item
                    key={_id}
                    disabled={!validate(PROJECT_IMPORT_PARTICIPANTS)}
                    data-testid={`additional-menu-upload-csv-${slugify(name)}`}
                    onClick={() => {
                      setInputSchemaId(_id);
                      if (inputRef.current) {
                        inputRef.current.click();
                      }
                    }}
                  >
                    {t('uploadRecipientCSV', {
                      context: branding,
                    })}{' '}
                    {name}
                  </Menu.Item>
                );
              })}
              <Menu.Item
                data-testid="additional-menu-force-sync-to-proms"
                key="force-sync-to-proms"
                onClick={onForceSyncToProms}
                disabled={
                  !validate(PROJECT_FORCE_SYNCHRONIZATION, {
                    relativeTo: project && project.getDomains(),
                  })
                }
                // NOTE: It can be problematic if the sync process is stuck for some reason.
                // disabled={
                //   !progressReady ||
                //   !project ||
                //   project.patientServiceSyncProgress < 100
                // }
                loading
              >
                {t('syncRecipientService', {
                  context: branding,
                })}{' '}
                {project &&
                  progressReady &&
                  project.patientServiceSyncProgress < 100 &&
                  `(${project.patientServiceSyncProgress}%)`}
              </Menu.Item>
              {project && project.blueprintId && (
                <Menu.Item
                  data-testid="additional-menu-edit-project"
                  key="edit-project"
                  onClick={handleEditProject}
                  disabled={
                    !validate(ADMIN_UPDATE_PROJECT, {
                      relativeTo: project && project.getDomains(),
                    })
                  }
                >
                  {t('editProject')}
                </Menu.Item>
              )}
            </Menu>
          }
          trigger={['click']}
        >
          <Button
            data-testid="additional-menu"
            type="tertiary"
            icon={<DotsVerticalIcon />}
          />
        </Dropdown>
      </div>
    );

    return (
      <Stack>
        {project && <ProjectInfo projectId={projectId} />}
        <ConnectedFilters
          storeKey={`dashboards.${dashboardId}`}
          dashboardId={dashboardId}
          projectId={projectId}
        />
        <Card>
          <ProjectTable
            project={project}
            projectId={projectId}
            patients={patients}
            patientsReady={patientsReady}
            nPatients={nPatients}
            pagination={getPaginationProps()}
            dashboardId={dashboardId}
            paginationKey={paginationKey}
            variables={variables}
            headerExtra={projectTableHeaderExtra}
          />
        </Card>
        <input
          ref={inputRef}
          type="file"
          accept="text/csv"
          onChange={handleFileInputOnChange}
          className="hidden"
        />
      </Stack>
    );
  },
);

ProjectMilestones.propTypes = {
  projectId: PropTypes.string.isRequired,
  dashboardId: PropTypes.string.isRequired,
};

export default ProjectMilestones;
