import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { isoLangs } from '@zedoc/iso-langs';
import { apiZedocListTranslationOptionsForAnswersSheet } from '../../common/api/zedoc';
import Select from './Select';
import useList from '../../utils/useList';

const TranslationsSelect = ({
  answersSheetId,
  answersSheetLanguages,
  value,
  onChange,
  compact,
}) => {
  const { i18n } = useTranslation();
  const uiLanguage = i18n.language;

  const { items } = useList(
    answersSheetId &&
      apiZedocListTranslationOptionsForAnswersSheet.withParams({
        answersSheetId,
        resultId: '$meta.id',
      }),
  );

  const originalQuestionnaireLanguage = useMemo(() => {
    return items.find(({ version }) => version === '0');
  }, [items]);

  const translations = useMemo(() => {
    return items.filter(
      ({ language }) =>
        uiLanguage === language || answersSheetLanguages.includes(language),
    );
  }, [items, uiLanguage, answersSheetLanguages]);

  const translationThatMatchesUILanguage = useMemo(
    () => translations.find(({ language }) => language === uiLanguage),
    [translations, uiLanguage],
  );

  const languageOptions = useMemo(() => {
    const generateTranslations = ({ language, translationId }) => {
      return {
        value: translationId,
        label: isoLangs[language]?.name,
      };
    };

    if (!translationThatMatchesUILanguage && originalQuestionnaireLanguage) {
      // NOTE: If no translation that matches UI language is present than use questionnaire language instead
      return [originalQuestionnaireLanguage, ...translations].map(
        generateTranslations,
      );
    }

    return translations.map(generateTranslations);
  }, [
    translations,
    translationThatMatchesUILanguage,
    originalQuestionnaireLanguage,
  ]);

  const handleCurrentLanguageChange = (language) => onChange(language);
  useEffect(() => {
    if (translationThatMatchesUILanguage) {
      const { translationId } = translationThatMatchesUILanguage;
      onChange(translationId);
    } else if (originalQuestionnaireLanguage) {
      onChange(originalQuestionnaireLanguage.translationId);
    }
  }, [
    onChange,
    translationThatMatchesUILanguage,
    originalQuestionnaireLanguage,
  ]);

  return languageOptions.length > 1 ? (
    <Select
      options={languageOptions}
      onChange={handleCurrentLanguageChange}
      value={value}
      compact={compact}
    />
  ) : null;
};

TranslationsSelect.propTypes = {
  answersSheetId: PropTypes.string,
  answersSheetLanguages: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.string,
  onChange: PropTypes.func,
  compact: PropTypes.bool,
};

TranslationsSelect.defaultProps = {
  answersSheetId: null,
  answersSheetLanguages: [],
  value: null,
  onChange: () => {},
  compact: false,
};

export default TranslationsSelect;
