export const ANSWERS_SHEET_SESSION_KEEP_ALIVE_SECONDS = 15;

export const SIGNED_NOTE_TYPE__SAVED = 'Saved';
export const SIGNED_NOTE_TYPE__AMENDED = 'Amended';
export const SIGNED_NOTE_TYPE__REASSIGNED = 'Reassigned';
export const SIGNED_NOTE_TYPE__RESOLVED = 'Resolved';

export const SIGNED_NOTE_TYPES = [
  SIGNED_NOTE_TYPE__SAVED,
  SIGNED_NOTE_TYPE__AMENDED,
  SIGNED_NOTE_TYPE__REASSIGNED,
  SIGNED_NOTE_TYPE__RESOLVED,
];

export const ANSWERS_SHEET_NAVIGATION_TYPE__CURSOR = 'Cursor'; // the default one

export const ANSWERS_SHEET_NAVIGATION_TYPES = [
  ANSWERS_SHEET_NAVIGATION_TYPE__CURSOR,
];
