import map from 'lodash/map';
import forEach from 'lodash/forEach';
import groupBy from 'lodash/groupBy';
import React, { useMemo } from 'react';
import { compose } from 'recompose';
import Pagination from '../../../../common/components/Pagination';
import ActivitiesList from './ActivitiesList';
import PatientRecord from '../../../../common/models/PatientRecord';
import ActivitiesItem from './ActivitiesItem';

const ActivitiesDirectory = compose()(
  ({
    patients,
    pagination,
    projectId,
    dashboardId,
    paginationKey,
    onPatientClick,
    getIsDisabled,
    milestones,
    variables,
    onMilestoneClick,
    loading,
  }) => {
    // eslint-disable-next-line react/jsx-props-no-spreading
    const renderPagination = () => <Pagination {...pagination} />;

    const dataSource = useMemo(() => {
      const byRecipientId = groupBy(patients, 'recipientId');
      const newDataSource = [];
      forEach(patients, (patientRecord) => {
        const { recipientId } = patientRecord;
        if (byRecipientId[recipientId]) {
          newDataSource.push(
            new PatientRecord({
              ...patientRecord.raw,
              allPatientRecords: byRecipientId[recipientId],
            }),
          );
          delete byRecipientId[recipientId];
        }
      });
      return newDataSource;
    }, [patients]);

    return (
      <div className="stack-6">
        <ActivitiesList
          data-testid="project-patient-table"
          dataSource={dataSource}
          loading={loading}
          // pageSize={pageSize}
          renderItem={(
            { _id: patientRecordId, allPatientRecords },
            parentIndex,
          ) => {
            return (
              <li key={patientRecordId}>
                {parentIndex !== 0 && <hr />}
                {map(allPatientRecords, (patientRecord, i) => (
                  <ActivitiesItem
                    key={i}
                    dashboardId={dashboardId}
                    paginationKey={paginationKey}
                    projectId={projectId}
                    patientRecord={patientRecord}
                    patientRecordId={patientRecordId}
                    variables={variables}
                    milestones={milestones}
                    onMilestoneClick={onMilestoneClick}
                    getIsDisabled={getIsDisabled}
                    onPatientClick={onPatientClick}
                  />
                ))}
              </li>
            );
          }}
        />
        {renderPagination()}
      </div>
    );
  },
);

export default ActivitiesDirectory;
