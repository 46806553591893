import React from 'react';
import { compose } from 'recompose';
import { Field, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import Text from '../../../../common/components/base/Text';
import FormFieldText from '../../../../components/forms/FormFieldText';
import FormFieldSearch from '../../../../components/forms/FormFieldSearch';
import { makeJsonSchemaFormValidator } from '../../../../utils/errors';
// import FormFieldDate from '../../../../components/forms/FormFieldDate';

const Form = compose(
  reduxForm({
    validate: makeJsonSchemaFormValidator({
      type: 'object',
      required: ['name', 'belongsTo'],
      properties: {
        name: {
          type: 'string',
          title: 'Name',
          minLength: 1,
        },
        description: {
          type: 'string',
          title: 'Description',
        },
        belongsTo: {
          type: 'string',
          title: 'Belongs to',
          minLength: 1,
        },
        billingCode: {
          type: 'string',
          title: 'Billing Code',
        },
        timezone: {
          type: 'string',
          title: 'Timezone',
        },
      },
    }),
  }),
)(({ error, handleSubmit, onSubmit, belongsToOptions }) => {
  const { t } = useTranslation('forms');

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="stack-6 w-full sm:w-1/2">
      {error && <Text.Paragraph type="danger">{error}</Text.Paragraph>}
      <Field
        data-testid="form-field-belongs-to"
        component={FormFieldSearch}
        name="belongsTo"
        type="text"
        label={t('belongsTo.label')}
        options={belongsToOptions}
        required
      />
      <Field
        data-testid="form-field-name"
        component={FormFieldText}
        name="name"
        type="text"
        label={t('name.label')}
        required
      />
      <Field
        data-testid="form-field-timezone"
        component={FormFieldSearch}
        valueSetId="zedoc/timezones@1.0.0"
        name="timezone"
        label={t('timezone.label')}
      />
      <Field
        data-testid="form-field-billing-code"
        component={FormFieldText}
        name="billingCode"
        type="text"
        label={t('projectReferenceCode.label')}
      />
      <Field
        data-testid="form-field-description"
        component={FormFieldText}
        name="description"
        type="text"
        label={t('description.label')}
      />
    </form>
  );
});

export default Form;
