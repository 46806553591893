/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React from 'react';
import { Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDDPSubscription } from '@zedoc/ddp-connector';
import AlreadySignedIn from '../../components/plates/AlreadySignedIn';
import Skeleton from '../../components/Skeleton';
import EntryLayoutTransition from '../../components/layouts/EntryLayout/EntryLayoutTransition';
import { apiCurrentUserDetails } from '../../common/api/currentUser';
import CurrentUser from '../../models/CurrentUser';

const EntryRoute = ({ component: Component, ...rest }) => {
  const { ready: isSubscriptionReady, error: subscriptionError } =
    useDDPSubscription(apiCurrentUserDetails.withParams());
  const currentUser = useSelector(CurrentUser.select.user());
  const isLoggingIn = useSelector(CurrentUser.select.isLoggingIn());

  return isSubscriptionReady || subscriptionError ? (
    <Route
      {...rest}
      render={(props) => {
        if (currentUser) {
          return <AlreadySignedIn currentUser={currentUser} />;
        }
        return <Component isLoggingIn={isLoggingIn} {...props} />;
      }}
    />
  ) : (
    <EntryLayoutTransition>
      <div className="stack-10">
        <Skeleton className="h-56" />
        <Skeleton className="h-12 rounded-full" />
      </div>
    </EntryLayoutTransition>
  );
};

EntryRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
};

export default EntryRoute;
