// @ts-check
import { QUESTION_TYPE__CAT_SECTION } from '../../constants';

/**
 * @param {number | undefined} a
 * @param {number | undefined} b
 * @returns {number}
 */
export const compareOptionalNumbers = (a, b) => {
  if (a === undefined && b === undefined) {
    return 0;
  }
  if (b === undefined) {
    return -1;
  }
  if (a === undefined) {
    return 1;
  }
  return a - b;
};

/**
 * @template T
 * @typedef {(a: T, b: T) => number} CompareFunction
 */

/**
 * @typedef {object} Question
 * @property {import('../../constants').QuestionType} type
 * @property {number | undefined} sequenceNo
 * @property {number | undefined} information
 */

/**
 * @param {Question | undefined} question
 * @returns {CompareFunction<Question> | undefined}
 */
export const defaultGetCompare = (question) => {
  if (!question || question.type !== QUESTION_TYPE__CAT_SECTION) {
    return undefined;
  }
  return (q1, q2) => {
    const order = compareOptionalNumbers(q1.sequenceNo, q2.sequenceNo);
    if (order !== 0) {
      return order;
    }
    return -compareOptionalNumbers(q1.information, q2.information);
  };
};
