import each from 'lodash/each';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDDPQuery } from '@zedoc/ddp-connector';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { apiZedocSearchRecipients } from '../../../../common/api/zedoc';
import { PATIENT_ACCESS_PATIENT_PII_VARIABLES } from '../../../../common/permissions';
import Recipient from '../../../../common/models/Recipient';
import { getProjectNames } from '../../../../common/api/aggregations/Projects';
import { default as RecipientSelect } from '../../../../common/selectors/Recipient';
import { default as ParticipationSelect } from '../../../../common/selectors/Participation';
import PageBar from '../../../../components/Layout/PageBar';
import Table from '../../../../components/lists/Table';
import branding from '../../../../utils/branding';
import useDocumentTitle from '../../../../utils/useDocumentTitle';
import usePermission from '../../../../utils/usePermission';
import ConnectedFilters from './ConnectedFilters';
import MatchedProperties from '../../../../components/MatchedProperties';
import usePagination from '../../../../utils/usePagination';
import { getFilters } from '../../selectors';
import cleanFilters from '../../../../utils/cleanFilters';

/**
 * @param {Recipient} recipient
 */
const useRecipientName = (recipient) => {
  const canSeePII = usePermission([PATIENT_ACCESS_PATIENT_PII_VARIABLES], {
    relativeTo: recipient && recipient.getDomains(),
  });

  const recipientName = useMemo(() => {
    if (!recipient) {
      return '';
    }

    if (!canSeePII) {
      return recipient.getTruncatedId();
    }

    return (
      recipient.getFullName() ||
      recipient.getIdentifier() ||
      recipient.getPrimaryIdentifier() ||
      recipient.getTruncatedId()
    );
  }, [canSeePII, recipient]);

  return recipientName;
};

const PatientLink = ({ recipient }) => {
  const name = useRecipientName(recipient);
  return (
    <Link
      data-testid={`button-patient-${name}`}
      className="font-medium text-primary-800"
      to={`/patients/${recipient?._id}`}
    >
      {name}
    </Link>
  );
};

PatientLink.propTypes = {
  recipient: PropTypes.instanceOf(Recipient).isRequired,
};

const PatientsTable = () => {
  const { t } = useTranslation();

  const rawFilters = useSelector(getFilters);
  const filters = useMemo(
    () => cleanFilters(rawFilters, { anyOf: true }),
    [rawFilters],
  );
  const {
    ready: subscriptionsReady,
    items: patients,
    getPaginationProps,
    paginationKey,
  } = usePagination({
    debounceMs: 500,
    selector: RecipientSelect,
    getSubscription: (currentPage, resultsPerPage) =>
      apiZedocSearchRecipients.withParams({
        filters,
        controlId: '$meta.id',
        pageIndex: currentPage - 1,
        resultsPerPage,
      }),
    mapSelector: (selector) => {
      return selector.lookup({
        from: ParticipationSelect.all(),
        as: 'participations',
        foreignKey: 'recipientId',
        key: (doc, _, byRecipientId) => {
          return byRecipientId[doc._id];
        },
      });
    },
  });

  const { value: projectNames } = useDDPQuery(getProjectNames.withParams({}));
  const columns = useMemo(
    () => [
      {
        title: t('recipients', {
          count: 1,
          context: branding,
        }),
        dataIndex: 'patient',
        key: 'recipientName',
        render: (text, recipient) => (
          <div className="stack-1">
            <PatientLink recipient={recipient} />
            <MatchedProperties
              rawFilters={rawFilters}
              allMatchedProperties={
                paginationKey &&
                recipient[paginationKey] &&
                recipient[paginationKey].filters
              }
            />
          </div>
        ),
        width: 250,
      },
      {
        title: t('projects'),
        dataIndex: 'projects',
        key: 'projects',
        render: (text, recipient) => {
          const projects = [];
          each(
            recipient.participations,
            ({ projectId }) =>
              projectNames &&
              projectNames[projectId] &&
              projects.push(projectNames[projectId]),
          );

          return projects.join(', ') || 'None';
        },
      },
    ],
    [t, projectNames, paginationKey, rawFilters],
  );

  const title = t('allRecipients', {
    context: branding,
  });
  useDocumentTitle([title]);

  return (
    <div className="stack-6">
      <PageBar title={title} />
      <div className="bg-surface py-4 shadow rounded-md stack-4">
        <div className="px-4">
          <ConnectedFilters />
        </div>
        <Table
          data-testid="patient-table"
          rowKey="_id"
          dataSource={patients}
          columns={columns}
          loading={!subscriptionsReady}
          pagination={getPaginationProps()}
        />
      </div>
    </div>
  );
};

export default PatientsTable;
