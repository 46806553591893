import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  ACTIVITY_STATE__COMPLETED,
  ACTIVITY_STATE__ACTIVE,
  ACTIVITY_STATE__ABORTED,
  ACTIVITY_STATE__CANCELED,
  ACTIVITY_STATE__EXPIRED,
  ACTIVITY_STATE__SCHEDULED,
  ACTIVITY_STATE__PLANNED,
  ACTIVITY_STATE__OMITTED,
  ACTIVITY_STATE__SUSPENDED,
} from '../../common/constants';
import {
  getMilestoneBackgroundColor,
  getMilestoneStatus,
  getMilestoneBorderColor,
} from '../Milestone/helpers';
import useElementTruncated from '../../utils/useElementTruncated';
import Tooltip from '../../common/components/Tooltip';

const CalendarMilestone = ({
  name,
  state,
  isEmpty,
  isNonActionable,
  isDisabled,
  onClick,
  showContinuationToPrevDate,
  showContinuationToNextDate,
  className,
  grid,
}) => {
  const { t } = useTranslation('components');
  const { ref, isElementTruncated } = useElementTruncated();

  const containerClassName = useMemo(() => {
    let str = '';

    if (showContinuationToNextDate) {
      str += 'pie-3';
    }

    if (showContinuationToPrevDate) {
      str += ' pis-3';
    }

    return str;
  }, [showContinuationToNextDate, showContinuationToPrevDate]);
  const milestoneClassName = useMemo(() => {
    if (showContinuationToNextDate && !showContinuationToPrevDate) {
      return 'rounded-ss-sm rounded-es-sm';
    }

    if (showContinuationToPrevDate && !showContinuationToNextDate) {
      return 'rounded-se-sm rounded-ee-sm';
    }

    return 'rounded-sm';
  }, [showContinuationToNextDate, showContinuationToPrevDate]);

  const label = useMemo(() => {
    let str = name;

    if (state) {
      str += ` | ${getMilestoneStatus(t, state, isEmpty)}`;
    }

    return str;
  }, [name, t, state, isEmpty]);

  return (
    <div
      data-testid={`calendar-activity-${name}`}
      className={`relative group self-end m-2 ${containerClassName} ${className}`}
      style={{
        gridColumnStart: grid.columnStart,
        gridColumnEnd: grid.columnEnd + 1,
        gridRow: grid.rowStart + 1,
      }}
    >
      <Tooltip
        title={isElementTruncated ? label : ''}
        className="whitespace-nowrap truncate"
      >
        <button
          ref={ref}
          type="button"
          className={`z-calendar__milestone ${milestoneClassName} px-2 py-1 text-xs ${
            isDisabled ? 'cursor-not-allowed' : ''
          } text-left truncate ${
            state
              ? `w-full ${getMilestoneBackgroundColor(
                  state,
                  isEmpty,
                  isNonActionable,
                  isDisabled,
                )} ${getMilestoneBorderColor(state, isEmpty, isDisabled)} ${
                  isNonActionable ? 'border-2 leading-3' : 'border-transparent'
                } `
              : 'bg-neutral-500 hover:bg-neutral-400'
          }`}
          onClick={isDisabled ? () => {} : onClick}
        >
          {label}
        </button>
        {showContinuationToPrevDate && (
          <div
            className={`h-0 w-0 absolute top-0 -inline-start-3 border-12 ${getMilestoneBorderColor(
              state,
              isEmpty,
              isDisabled,
            )}`}
            style={{
              top: 1,
              borderBlockStartColor: 'transparent',
              borderInlineStartColor: 'transparent',
              borderBlockEndColor: 'transparent',
            }}
          />
        )}
        {showContinuationToNextDate && (
          <div
            className={`h-0 w-0 absolute top-0 -inline-end-3 border-12 ${getMilestoneBorderColor(
              state,
              isEmpty,
              isDisabled,
            )}`}
            style={{
              top: 1,
              borderBlockStartColor: 'transparent',
              borderInlineEndColor: 'transparent',
              borderBlockEndColor: 'transparent',
            }}
          />
        )}
      </Tooltip>
    </div>
  );
};

CalendarMilestone.propTypes = {
  name: PropTypes.string.isRequired,
  state: PropTypes.oneOf([
    ACTIVITY_STATE__COMPLETED,
    ACTIVITY_STATE__ACTIVE,
    ACTIVITY_STATE__ABORTED,
    ACTIVITY_STATE__CANCELED,
    ACTIVITY_STATE__EXPIRED,
    ACTIVITY_STATE__SCHEDULED,
    ACTIVITY_STATE__PLANNED,
    ACTIVITY_STATE__OMITTED,
    ACTIVITY_STATE__SUSPENDED,
  ]).isRequired,
  isEmpty: PropTypes.bool,
  isNonActionable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func,
  showContinuationToPrevDate: PropTypes.bool,
  showContinuationToNextDate: PropTypes.bool,
  className: PropTypes.string,
  grid: PropTypes.shape({
    rowStart: PropTypes.number,
    columnStart: PropTypes.number,
    columnEnd: PropTypes.number,
  }),
};

CalendarMilestone.defaultProps = {
  isEmpty: false,
  isNonActionable: false,
  isDisabled: false,
  onClick: () => {},
  showContinuationToPrevDate: false,
  showContinuationToNextDate: false,
  className: '',
  grid: {},
};

export default CalendarMilestone;
