export const PROJECT_DASHBOARD_TYPE__UNSUPPORTED = 'unsupported'; // this is used as a placeholder value for archived legacy dashboards
export const PROJECT_DASHBOARD_TYPE__ANALYTICS = 'analytics';
export const PROJECT_DASHBOARD_TYPE__ANALYTICS_WITHOUT_POPULATION =
  'analyticsWithoutPopulation';
export const PROJECT_DASHBOARD_TYPE__TIMELINE = 'timeline';
export const PROJECT_DASHBOARD_TYPE__DOCUMENTS = 'documents'; // all completed answer sheets generated for the patient
export const PROJECT_DASHBOARD_TYPE__DETAILS = 'details'; // show detailed information about the entity
export const PROJECT_DASHBOARD_TYPE__LATEST_RESPONSES = 'latestResponses'; // show latest responses for given patient and questionnaire
export const PROJECT_DASHBOARD_TYPE__ANALYTICS_TABLE = 'analyticsTable';
export const PROJECT_DASHBOARD_TYPE__EAPP = 'EAPP';
export const PROJECT_DASHBOARD_TYPE__EAPP_DASHBOARD_V2 = 'EAPPv2';
export const PROJECT_DASHBOARD_TYPE__PROJECT_LANDING_PAGE =
  'projectLandingPage';
export const PROJECT_DASHBOARD_TYPE__PRESET = 'preset';

export const PROJECT_DASHBOARD_TYPES = [
  PROJECT_DASHBOARD_TYPE__UNSUPPORTED,
  PROJECT_DASHBOARD_TYPE__ANALYTICS,
  PROJECT_DASHBOARD_TYPE__ANALYTICS_WITHOUT_POPULATION,
  PROJECT_DASHBOARD_TYPE__TIMELINE,
  PROJECT_DASHBOARD_TYPE__DOCUMENTS,
  PROJECT_DASHBOARD_TYPE__DETAILS,
  PROJECT_DASHBOARD_TYPE__LATEST_RESPONSES,
  PROJECT_DASHBOARD_TYPE__ANALYTICS_TABLE,
  PROJECT_DASHBOARD_TYPE__EAPP,
  PROJECT_DASHBOARD_TYPE__EAPP_DASHBOARD_V2,
  PROJECT_DASHBOARD_TYPE__PROJECT_LANDING_PAGE,
  PROJECT_DASHBOARD_TYPE__PRESET,
];

export const DASHBOARD_PERSPECTIVE__PATIENTS = 'patients';
export const DASHBOARD_PERSPECTIVE__PARTICIPATIONS = 'participations';
export const DASHBOARD_PERSPECTIVE__ACTIVITIES = 'activities';
export const DASHBOARD_PERSPECTIVE__RESPONSES = 'responses'; // only completed answers sheets
export const DASHBOARD_PERSPECTIVE__ASSIGNMENTS = 'assignments'; // all answers sheets which are "due"
export const DASHBOARD_PERSPECTIVE__ANSWERS = 'answers';
export const DASHBOARD_PERSPECTIVE__EAPPS = 'eapps';
export const DASHBOARD_PERSPECTIVES = [
  DASHBOARD_PERSPECTIVE__PATIENTS,
  DASHBOARD_PERSPECTIVE__PARTICIPATIONS,
  DASHBOARD_PERSPECTIVE__ACTIVITIES,
  DASHBOARD_PERSPECTIVE__RESPONSES,
  DASHBOARD_PERSPECTIVE__ASSIGNMENTS,
  DASHBOARD_PERSPECTIVE__ANSWERS,
  DASHBOARD_PERSPECTIVE__EAPPS,
];

export const DETAILED_VIEW_TRANSFORMATION__DEFAULT = 'default';
export const DETAILED_VIEW_TRANSFORMATION__NOTHING = 'nothing';
export const DETAILED_VIEW_TRANSFORMATION__COLORS = 'colors';
export const DETAILED_VIEW_TRANSFORMATION__OPACITY = 'opacity';
export const DETAILED_VIEW_TRANSFORMATION__PATTERN = 'pattern';
export const DETAILED_VIEW_TRANSFORMATIONS = [
  DETAILED_VIEW_TRANSFORMATION__DEFAULT,
  DETAILED_VIEW_TRANSFORMATION__NOTHING,
  DETAILED_VIEW_TRANSFORMATION__COLORS,
  DETAILED_VIEW_TRANSFORMATION__OPACITY,
  DETAILED_VIEW_TRANSFORMATION__PATTERN,
];
export const TRANSFORMATION_OPACITY__POPULATION = 0.1;
export const TRANSFORMATION_OPACITY__RECIPIENT = 1;
export const TRANSFORMATION_COLORS__POPULATION = 'lightgrey';
export const TRANSFORMATION_COLORS__RECIPIENT = 'skyblue';

export const PATIENT_AUTH_FALLBACK_TYPE__DO_NOT_CONTACT = 'doNotContact';
export const PATIENT_AUTH_FALLBACK_TYPE__CONTACT_AND_SKIP_AUTH =
  'contactAndSkipAuth';
export const PATIENT_AUTH_FALLBACK_TYPE__CONTACT_AND_FAIL_TO_AUTH =
  'contactAndFailToAuth';
export const PATIENT_AUTH_FALLBACK_TYPES = [
  PATIENT_AUTH_FALLBACK_TYPE__DO_NOT_CONTACT,
  PATIENT_AUTH_FALLBACK_TYPE__CONTACT_AND_SKIP_AUTH,
  PATIENT_AUTH_FALLBACK_TYPE__CONTACT_AND_FAIL_TO_AUTH,
];
