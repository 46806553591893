/* eslint-disable react/prop-types */
import {
  DeleteOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
  EditOutlined,
  // EyeOutlined,
} from '@ant-design/icons';
import map from 'lodash/map';
import find from 'lodash/find';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { compose } from 'recompose';
import { ddp } from '@zedoc/ddp-connector';
import { useTranslation } from 'react-i18next';
import { Question, Element, Field } from '@zedoc/react-questionnaire';
import {
  Questionnaire as QuestionnaireModel,
  Question as QuestionModel,
} from '@zedoc/questionnaire';
import { apiTerminologyExpandValueSet } from '../../../common/api/terminology';
import Text from '../../../common/components/base/Text';
import Button from '../../../common/components/Button';
import Sidebar from '../../../common/components/primitives/Sidebar';
import Cluster from '../../../common/components/primitives/Cluster';
import Stack from '../../../common/components/primitives/Stack';
import { default as CommonComponentsCollection } from '../../../common/components/Collection';
import { CollapseItem } from '../../../common/components/Collapse';
// import FormFieldError from './FormFieldError';

const Title = ({ input: { value }, title, expandQueryResult }) => {
  if (!value) {
    return <div data-testid={`label-${title}`}>{title}</div>;
  }

  if (!expandQueryResult) {
    return value;
  }

  const { contains } = expandQueryResult.expansion;

  const res = find(contains, (item) => item.code === value);

  const titleText = res ? res.display : value;

  return <div data-testid={`label-${titleText}`}>{titleText}</div>;
};

const CompoundTitle = ({ nameId, otherNameId, title, expandQueryResult }) => (
  <Question id={nameId}>
    {() => (
      <Field>
        {({ input: { value } }) => (
          <Question id={otherNameId}>
            {() => (
              <Field>
                {({ input }) => {
                  const value2 = input.value;
                  if (value && value2) {
                    return (
                      <>
                        <Title
                          input={{ value }}
                          title={title}
                          expandQueryResult={expandQueryResult}
                        />
                        &nbsp;(
                        <Title
                          input={{ value: value2 }}
                          title={title}
                          expandQueryResult={expandQueryResult}
                        />
                        )
                      </>
                    );
                  }
                  if (value && !value2) {
                    return (
                      <Title
                        input={{ value }}
                        title={title}
                        expandQueryResult={expandQueryResult}
                      />
                    );
                  }
                  if (!value && value2) {
                    return (
                      <Title
                        input={{ value: value2 }}
                        title={title}
                        expandQueryResult={expandQueryResult}
                      />
                    );
                  }

                  return (
                    <Title
                      data-testid={`collapse-${title}`}
                      input={{ value: '' }}
                      expandQueryResult={expandQueryResult}
                      title={title}
                    />
                  );
                }}
              </Field>
            )}
          </Question>
        )}
      </Field>
    )}
  </Question>
);

const CollectionTitle = compose(
  ddp({
    queries: (state, { valueSetId }) => ({
      expandQueryResult: valueSetId
        ? apiTerminologyExpandValueSet.withParams({
            id: valueSetId,
            // FIXME: Performance
            limit: 0,
          })
        : null,
    }),
    renderLoader: null,
  }),
)(({ expandQueryResult, title, nameId, otherNameId }) => {
  if (otherNameId) {
    return (
      <CompoundTitle
        nameId={nameId}
        otherNameId={otherNameId}
        title={title}
        expandQueryResult={expandQueryResult}
      />
    );
  }
  return (
    <Question id={nameId}>
      {({ valueSet }) => {
        return (
          <Field
            data-testid={`collapse-${title}`}
            component={Title}
            valueSet={valueSet}
            expandQueryResult={expandQueryResult}
            title={title}
          />
        );
      }}
    </Question>
  );
});

const Content = ({
  title,
  questionnaire,
  question,
  questionsIds,
  Section,
  handleRemove,
  isPristine,
  valueSetId,
  nameId,
}) => {
  const { t } = useTranslation();

  const [isEditing, setIsEditing] = useState(isPristine);

  const handleEdit = () => setIsEditing(!isEditing);

  return (
    <Stack>
      <Cluster space={1}>
        {/* <Button
          data-testid={`button-preview-${title}`}
          type="primary"
          icon={<EyeOutlined />}
        >
          {t('preview')}
        </Button> */}
        <Button
          data-testid={`button-edit-${title}`}
          type="primary"
          icon={<EditOutlined />}
          onClick={handleEdit}
          disabled={isEditing}
        >
          {t('edit')}
        </Button>
        <Button
          data-testid={`button-remove-${title}`}
          type="primary"
          icon={<DeleteOutlined />}
          onClick={handleRemove}
        >
          {t('remove')}
        </Button>
      </Cluster>
      <Section
        questionnaire={questionnaire}
        sectionId={question.id}
        questionsIds={questionsIds}
        valueSetId={valueSetId}
        nameId={nameId}
        isEditing={isEditing}
        isNested
      />
      {/* <Field
        component={FormFieldError}
        name="value"
      /> */}
    </Stack>
  );
};

const Collection = ({
  elements,
  question,
  questionnaire,
  onAppend,
  Section,
  // eslint-disable-next-line react/prop-types
  questionsIds,
  // eslint-disable-next-line react/prop-types
  // eslint-disable-next-line react/prop-types
  valueSetId,
  // eslint-disable-next-line react/prop-types
  nameId,
  // eslint-disable-next-line react/prop-types
  otherNameId,
  hasOrder,
  isTertiary,
}) => {
  const { t } = useTranslation();
  const title = question.getTitle();
  const items = map(elements, (elementId, i) => {
    // const elementFormValues = formValues._elements[elementId];

    return {
      key: elementId,
      children: (
        <Element key={elementId} id={elementId}>
          {({ handleRemove, handleMoveUp, handleMoveDown }) => (
            <Sidebar
              space={1}
              side="right"
              align="flex-start"
              sidebar={
                hasOrder ? (
                  <Stack space={1}>
                    <Button
                      icon={<ArrowUpOutlined />}
                      disabled={i === 0}
                      onClick={handleMoveUp}
                      ghost
                      block
                    />
                    <Button
                      icon={<ArrowDownOutlined />}
                      disabled={i === elements.length - 1}
                      onClick={handleMoveDown}
                      ghost
                      block
                    />
                  </Stack>
                ) : null
              }
            >
              <div
                className={`p-6 rounded-lg ${
                  isTertiary ? 'bg-pw2-tertiary' : 'bg-pw2-secondary'
                } dark:bg-disabled`}
              >
                <CollapseItem
                  title={
                    <CollectionTitle
                      data-testid={`collapse-${title}`}
                      title={`${pluralize(title, 1)} ${i + 1}`}
                      valueSetId={valueSetId}
                      nameId={nameId}
                      otherNameId={otherNameId}
                      elementId={elementId}
                    />
                  }
                  titleToken="title3"
                  content={
                    <Content
                      title={title}
                      questionnaire={questionnaire}
                      question={question}
                      questionsIds={questionsIds}
                      valueSetId={valueSetId}
                      nameId={nameId}
                      Section={Section}
                      handleRemove={handleRemove}
                    />
                  }
                />
              </div>
            </Sidebar>
          )}
        </Element>
      ),
    };
  });

  return (
    <CommonComponentsCollection
      data-testid={question.getSlug()}
      title={
        <Text.Heading data-testid={`collection-header-${title}`} level={2}>
          {title}
        </Text.Heading>
      }
      items={items}
      addTitle={t('add')}
      onAdd={onAppend}
    />
  );
};

Collection.propTypes = {
  elements: PropTypes.arrayOf(PropTypes.string),
  question: PropTypes.instanceOf(QuestionModel).isRequired,
  questionnaire: PropTypes.instanceOf(QuestionnaireModel).isRequired,
  onAppend: PropTypes.func.isRequired,
  Section: PropTypes.elementType.isRequired,
  hasOrder: PropTypes.bool,
  isTertiary: PropTypes.bool,
};

Collection.defaultProps = {
  elements: [],
  hasOrder: false,
  isTertiary: false,
};

export default Collection;
