import isEqual from 'lodash/isEqual';
import filter from 'lodash/filter';
import map from 'lodash/map';
import { getVersionsDifference, isMatchingVersion } from '@zedoc/questionnaire';
import { EJSON } from '@zedoc/ejson';
import {
  apiBlueprintsGetBlueprint,
  apiBlueprintsCreateBlueprint,
  apiBlueprintsUpdateBlueprint,
  apiBlueprintsGetDrafts,
} from '../../api/blueprints';
import BlueprintDraft from './BlueprintDraft';
import BlueprintRelease from './BlueprintRelease';
import { BLUEPRINT_RELEASE_SOURCE_TYPE__PROJECT_JSON } from './constants';
import createDefaultConfiguration from './createDefaultConfiguration';

class Blueprint {
  constructor(props, client) {
    this.client = client;
    this.replace(props);
  }

  replace(props = {}) {
    this.props = {
      ...props,
    };
    return this;
  }

  update(newProps) {
    this.props = {
      ...this.props,
      ...newProps,
    };
    return this;
  }

  async call(apiSpec, params) {
    if (!this.client) {
      throw new Error(
        `Cannot call ${apiSpec.getName()} because client is not specified.`,
      );
    }
    return this.client.call(apiSpec, params);
  }

  async save() {
    const { blueprintId } = this.props;
    if (blueprintId) {
      const props = await this.call(apiBlueprintsUpdateBlueprint, {
        blueprintId,
        ...this.props,
      });
      return this.replace(props);
    }
    const props = await this.call(apiBlueprintsCreateBlueprint, this.props);
    return this.replace(props);
  }

  async createDraft({ publish = false, useLatestParentVersion = false } = {}) {
    const { name, description, parentId, parentVersionRange, blueprintId } =
      this.props;
    if (!blueprintId) {
      throw new Error('Cannot create draft before blueprint is saved.');
    }
    let baseRelease = await BlueprintRelease.getMaxVersionSatisfying(
      blueprintId,
      'x',
      this.client,
    );
    let parentRelease;
    if (parentId) {
      parentRelease = await BlueprintRelease.getMaxVersionSatisfying(
        parentId,
        parentVersionRange,
        this.client,
      );
      if (!parentRelease) {
        throw new Error('No parent release found.');
      }
    }
    if (!baseRelease) {
      const props = {
        blueprintId,
        version: '0.0.0',
      };
      if (parentRelease) {
        props.parentId = parentId;
        props.parentVersion = parentRelease.props.version;
      } else {
        props.sourceType = BLUEPRINT_RELEASE_SOURCE_TYPE__PROJECT_JSON;
        props.source = EJSON.stringify(
          createDefaultConfiguration({
            name,
            description,
          }),
        );
      }
      baseRelease = new BlueprintRelease(props, this.client);
    }
    const { source, sourceType, sourceModifiedFeatures, parentVersion } =
      baseRelease.props;
    const releaseType =
      parentRelease && useLatestParentVersion
        ? getVersionsDifference(parentVersion, parentRelease.getVersion()) ||
          'patch'
        : 'patch';
    const newDraft = new BlueprintDraft(
      {
        version: baseRelease.getNextVersion(releaseType),
        blueprintId,
        source,
        sourceType,
        sourceModifiedFeatures,
        parentId,
        parentVersion:
          parentRelease && useLatestParentVersion
            ? parentRelease.getVersion()
            : parentVersion,
      },
      this.client,
    );
    if (sourceType === BLUEPRINT_RELEASE_SOURCE_TYPE__PROJECT_JSON) {
      // NOTE: The following step involves draft compilation, which ensures
      //       that the parent release details will be fetched (if present).
      //       So the resulting source will be a combination of latest
      //       parent release and previous release of this blueprint.
      const configuration = await newDraft.getProjectConfiguration();
      if (configuration) {
        newDraft.update({
          source: EJSON.stringify(configuration),
        });
      }
    }
    if (publish) {
      return newDraft.publish();
    }
    return newDraft.save();
  }

  async patchAllDrafts(newProperties, { versionRange = 'x' } = {}) {
    const { blueprintId } = this.props;
    let drafts = map(
      await this.call(apiBlueprintsGetDrafts, {
        blueprintId,
      }),
      (rawDraft) => {
        return new BlueprintDraft(rawDraft, this.client);
      },
    );

    drafts = filter(drafts, (draft) => {
      const { source, sourceType, version } = draft.props;
      if (versionRange && !isMatchingVersion(versionRange, version)) {
        return false;
      }
      if (sourceType !== BLUEPRINT_RELEASE_SOURCE_TYPE__PROJECT_JSON) {
        return false;
      }
      let configuration;
      try {
        configuration = EJSON.parse(source);
      } catch (err) {
        // ...
      }
      if (configuration) {
        const newConfiguration = {
          ...configuration,
          ...newProperties,
        };
        if (!isEqual(newConfiguration, configuration)) {
          draft.update({
            source: EJSON.stringify(newConfiguration),
          });
          return true;
        }
      }
      return false;
    });

    // Compile all relevant drafts to see if there are no problems after update.
    await Promise.all(
      map(drafts, async (draft) => {
        await draft.compile();
      }),
    );

    // Finally, save all relevant drafts.
    await Promise.all(map(drafts, (draft) => draft.save()));
  }

  async deleteAllDrafts() {
    const { blueprintId } = this.props;
    const drafts = map(
      await this.call(apiBlueprintsGetDrafts, {
        blueprintId,
      }),
      (rawDraft) => {
        return new BlueprintDraft(rawDraft, this.client);
      },
    );
    await Promise.all(map(drafts, (draft) => draft.delete()));
  }

  static async get(blueprintId, client) {
    const props = await client.call(apiBlueprintsGetBlueprint, {
      blueprintId,
    });
    return new Blueprint(props, client);
  }
}

export default Blueprint;
