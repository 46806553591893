import { createEntitiesSelectors } from '@zedoc/ddp-connector';
import { EJSON } from '@zedoc/ejson';

const defaultTransform = (x) => x;

const createSelectors = (Model, plugins, options = {}) => {
  const {
    collection = Model.collection,
    transform = defaultTransform,
    transformContextSelector,
  } = options;
  if (!collection) {
    throw new Error(
      `Cannot create selectors for model: "${Model.name}" because it does not have collection property`,
    );
  }
  const selectors = createEntitiesSelectors(collection, {
    Model,
    prefix: Model.store,
    plugins,
    transformContextSelector,
    transform: (doc, ctx) => transform(EJSON.fromJSONValue(doc), ctx),
  });
  return selectors;
};

export default createSelectors;
