import map from 'lodash/map';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useDDPSubscription } from '@zedoc/ddp-connector';
import { getCurrentYearMonthDay } from '@zedoc/date';
import { default as ProjectSelect } from '../common/selectors/Project';
import { default as ProjectTrackSelect } from '../common/selectors/ProjectTrack';
import Participation from '../common/models/Participation';
import { apiZedocOneProject } from '../common/api/zedoc';
import { PARTICIPATION_STATE__ACTIVE } from '../common/constants';
import PermissionsDomain from '../common/models/PermissionsDomain';
import usePermissionsRealm from './usePermissionsRealm';
import { PROJECT_PROFILE_CREATE_PARTICIPATION } from '../common/permissions';

/**
 * @typedef {import('../common/models/Project').default} Project
 */

/**
 * @typedef {import('../common/models/ProjectTrack').default} ProjectTrack
 */

/**
 * @typedef {object} NewParticipation
 * @property {Project} [project]
 * @property {Participation} [newParticipation]
 * @property {boolean} newParticipationReady
 */

/**
 * @param {string} projectId
 * @returns {NewParticipation}
 */
function useNewParticipation(projectId) {
  /** @type {Project | undefined} */
  const project = useSelector(ProjectSelect.one().whereIdEquals(projectId));

  const { ready: projectReady } = useDDPSubscription(
    projectId &&
      apiZedocOneProject.withParams({
        projectId,
      }),
  );

  /** @type {ProjectTrack | undefined} */
  const defaultTrack = useSelector(
    ProjectTrackSelect.one()
      .where({
        projectId,
      })
      .sort({
        index: 1,
      }),
  );

  const defaultTrackId = defaultTrack && defaultTrack._id;
  const { domainsReady: createRealmReady, domains: createRealm } =
    usePermissionsRealm([PROJECT_PROFILE_CREATE_PARTICIPATION], {
      scope: project ? project.getDomains() : [],
    });

  // const { domainsReady: deleteRealmReady, domains: deleteRealm } =
  //   usePermissionsRealm([PROJECT_PROFILE_DELETE_PARTICIPATION], {
  //     scope: project ? project.getDomains() : [],
  //   });

  const newParticipation = useMemo(() => {
    return new Participation({
      ownership: map(
        PermissionsDomain.extractFundamentalDomains(createRealm),
        (domain) => ({
          domain,
        }),
      ),
      state: PARTICIPATION_STATE__ACTIVE,
      trackId: defaultTrackId,
      trackDate: getCurrentYearMonthDay(),
    });
  }, [createRealm, defaultTrackId]);

  return {
    newParticipation,
    newParticipationReady: projectReady && createRealmReady,
  };
}

export default useNewParticipation;
