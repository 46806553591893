import ApiSpec from './ApiSpec';
import {
  PATIENT_MILESTONE_REVIEW_ANSWERS_SHEET,
  PATIENT_MILESTONE_SEARCH_ANSWERS_SHEET,
  PATIENT_MILESTONE_REVIEW_ACTIVITY,
  PATIENT_MILESTONE_SEARCH_ACTIVITY,
  PROJECT_PROFILE_REVIEW_PARTICIPATION,
  PROJECT_PROFILE_SEARCH_PARTICIPATION,
  PATIENT_REVIEW_PATIENT,
  PROJECT_REVIEW_DASHBOARD,
} from '../permissions';
import { mergeObjectSchemas, searchApiSchema } from './common';
import Id from '../schemata/Id';

export const apiDocumentsGenerateForAnswersSheet = new ApiSpec({
  name: 'api.documents.generateForAnswersSheet',
  resources: [PATIENT_MILESTONE_REVIEW_ANSWERS_SHEET, PROJECT_REVIEW_DASHBOARD],
  schema: {
    type: 'object',
    required: ['answersSheetId', 'dashboardId'],
    properties: {
      answersSheetId: Id,
      dashboardId: Id,
      regenerate: {
        type: 'boolean',
      },
      params: {
        type: 'object',
        additionalProps: {
          type: ['string', 'number', 'boolean', 'null'],
        },
      },
    },
  },
});

export const apiDocumentsAllForAnswersSheet = new ApiSpec({
  name: 'api.documents.allForAnswersSheet',
  resources: [PATIENT_MILESTONE_REVIEW_ANSWERS_SHEET, PROJECT_REVIEW_DASHBOARD],
  schema: {
    type: 'object',
    required: ['answersSheetId', 'dashboardId'],
    properties: {
      answersSheetId: Id,
      dashboardId: Id,
    },
  },
});

export const apiDocumentsSearchAnswersSheets = new ApiSpec({
  name: 'api.documents.searchAnswersSheets',
  resources: [
    PROJECT_REVIEW_DASHBOARD,
    {
      variables: {
        scope: PATIENT_MILESTONE_SEARCH_ANSWERS_SHEET,
      },
    },
  ],
  schema: mergeObjectSchemas(searchApiSchema, {
    type: 'object',
    required: ['dashboardId'],
    properties: {
      dashboardId: Id,
    },
  }),
});

export const apiDocumentsGenerateForActivities = new ApiSpec({
  name: 'api.documents.generateForActivity',
  resources: [PATIENT_MILESTONE_REVIEW_ACTIVITY, PROJECT_REVIEW_DASHBOARD],
  schema: {
    type: 'object',
    required: ['activityId', 'dashboardId'],
    properties: {
      activityId: Id,
      dashboardId: Id,
      regenerate: {
        type: 'boolean',
      },
      params: {
        type: 'object',
        additionalProps: {
          type: ['string', 'number', 'boolean', 'null'],
        },
      },
    },
  },
});

export const apiDocumentsAllForActivity = new ApiSpec({
  name: 'api.documents.allForActivity',
  resources: [PATIENT_MILESTONE_REVIEW_ACTIVITY, PROJECT_REVIEW_DASHBOARD],
  schema: {
    type: 'object',
    required: ['activityId', 'dashboardId'],
    properties: {
      activityId: Id,
      dashboardId: Id,
    },
  },
});

export const apiDocumentsSearchActivities = new ApiSpec({
  name: 'api.documents.searchActivities',
  resources: [
    PROJECT_REVIEW_DASHBOARD,
    {
      variables: {
        scope: PATIENT_MILESTONE_SEARCH_ACTIVITY,
      },
    },
  ],
  schema: mergeObjectSchemas(searchApiSchema, {
    type: 'object',
    required: ['dashboardId'],
    properties: {
      dashboardId: Id,
    },
  }),
});

export const apiDocumentsGenerateForParticipation = new ApiSpec({
  name: 'api.documents.generateForParticipation',
  resources: [PROJECT_PROFILE_REVIEW_PARTICIPATION, PROJECT_REVIEW_DASHBOARD],
  schema: {
    type: 'object',
    required: ['participationId', 'dashboardId'],
    properties: {
      participationId: Id,
      dashboardId: Id,
      regenerate: {
        type: 'boolean',
      },
      params: {
        type: 'object',
        additionalProps: {
          type: ['string', 'number', 'boolean', 'null'],
        },
      },
    },
  },
});

export const apiDocumentsAllForParticipation = new ApiSpec({
  name: 'api.documents.allForParticipation',
  resources: [PROJECT_PROFILE_REVIEW_PARTICIPATION, PROJECT_REVIEW_DASHBOARD],
  schema: {
    type: 'object',
    required: ['participationId', 'dashboardId'],
    properties: {
      participationId: Id,
      dashboardId: Id,
    },
  },
});

export const apiDocumentsSearchParticipations = new ApiSpec({
  name: 'api.documents.searchParticipations',
  resources: [
    PROJECT_REVIEW_DASHBOARD,
    {
      variables: {
        scope: PROJECT_PROFILE_SEARCH_PARTICIPATION,
      },
    },
  ],
  schema: mergeObjectSchemas(searchApiSchema, {
    type: 'object',
    required: ['dashboardId'],
    properties: {
      dashboardId: Id,
    },
  }),
});

export const apiDocumentsGenerateForRecipient = new ApiSpec({
  name: 'api.documents.generateForRecipient',
  resources: [PATIENT_REVIEW_PATIENT, PROJECT_REVIEW_DASHBOARD],
  schema: {
    type: 'object',
    required: ['recipientId', 'dashboardId'],
    properties: {
      recipientId: Id,
      dashboardId: Id,
      regenerate: {
        type: 'boolean',
      },
      params: {
        type: 'object',
        additionalProps: {
          type: ['string', 'number', 'boolean', 'null'],
        },
      },
    },
  },
});

export const apiDocumentsAllForRecipient = new ApiSpec({
  name: 'api.documents.allForRecipient',
  resources: [PATIENT_REVIEW_PATIENT, PROJECT_REVIEW_DASHBOARD],
  schema: {
    type: 'object',
    required: ['recipientId', 'dashboardId'],
    properties: {
      recipientId: Id,
      dashboardId: Id,
    },
  },
});

export const apiDocumentsSearchRecipients = new ApiSpec({
  name: 'api.documents.searchRecipients',
  resources: [
    PROJECT_REVIEW_DASHBOARD,
    {
      variables: {
        scope: PROJECT_PROFILE_SEARCH_PARTICIPATION,
      },
    },
  ],
  schema: mergeObjectSchemas(searchApiSchema, {
    type: 'object',
    required: ['dashboardId'],
    properties: {
      dashboardId: Id,
    },
  }),
});
