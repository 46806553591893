import { Questionnaire } from '@zedoc/questionnaire';
import { default as rawQuestionnaire } from './ProjectWizard.json';

/**
 * How to make changes to ProjectWizard.json in a feature branch:
 *
 * 1. Assuming that you're branching from develop, start by going into
 *    form-builder.zedoc.co and create a new project by uploading
 *    the contents of ProjectWizard.fb.json that you will find in this
 *    repository.
 *
 * 2. The copy should be placed in "dev" namespace and the identifier
 *    should correspond to your feature branch name, e.g.
 *    dev/project_wizard-feature-1234.
 *
 * 3. Make the necessary edits in the new project and make sure you
 *    save them. You don't necessarily need to publish any questionnaire.
 *
 * 4. When the edits are done, download the *.fb.json file and use it to
 *    overwrite ProjectWizard.fb.json in your feature branch.
 *
 * 5. To minimize the risk of merge conflicts, undo changes to "_id", "name"
 *    and "identifier" fields as well as "sectionId" of the first question.
 *
 * 6. Go to zedoc.io/scripts and execute: rushx project-wizard. This will
 *    re-generate ProjectWizard.json based on ProjectWizard.fb.json.
 *
 * 7. Finally commit changes to the feature branch and eventually merge them
 *    to develop. Merging to develop may of course require conflicts resolution
 *    assuming some other developer also modified ProjectWizard.fb.json. You will
 *    need to resolve them manually.
 *
 * 8. After resolving potential conflicts re-generate ProjectWizard.json
 *    using the method described in point 4.
 */
export default new Questionnaire(rawQuestionnaire);
