import forEach from 'lodash/forEach';
import {
  createCheckSchema,
  getAllErrors,
  getErrorMessage as getJsonSchemaErrorMessage,
} from '@zedoc/check-schema';
import ClientSafeError from '../utils/ClientSafeError';

/**
 * @typedef {object} FieldError
 * @property {string} name
 * @property {string} type
 */

export class ValidationError extends ClientSafeError {
  /**
   * @param {FieldError[]} errors
   * @param {string} reason
   */
  constructor(errors, reason) {
    super('validation-error', reason, errors);
    this.name = 'ValidationError';
  }
}

/**
 * @template {import('json-schema-to-ts').JSONSchema7} T
 * @param {T} jsonSchema
 * @param {new (errors: FieldError[], reason: string) => ClientSafeError} SchemaValidationError
 * @returns {(value: unknown) => void}
 */
export const makeJsonSchemaValidator = (
  jsonSchema,
  SchemaValidationError = ValidationError,
) => {
  if (!jsonSchema) {
    return () => {};
  }
  const checkSchema = createCheckSchema({
    rootSchema: jsonSchema,
  });
  return function validate(value) {
    const cleanedValue = value;
    const error = checkSchema({ $ref: '#' }, cleanedValue);
    if (error) {
      /** @type {FieldError[]} */
      const errors = [];
      const allErrors = getAllErrors(error);
      forEach(allErrors, ({ key, message }) => {
        errors.push({
          name: key || '$$ROOT',
          type: message,
        });
      });
      throw new SchemaValidationError(errors, getJsonSchemaErrorMessage(error));
    }
  };
};

/**
 * @typedef {object} ErrorDescriptor
 * @property {string} [message]
 * @property {Record<string, ErrorDescriptor>} [errors]
 */

/**
 * @typedef {string | ErrorMessageDictionary} ErrorMessage
 */

/**
 * @template [T=never]
 * @typedef {{[x: string]: ErrorMessage}} ErrorMessageDictionary
 */
