import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import EnvironmentAlert from '../common/components/EnvironmentAlert';
import settings from '../common/settings';
import branding from './branding';
import CurrentUser from '../models/CurrentUser';

const { commitSha, commitTime, branchName, showDemoBanner } = settings.public;

const showStagingBanner = true;

const ENVIRONMENT__DEMO = 'demo';
const ENVIRONMENT__STAGING = 'staging';

const getEnvironmentName = () => {
  if (showDemoBanner) {
    return ENVIRONMENT__DEMO;
  }

  if (showStagingBanner) {
    return ENVIRONMENT__STAGING;
  }

  return null;
};

const getEnvironmentType = () => {
  if (showDemoBanner) {
    return 'info';
  }

  if (showStagingBanner) {
    return 'warning';
  }

  return null;
};

export default () => {
  const { t } = useTranslation();
  const user = useSelector(CurrentUser.select.user());

  const details = [
    {
      label: t('forms:version.label'),
      value: branchName,
    },
    {
      label: t('forms:hash.label'),
      value: commitSha.substr(0, 8),
    },
    {
      label: t('updatedAt'),
      value: moment.unix(commitTime).format('DD/MM/YYYY HH:mm'),
    },
    {
      label: t('users', { count: 1 }),
      value: user && user.name,
    },
    {
      label: t('forms:userId.label'),
      value: user && user._id,
    },
  ];
  const name = getEnvironmentName();
  const type = getEnvironmentType();

  if (!showDemoBanner && !showStagingBanner) {
    return null;
  }

  return (
    <EnvironmentAlert type={type} details={details}>
      <span className="text-xs">
        <strong className="text-sm">
          {t('components:EnvironmentAlert.attention')}
        </strong>{' '}
        <Trans
          i18nKey={
            name === ENVIRONMENT__DEMO
              ? 'components:EnvironmentAlert.contentDemo'
              : 'components:EnvironmentAlert.contentStaging'
          }
        >
          <strong className="text-sm" />
        </Trans>{' '}
        {t('components:EnvironmentAlert.contentEnd', {
          context: branding,
        })}
      </span>
    </EnvironmentAlert>
  );
};
