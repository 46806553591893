/* eslint-disable react/forbid-prop-types */
import includes from 'lodash/includes';
import React from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';
import { Questionnaire as QuestionnaireModel } from '@zedoc/questionnaire';
import {
  Questionnaire,
  Question as OriginalQuestion,
} from '@zedoc/react-questionnaire';
import { Markdown } from '@zedoc/react-markdown';
import FormInput from './FormInput';
import Stack from '../../../common/components/primitives/Stack';
import Collection from './Collection';
import CollectionNested from './CollectionNested';

const Section = pure(
  ({
    questionnaire,
    sectionId,
    questionsIds,
    valueSetId,
    nameId,
    otherNameId,
    isEditing,
    isNested,
    hasOrder,
    isTertiary,
    headerLevel,
  }) => {
    return questionnaire.mapQuestions(
      ({ id: questionId }) => (
        <OriginalQuestion id={questionId} key={questionId}>
          {({ question, valueSet, elements, handleAppend }) => {
            if (!includes(questionsIds, question.id)) {
              return null;
            }
            if (question.isForInternalUsage()) {
              return null;
            }
            if (question.shouldHideAndIgnoreAnswer()) {
              return null;
            }
            if (question.isSection() || question.isEmpty()) {
              if (headerLevel && headerLevel === 2) {
                return (
                  <Stack space={0}>
                    <h2 data-testid={`label-${question.getTitle()}`}>
                      <Markdown source={question.getTitle()} />
                    </h2>
                    <Markdown source={question.getDescription()} />
                  </Stack>
                );
              }
              return (
                <Stack space={0}>
                  <h3 data-testid={`label-${question.getTitle()}`}>
                    <Markdown source={question.getTitle()} />
                  </h3>
                  <Markdown source={question.getDescription()} />
                </Stack>
              );
            }
            if (question.isCollection()) {
              if (isNested) {
                if (isEditing) {
                  return (
                    <CollectionNested
                      elements={elements}
                      question={question}
                      questionnaire={questionnaire}
                      onAppend={handleAppend}
                      Section={Section}
                      questionsIds={questionsIds}
                      valueSetId={valueSetId}
                      nameId={nameId}
                      otherNameId={otherNameId}
                      isEditing={isEditing}
                    />
                  );
                }

                return null;
              }
              return (
                <Collection
                  elements={elements}
                  question={question}
                  questionnaire={questionnaire}
                  onAppend={handleAppend}
                  Section={Section}
                  questionsIds={questionsIds}
                  valueSetId={valueSetId}
                  nameId={nameId}
                  otherNameId={otherNameId}
                  hasOrder={hasOrder}
                  isTertiary={isTertiary}
                />
              );
            }

            return (
              <>
                <FormInput
                  question={question}
                  valueSet={valueSet}
                  valueSetId={valueSetId}
                  nameId={nameId}
                  otherNameId={otherNameId}
                  isEditing={isEditing}
                />
                {isEditing && <Markdown source={question.getDescription()} />}
              </>
            );
          }}
        </OriginalQuestion>
      ),
      {
        sectionId,
        stopRecursion: (q) => q.isCollection(),
      },
    );
  },
);

const Question = ({
  projectId,
  questionnaire,
  variables,
  // TODO: We may not need it
  sectionId,
  // eslint-disable-next-line react/prop-types
  questionsIds,
  // eslint-disable-next-line react/prop-types
  valueSetId,
  // eslint-disable-next-line react/prop-types
  nameId,
  // eslint-disable-next-line react/prop-types
  otherNameId,
  hasOrder,
  isTertiary,
  headerLevel,
}) => (
  <Questionnaire
    name={`projectSettings::${projectId}`}
    variables={variables}
    questionnaire={questionnaire}
    // NOTE: The state is controlled at the router level,
    //       so we don't want to mess up with it here.
    keepStateOnUnmount
  >
    <Stack>
      <Section
        questionnaire={questionnaire}
        sectionId={sectionId}
        questionsIds={questionsIds}
        valueSetId={valueSetId}
        nameId={nameId}
        otherNameId={otherNameId}
        hasOrder={hasOrder}
        isTertiary={isTertiary}
        headerLevel={headerLevel}
        isEditing
      />
    </Stack>
  </Questionnaire>
);

Question.propTypes = {
  projectId: PropTypes.string.isRequired,
  questionnaire: PropTypes.instanceOf(QuestionnaireModel).isRequired,
  sectionId: PropTypes.string,
  headerLevel: PropTypes.number,
  variables: PropTypes.objectOf(PropTypes.any),
  hasOrder: PropTypes.bool,
  isTertiary: PropTypes.bool,
};

Question.defaultProps = {
  sectionId: null,
  variables: null,
  hasOrder: false,
  isTertiary: false,
  headerLevel: null,
};

export default Question;
