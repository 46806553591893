const IdDigits = '23456789ABCDEFGHJKLMNPQRSTWXYZabcdefghijkmnopqrstuvwxyz';

const Id = /** @type {const} */ ({
  type: 'string',
  pattern: `^[a-zA-Z0-9]{4}-[a-zA-Z0-9]{2}-[${IdDigits}]{17}$`,
});

export { IdDigits };

export default Id;
