import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import keyBy from 'lodash/keyBy';
import {
  nextVersionString,
  findMaxMatchingVersion,
} from '@zedoc/questionnaire';
import { createValidator } from '@zedoc/check-schema';
import { EJSON } from '@zedoc/ejson';
import {
  apiBlueprintsGetBlueprint,
  apiBlueprintsGetRelease,
  apiBlueprintsGetReleases,
  apiBlueprintsCreateRelease,
} from '../../api/blueprints';
import {
  FEATURES,
  BLUEPRINT_FEATURE_SET__FULL,
} from '../../schemata/BlueprintFeature';
import ProjectConfiguration from '../../schemata/ProjectConfiguration';
import { BLUEPRINT_RELEASE_SOURCE_TYPE__PROJECT_JSON } from './constants';
import mergeConfigurations from './mergeConfigurations';
import getModifiedFeatures from './getModifiedFeatures';
import ClientSafeError from '../../utils/ClientSafeError';

const validateConfiguration = createValidator(ProjectConfiguration);

class BlueprintRelease {
  constructor(props, client) {
    this.client = client;
    this.replace(props);
  }

  replace(props = {}) {
    this.props = {
      ...props,
    };
    return this;
  }

  getVersion() {
    return this.props.version;
  }

  getNextVersion(releaseType = 'patch') {
    return nextVersionString(this.getVersion(), releaseType);
  }

  update(newProps) {
    this.props = {
      ...this.props,
      ...newProps,
    };
    return this;
  }

  async call(apiSpec, params) {
    if (!this.client) {
      throw new Error(
        `Cannot call ${apiSpec.getName()} because client is not specified.`,
      );
    }
    return this.client.call(apiSpec, params);
  }

  async save() {
    const props = await this.call(apiBlueprintsCreateRelease, this.props);
    return this.replace(props);
  }

  async compile() {
    const {
      blueprintId,
      parentId,
      parentVersion,
      source,
      sourceType,
      sourceModifiedFeatures,
    } = this.props;
    let parentRelease;
    if (parentId && parentVersion) {
      parentRelease = await this.constructor.get(
        parentId,
        parentVersion,
        this.client,
      );
    }
    let parentConfiguration;
    if (parentRelease) {
      ({ configuration: parentConfiguration } = await parentRelease.compile());
    }
    let ownConfiguration;
    let modifiedFeatures = sourceModifiedFeatures;
    switch (sourceType) {
      case BLUEPRINT_RELEASE_SOURCE_TYPE__PROJECT_JSON: {
        try {
          ownConfiguration = EJSON.parse(source);
        } catch (err) {
          throw new ClientSafeError('compileError', 'Expected valid JSON');
        }
        try {
          validateConfiguration(ownConfiguration);
        } catch (err) {
          throw new ClientSafeError('compileError', err.message);
        }
        if (!modifiedFeatures) {
          // TODO: This is a temporary workaround for situations
          //       when modifiedFeatures are not properly tracked.
          //       So what happens here is we assume that all features
          //       that are allowed could have been modified. Of course
          //       this is sub-optimal but it should work fine for SaaS
          //       scenario. We will need to revisit it later on.
          const { featureSet = BLUEPRINT_FEATURE_SET__FULL } = await this.call(
            apiBlueprintsGetBlueprint,
            {
              blueprintId,
            },
          );
          modifiedFeatures = getModifiedFeatures(
            ownConfiguration,
            FEATURES[featureSet],
          );
        }
        break;
      }
      default: {
        if (sourceType) {
          throw new ClientSafeError(
            'compileError',
            `Unknown source type: ${sourceType}`,
          );
        }
      }
    }

    const configuration = parentConfiguration
      ? mergeConfigurations(
          parentConfiguration,
          ownConfiguration,
          modifiedFeatures,
        )
      : ownConfiguration;
    return {
      configuration,
      modifiedFeatures,
    };
  }

  async getProjectConfiguration() {
    let configuration;
    try {
      ({ configuration } = await this.compile());
    } catch (err) {
      // ...
    }
    return configuration;
  }

  static async get(blueprintId, version, client) {
    const props = await client.call(apiBlueprintsGetRelease, {
      blueprintId,
      version,
    });
    return new BlueprintRelease(props, client);
  }

  static async getMaxVersionSatisfying(blueprintId, versionRange, client) {
    // TODO: Optimize this because we don't need to fetch all releases of course.
    //       It would be just fine to fetch the latest one only.
    const releases = await client.call(apiBlueprintsGetReleases, {
      blueprintId,
    });
    if (isEmpty(releases)) {
      return undefined;
    }
    const versions = map(releases, 'version');
    const byVersion = keyBy(releases, 'version');
    const maxVersion = findMaxMatchingVersion(versionRange || 'x', versions);
    return new BlueprintRelease(byVersion[maxVersion], client);
  }
}

export default BlueprintRelease;
