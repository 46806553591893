import React, { Suspense } from 'react';
import i18next from 'i18next';
import Button from '../common/components/Button';
import notification from '../common/utilsClient/notification';
import parentLogger from '../common/logger';

export const logger = parentLogger.create('notifications');

const handleGetContainer = () =>
  document.querySelector('dialog') || document.body;

const onClose = () => notification.destroy();

notification.config({
  duration: 2.5,
});

const isOk = (value) => {
  return value && typeof value === 'string' && i18next.exists(value);
};

export const cancel = new Error();

export const notifyError =
  ({ renderOutsideModal, useWarning } = {}) =>
  (err) => {
    if (err === cancel) {
      return;
    }
    const key = `error_${Date.now()}`;
    let meta = {};
    let msg;
    if (typeof err === 'string') {
      msg = err;
    } else if (err instanceof Error) {
      if (isOk(err.error)) {
        msg = `${i18next.t('errors', { count: 1 })}: ${i18next.t(
          err.error,
          err.details,
        )}`;
      } else if (isOk(err.reason)) {
        msg = `${i18next.t('errors', { count: 1 })}: ${i18next.t(
          err.reason,
          err.details,
        )}`;
      } else if (isOk(err.message)) {
        msg = `${i18next.t('errors', { count: 1 })}: ${i18next.t(err.message)}`;
      } else if (err.reason && typeof err.reason === 'string') {
        msg = `${i18next.t('errors', { count: 1 })}: ${err.reason}`;
      } else {
        msg = err.message;
      }
      meta = {
        ...err,
        stack: err.stack,
      };
    } else if (err && typeof err === 'object' && err.message) {
      msg = err.message;
      meta = err;
    } else {
      msg = 'Unknown error';
    }
    const options = {
      key,
      message: msg,
      duration: 0,
      btn: (
        <Suspense fallback={<div>loading ...</div>}>
          <Button onClick={() => notification.close(key)}>
            {i18next.t('close')}
          </Button>
        </Suspense>
      ),
      getContainer: () =>
        renderOutsideModal ? document.body : handleGetContainer(),
      // Have to destroy notifications on close otherwise 'getContainer' won't work
      onClose,
    };
    if (useWarning) {
      notification.warning(options);
      logger.warn(msg, meta);
    } else {
      notification.error(options);
      logger.error(msg, meta);
    }
  };

export const notifySuccess =
  (message, { renderOutsideModal } = {}) =>
  (...args) => {
    let msg;
    if (typeof message === 'function') {
      msg = message(...args);
    } else if (message) {
      msg = message.toString();
    } else {
      msg = 'Success';
    }
    notification.success({
      message: msg,
      getContainer: () =>
        renderOutsideModal ? document.body : handleGetContainer(),
      // Have to destroy notifications on close otherwise 'getContainer' won't work
      onClose,
    });
    logger.debug(msg, {
      args,
    });
  };
