import React from 'react';
import { useTranslation } from 'react-i18next';
import { ADMIN_CREATE_REPORT } from '../../common/permissions';
import Text from '../../common/components/base/Text';
import Sidebar from '../../common/components/primitives/Sidebar';
import Stack from '../../common/components/primitives/Stack';
import Search from '../../components/inputs/Search';
import ReportsDirectory from './components/ReportsDirectory';
import useDocumentTitle from '../../utils/useDocumentTitle';
import usePermission from '../../utils/usePermission';

const SettingsReports = () => {
  const { t } = useTranslation();

  useDocumentTitle([t('settings'), t('reports')]);

  const canCreateReport = usePermission([ADMIN_CREATE_REPORT]);

  return (
    <Stack space={4}>
      <Sidebar
        sidebar={
          canCreateReport ? (
            <Text.Link
              href="https://app.powerbi.com/groups/me/list/reports"
              target="_new"
            >
              {t('addReport')}
            </Text.Link>
          ) : null
        }
      >
        <Search />
      </Sidebar>
      <ReportsDirectory />
    </Stack>
  );
};

SettingsReports.propTypes = {};

SettingsReports.defaultProps = {};

export default SettingsReports;
