import groupBy from 'lodash/groupBy';
import reduce from 'lodash/reduce';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import map from 'lodash/map';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useDDPSubscription } from '@zedoc/ddp-connector';
import Empty from '../../../common/components/base/Empty';
import { default as NotificationLogSelect } from '../../../common/selectors/NotificationLog';
import { apiZedocPatientActivityNotifications } from '../../../common/api/zedoc';
import { NOTIFICATION_DELIVERY_TYPE_SMS } from '../../../common/constants';
import RemindersGroup from './RemindersGroup';

const constant = (x) => () => x;

const RemindersList = ({ activityId, projectId }) => {
  useDDPSubscription(
    apiZedocPatientActivityNotifications.withParams({
      activityId,
    }),
  );

  const notifications = useSelector(
    useMemo(() => {
      return (
        NotificationLogSelect.all()
          .satisfying(
            (notification) =>
              !!find(notification.reasonsToSend, {
                activityId,
              }),
          )
          // NOTE: Filter to hide legacy notifications.
          .satisfying(
            (notification) =>
              notification.getState() && !notification.isAborted(),
          )
          .map(
            constant((notification) => ({
              templateId: notification.templateId,
              address: notification.getAddress(),
              state: notification.getState(),
              timestamp: notification.getTimestamp(),
              deliveryType: notification.getType(),
              numberOfMessageParts: notification.getNumberOfMessageParts(),
            })),
          )
      );
    }, [activityId]),
  );
  const groupedNotifications = groupBy(notifications, 'deliveryType');
  const numberOfMessagePartsTotal =
    !find(
      groupedNotifications[NOTIFICATION_DELIVERY_TYPE_SMS],
      // NOTE: -1 is a rare case e.g. Clalit Unicell as mentioned here
      // https://theclinician.atlassian.net/browse/TECH-750?focusedCommentId=20457
      ({ numberOfMessageParts }) => numberOfMessageParts === -1,
    ) &&
    reduce(
      groupedNotifications[NOTIFICATION_DELIVERY_TYPE_SMS],
      (result, { numberOfMessageParts }) => {
        if (isNumber(numberOfMessageParts)) {
          return result + numberOfMessageParts;
        }

        return result;
      },
      0,
    );

  return !isEmpty(notifications) ? (
    <div className="stack-4">
      {/* NOTE: Always show text reminders group at the top */}
      <RemindersGroup
        projectId={projectId}
        notifications={groupedNotifications[NOTIFICATION_DELIVERY_TYPE_SMS]}
        type={NOTIFICATION_DELIVERY_TYPE_SMS}
        total={numberOfMessagePartsTotal}
      />
      {map(
        groupedNotifications,
        (notificationsGroup, type) =>
          type !== NOTIFICATION_DELIVERY_TYPE_SMS && (
            <RemindersGroup
              projectId={projectId}
              notifications={notificationsGroup}
              type={type}
              total={notificationsGroup.length}
            />
          ),
      )}
    </div>
  ) : (
    <Empty />
  );
};

RemindersList.propTypes = {
  activityId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
};

export default RemindersList;
