const CalendarDateSchema = /** @type {const} */ ({
  type: 'object',
  required: ['yearMonthDay'],
  properties: {
    yearMonthDay: {
      title: 'Date',
      type: 'string',
    }, // YYYY-MM-DD
    hours: {
      type: 'number',
      title: 'Time',
    }, // e.g. 1420 stands for 2:20 pm
  },
});

export default CalendarDateSchema;
