import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import Search from '../../../components/inputs/Search';
import Select from '../../../components/inputs/Select';
import Button from '../../../components/Button';
import {
  setGroup,
  setActiveDomain,
  getActiveDomain,
  setUsersGroupDialogVisible,
  setCreateUsersGroupFlag,
} from '../store';

const UsersGroupsFilters = ({ domains }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const onDomainChange = useCallback(
    (value) => dispatch(setActiveDomain(value)),
    [dispatch],
  );

  const onAdd = useCallback(() => {
    dispatch(setUsersGroupDialogVisible(true));
    dispatch(setCreateUsersGroupFlag(true));
    dispatch(
      setGroup({
        type: '',
        name: '',
        belongsTo: '',
        roles: [],
      }),
    );
  }, [dispatch]);

  const activeDomain = useSelector(getActiveDomain);
  return (
    <div className="cluster-4">
      <div className="grid gap-4 grid-cols-auto-fit flex-1">
        <Search />
        <Select
          data-testid="select-projects"
          className="zedoc-tour__filter"
          options={[
            {
              value: '',
              label: t('all'),
            },
            ...domains,
          ]}
          onChange={onDomainChange}
          value={activeDomain}
          showSearch
          allowClear
        />
      </div>
      <Button data-testid="button-add-group" type="primary" onClick={onAdd}>
        {t('addGroup')}
      </Button>
    </div>
  );
};

UsersGroupsFilters.propTypes = {
  domains: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
};

UsersGroupsFilters.defaultProps = {
  domains: [],
};

export default UsersGroupsFilters;
