import sanitizeHtml from 'sanitize-html';

const clearValue = (obj, key) => {
  if (!Object.prototype.hasOwnProperty.call(obj, key)) {
    return obj;
  }

  return {
    ...obj,
    [key]: sanitizeHtml(obj[key], {
      allowedTags: [],
    }),
  };
};

export { clearValue };
