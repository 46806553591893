/* eslint-disable no-underscore-dangle */
import React, {
  forwardRef,
  useCallback,
  useContext,
  useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';
import { createSelector } from 'reselect';
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { encodeQuery } from '@zedoc/url';
import { createGetAtKey } from '@zedoc/selectors';
import { DDPContext } from '@zedoc/ddp-connector';
import { useTranslation } from 'react-i18next';
import { Random } from '@zedoc/random';
import Button from './Button';
import OAuth, { credentialRequestCompleteHandler } from '../common/oauth';
import { OAUTH_SERVICE_LOGIN_STYLE__REDIRECT } from '../common/constants/collections/OAuthServices';

const selectAfterLoginRedirect = createSelector(
  createGetAtKey('router.location.state.from'),
  (from) => {
    if (from) {
      return [from.pathname, from.search, from.hash].filter(Boolean).join('');
    }
    return '/';
  },
);

const LoginServices = forwardRef(
  ({ services, isLoginMethodPasswordEnabled }, forwardedRef) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const afterLoginRedirect = useSelector(selectAfterLoginRedirect);
    const ddpConnector = useContext(DDPContext);
    const handleOnClick = useCallback(
      (data) => {
        const {
          service,
          serviceType,
          loginStyle = OAUTH_SERVICE_LOGIN_STYLE__REDIRECT,
          authorizeUrl: baseLoginUrl,
          authorizeUrlQuery,
          clientId,
          requiredScopes = ['openid'],
          resource,
        } = data;

        const credentialToken = Random.secret();
        const query = {
          ...authorizeUrlQuery,
          response_type: 'code',
          client_id: clientId,
          prompt: 'login',
          redirect_uri: `${window.location.origin}/_oauth/${service}`,
          scope: requiredScopes.join(' '),
          state: OAuth._stateParam(
            loginStyle,
            credentialToken,
            loginStyle === OAUTH_SERVICE_LOGIN_STYLE__REDIRECT
              ? window.location.origin + afterLoginRedirect
              : window.location.origin,
          ),
        };

        if (serviceType === 'azure' || serviceType === 'azure:adfs') {
          query.code_challenge = credentialToken;
          if (resource) {
            query.resource = resource;
          }
        }

        const loginUrl = `${baseLoginUrl}${encodeQuery(query)}`;

        const credentialRequestCompleteCallback =
          credentialRequestCompleteHandler(ddpConnector, () => {
            dispatch(push(afterLoginRedirect));
          });

        OAuth.launchLogin({
          loginService: serviceType,
          loginStyle,
          loginUrl,
          credentialRequestCompleteCallback,
          credentialToken,
          popupOptions: {
            height: 600,
          },
        });
      },
      [ddpConnector, afterLoginRedirect, dispatch],
    );

    useImperativeHandle(
      forwardedRef,
      () => ({
        login: handleOnClick,
      }),
      [handleOnClick],
    );

    const isLoggingIn = false;
    return (
      <div className="stack-6">
        {services.map((service) => {
          return (
            <Button
              data-testid={`button-sign-in=${service.service}`}
              key={service._id}
              type={isLoginMethodPasswordEnabled ? 'secondary' : 'primary'}
              size="xl"
              htmlType="submit"
              loading={isLoggingIn}
              onClick={() => handleOnClick(service)}
              isRounded
            >
              {service.displayName ||
                t('entry:signInWith', {
                  serviceName: service.service || '[unknown]',
                })}
            </Button>
          );
        })}
      </div>
    );
  },
);

LoginServices.propTypes = {
  services: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
    }),
  ).isRequired,
  isLoginMethodPasswordEnabled: PropTypes.bool,
};

LoginServices.defaultProps = {
  isLoginMethodPasswordEnabled: true,
};

export default LoginServices;
