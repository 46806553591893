import { withHandlers, compose, branch, renderNothing } from 'recompose';
import { ddp } from '@zedoc/ddp-connector';
import { push } from 'connected-react-router';
import { property } from '@zedoc/selectors';
import { patientDetails } from '../../common/api/zedoc';
import { default as RecipientSelect } from '../../common/selectors/Recipient';
import { default as ParticipationSelect } from '../../common/selectors/Participation';
import { default as PatientRecordSelect } from '../../common/selectors/PatientRecord';
import { openMilestoneDialog } from '../ProjectsDashboard/actions';
import PatientCard from './PatientCard';
import {
  openProjectProfileDialog,
  getPatientCurrentTab,
  setPatientCurrentTab,
  openEditNoteDialog,
  setRemovePatientModalVisible,
} from './store';

const getRecipientId = property('match.params.recipientId');

export default compose(
  ddp({
    selectors: () => {
      return {
        recipientId: getRecipientId,
        recipient: RecipientSelect.one().whereIdEquals(getRecipientId),
        participations: ParticipationSelect.all().where({
          recipientId: getRecipientId,
        }),
        patientRecords: PatientRecordSelect.all().where({
          recipientId: getRecipientId,
        }),
        patientCurrentTab: getPatientCurrentTab,
      };
    },
    subscriptions: (
      _,
      {
        match: {
          params: { recipientId },
        },
      },
    ) => [
      patientDetails.withParams({
        recipientId,
      }),
    ],
  }),
  withHandlers({
    onTabChange:
      ({ dispatch }) =>
      (activeKey) =>
        dispatch(setPatientCurrentTab(activeKey)),
    onDangerouslyRemovePatient:
      ({ dispatch, recipient }) =>
      () =>
        recipient && dispatch(setRemovePatientModalVisible(true)),
    onAddNote:
      ({ dispatch }) =>
      () =>
        dispatch(openEditNoteDialog()),
    handleOnAdded:
      ({ dispatch }) =>
      ({ projectId, recipientId, milestoneId, commenceSurveyNow }) => {
        if (commenceSurveyNow) {
          dispatch(push(`/projects/${projectId}`));
          dispatch(
            openMilestoneDialog({
              recipientId,
              milestoneId,
            }),
          );
        }
      },
    onAddToProject:
      ({
        dispatch,
        match: {
          params: { recipientId },
        },
      }) =>
      () =>
        dispatch(
          openProjectProfileDialog({
            recipientId,
          }),
        ),
  }),
  branch(
    ({ recipient }) => !!recipient,
    (x) => x,
    renderNothing,
  ),
)(PatientCard);
