import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withHandlers, compose, setDisplayName } from 'recompose';
import {
  ROLE_TIER__MEDIUM,
  // ROLE_TIER__HIGH,
} from '../../../../common/constants';
import { PROJECT_PROFILE_VIEW_ANALYTICS } from '../../../../common/permissions';
import User from '../../../../common/models/User';
import ProjectMilestone from '../../../../common/models/ProjectMilestone';
import PatientRecord from '../../../../common/models/PatientRecord';
import branding from '../../../../utils/branding';
import ActivitiesDirectory from './ActivitiesDirectory';

const propTypes = {
  currentUser: PropTypes.instanceOf(User).isRequired,
  onPatientClick: PropTypes.func,
  onMilestoneClick: PropTypes.func,
  patients: PropTypes.arrayOf(PropTypes.instanceOf(PatientRecord)),
  milestones: PropTypes.arrayOf(PropTypes.instanceOf(ProjectMilestone)),
  onAddMilestone: PropTypes.func,
  onRemoveMilestone: PropTypes.func,
  nPatients: PropTypes.number.isRequired,
  patientsReady: PropTypes.bool.isRequired,
  pagination: PropTypes.shape({}),
};

const noop = () => {};
const defaultProps = {
  onPatientClick: noop,
  onMilestoneClick: noop,
  patients: [],
  milestones: [],
  onAddMilestone: noop,
  onRemoveMilestone: noop,
  nPatients: 0,
  patientsReady: true,
  pagination: {},
};

const ProjectTable = compose(
  withHandlers({
    // If user has low tier, they cannot display charts of patients that are not assigned to them ...
    getIsDisabled:
      ({ validate, currentUser }) =>
      (record) => {
        const grant = validate(PROJECT_PROFILE_VIEW_ANALYTICS);

        if (!grant) {
          return true;
        }

        if (
          grant.tier < ROLE_TIER__MEDIUM &&
          !record.isAssignedTo(currentUser._id)
        ) {
          return true;
        }

        return false;
      },
  }),
  setDisplayName('ProjectTable'),
)(
  ({
    patients,
    patientsReady,
    milestones,
    variables,
    subscriptionsReady,
    pagination,
    projectId,
    dashboardId,
    paginationKey,
    onMilestoneClick,
    onPatientClick,
    getIsDisabled,
    onSetActivePatientId,
    headerExtra,
  }) => {
    const { t } = useTranslation();

    return (
      <>
        <div className="cluster-4 items-center justify-between">
          <span className="text-lg font-medium">
            {t('recipients', {
              context: branding,
            })}
          </span>
          {headerExtra}
        </div>
        <hr className="border-divider mt-4" />
        <ActivitiesDirectory
          patients={patients}
          loading={!subscriptionsReady || !patientsReady}
          pagination={{
            ...pagination,
            itemRender: (page, type, originalElement) => (
              <span data-testid={`pagination-${type}-${page}`}>
                {originalElement}
              </span>
            ),
          }}
          projectId={projectId}
          paginationKey={paginationKey}
          dashboardId={dashboardId}
          onPatientClick={onPatientClick}
          getIsDisabled={getIsDisabled}
          milestones={milestones}
          variables={variables}
          onMilestoneClick={onMilestoneClick}
          onSetActivePatientId={onSetActivePatientId}
        />
      </>
    );
  },
);

ProjectTable.propTypes = propTypes;
ProjectTable.defaultProps = defaultProps;

export default ProjectTable;
