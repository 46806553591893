import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { InformationCircleIcon } from '@heroicons/react/outline';
import { useSelector } from 'react-redux';
import { apiZedocSearchProjects } from '../../common/api/zedoc';
import ProjectSelect from '../../common/selectors/Project';
import PageBar from '../../components/Layout/PageBar';
import Table from '../../components/lists/Table';
import Search, { SearchHighlighter } from '../../components/inputs/Search';
import branding from '../../utils/branding';
import Tooltip from '../../common/components/Tooltip';
import Skeleton from '../../components/Skeleton';
import usePagination from '../../utils/usePagination';
import useDocumentTitle from '../../utils/useDocumentTitle';
import { getFilters } from '../ProjectsList/store';

const PatientsInProject = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const handleOnOpenProject = (_id) =>
    history.push(`/patients-in-project/${_id}`);

  const filters = useSelector(getFilters);
  const {
    ready: subscriptionsReady,
    items: projects,
    totalItems,
    getPaginationProps,
  } = usePagination({
    debounceMs: 500,
    selector: ProjectSelect,
    getSubscription: (currentPage, resultsPerPage) =>
      apiZedocSearchProjects.withParams({
        filters,
        controlId: '$meta.id',
        pageIndex: currentPage - 1,
        resultsPerPage,
      }),
  });
  const projectsColumns = [
    {
      dataIndex: 'name',
      key: 'name',
      render: (text) => (
        <div className="text-primary-700 font-medium h-20 flex items-center">
          <SearchHighlighter text={text} />
        </div>
      ),
    },
  ];

  const title = t('recipientsInProject', { context: branding });
  useDocumentTitle([title]);

  return (
    <div className="stack-6">
      <PageBar title={title} />
      <div className="bg-surface py-4 shadow rounded-md stack-4">
        <div className="px-4 stack-4">
          <div className="stack-2">
            <div className="cluster-2 items-center">
              <span>{t('forms:projectName.label')}</span>
              <Tooltip title={t('forms:projectName.tooltip')}>
                <InformationCircleIcon className="w-5 h-5" />
              </Tooltip>
            </div>
            <Search placeholder={t('forms:projectName.placeholder')} />
          </div>
          <div className="cluster-4 items-center justify-between">
            <span className="text-lg font-medium">
              {subscriptionsReady ? (
                <span>{`#${t('projectsWithCount', {
                  count: totalItems,
                })}`}</span>
              ) : (
                <Skeleton className="h-6 w-40" block={false} />
              )}
            </span>
          </div>
        </div>
        <Table
          data-testid="projects-table"
          rowKey="_id"
          dataSource={projects}
          columns={projectsColumns}
          loading={!subscriptionsReady}
          pagination={getPaginationProps()}
          onRow={({ _id }) => ({
            onClick: () => handleOnOpenProject(_id),
          })}
        />
      </div>
    </div>
  );
};

export default PatientsInProject;
