import { useState, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  useDDPSubscription,
  createEntitiesSelectors,
} from '@zedoc/ddp-connector';
import { createSelector } from 'reselect';

const selectors = {};

export const getSelectors = (name) => {
  if (!selectors[name]) {
    selectors[name] = createEntitiesSelectors(name, {});
  }
  return selectors[name];
};

/**
 * @param {object} options
 * @param {string} options.resultId
 * @param {string} options.resultCollection
 * @param {(state: object) => Record<string, unknown>} [options.selector]
 */
export const createResultSelector = ({
  resultId,
  resultCollection,
  selector = createSelector(
    (state) => state?.ddp?.entities,
    getSelectors(resultCollection).one().whereIdEquals(resultId),
    (entities, result) => {
      if (!result) {
        return {};
      }
      return entities[result.collectionName] || {};
    },
  ),
}) => {
  return createSelector(
    getSelectors(resultCollection)
      .one()
      .whereIdEquals(resultId)
      .lookup({
        from: selector,
        as: 'items',
        foreignKey: '_id',
        key: (result, _resultId, documents) => {
          return result?.itemsOrder?.flatMap((id) => documents[id] || []);
        },
      }),
    (result) => ({
      items: result?.items || [],
      metadata: result?.metadata || {},
    }),
  );
};

/**
 * @param {object} [subscription]
 * @param {object} [options]
 * @param {number} [options.debounceMs=250]
 * @param {import('reselect').OutputSelector} [options.selector]
 * @param {string} [options.resultCollection]
 * @returns {{ ready: boolean, items: unknown[], metadata: object, resultId: string }}
 */
const useList = (subscription, options = {}) => {
  const {
    debounceMs = 250,
    selector,
    resultCollection = subscription && `${subscription.name}.Lists`,
  } = options;
  const [resultId, setResultsId] = useState(null);
  const { id, ready } = useDDPSubscription(subscription, {
    debounceMs,
  });
  useEffect(() => {
    if (id && ready) {
      setResultsId(id);
    }
  }, [id, ready]);
  const selectResult = useMemo(() => {
    const nextSelector = createResultSelector({
      selector,
      resultId,
      resultCollection,
    });
    return nextSelector;
  }, [selector, resultId, resultCollection]);
  const { items, metadata } = useSelector(selectResult);
  return {
    ready,
    items,
    metadata,
    resultId,
  };
};

export default useList;
