import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import Text from '../../common/components/base/Text';
import Button from '../Button';
import Divider from '../../common/components/Divider';

const PageHeader = ({ title, subTitle, description, extra, onBack }) => {
  const { i18n } = useTranslation();
  const languageDirection = i18n.dir();
  const isLanguageDirectionLTR = languageDirection === 'ltr';

  return (
    <div className="cluster-6 justify-between items-center">
      <div className="cluster-2 items-center">
        {onBack && (
          <>
            <Button
              data-testid="page-button-back"
              type="tertiary"
              icon={
                isLanguageDirectionLTR ? (
                  <ChevronLeftIcon />
                ) : (
                  <ChevronRightIcon />
                )
              }
              onClick={onBack}
            />
            <Divider.Vertical />
          </>
        )}
        <div className="stack-0">
          <div className="stack-0">
            <h1
              data-testid="page-header"
              // NOTE: Have to use "text-body" because Ant Designs styles make headers black
              className="text-xl font-normal text-body"
            >
              {title}
            </h1>
            <p data-testid="page-subheader" className="text-sm">
              {subTitle}
            </p>
          </div>
          {description && (
            <Text.Paragraph
              data-testid="page-header-description"
              size="small"
              importance="low"
            >
              {description}
            </Text.Paragraph>
          )}
        </div>
      </div>
      {extra}
    </div>
  );
};

PageHeader.propTypes = {
  title: PropTypes.node.isRequired,
  subTitle: PropTypes.node,
  description: PropTypes.string,
  extra: PropTypes.node,
  onBack: PropTypes.func,
};

PageHeader.defaultProps = {
  subTitle: null,
  description: null,
  extra: null,
  onBack: null,
};

export default PageHeader;
