import React from 'react';
import { push } from 'connected-react-router';
import { compose, withProps } from 'recompose';
import { ddp } from '@zedoc/ddp-connector';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import { Trans, withTranslation } from 'react-i18next';
import { removeCascade } from '../../../common/api/collections/Recipients';
import Stack from '../../../common/components/primitives/Stack';
import Text from '../../../common/components/base/Text';
import { notifyError, notifySuccess } from '../../../utils/notify';
import branding from '../../../utils/branding';
import ConfirmRemoveObject from '../../../components/dialogs/ConfirmRemoveObject';
import {
  getRemovePatientModalVisible,
  setRemovePatientModalVisible,
} from '../store';

const form = 'screens_patients_directory_removePatient';
export default compose(
  connect(
    createStructuredSelector({
      form: () => form,
      open: getRemovePatientModalVisible,
    }),
    (dispatch) => ({
      onCancel: () => {
        dispatch(setRemovePatientModalVisible(false));
        dispatch(reset(form));
      },
    }),
  ),
  withTranslation(),
  withProps(({ recipient, t }) => ({
    id: recipient && recipient._id,
    title: recipient && `${t('remove')} ${recipient.getFullName()}?`,
    expectedValue:
      recipient && recipient.getFullName().replace(/[\u00A0]/g, ' '), // remove non breaking spaces!
    expectedValueLabel: t('confirmations:removeRecipient.label', {
      context: branding,
    }),
    message: (
      <Stack>
        <Text.Paragraph>
          <Trans
            i18nKey="confirmations:removeRecipient.title"
            tOptions={{
              context: branding,
            }}
          >
            <Text.Span importance="high">
              {{
                name: recipient && recipient.getFullName(),
              }}
            </Text.Span>
          </Trans>
        </Text.Paragraph>
        <Text.Paragraph>
          <strong>
            {t('confirmations:removeRecipient.danger', {
              context: branding,
            })}
          </strong>
        </Text.Paragraph>
        <Text.Paragraph>
          {t('confirmations:removeRecipient.description', {
            context: branding,
          })}
        </Text.Paragraph>
      </Stack>
    ),
  })),
  ddp({
    subscriptions: (state, { recipient }) => [
      recipient && {
        name: 'api.collections.Recipients.one',
        params: [
          {
            recipientId: recipient._id,
          },
        ],
      },
    ],
    mutations: {
      onConfirm:
        ({ mutate, dispatch, t }) =>
        (recipientId) =>
          mutate(
            removeCascade.withParams({
              recipientId,
            }),
          )
            .then(() => dispatch(setRemovePatientModalVisible(false)))
            .then(() => dispatch(push('/patients')))
            .then(
              notifySuccess(
                t('confirmations:removeRecipient.success', {
                  context: branding,
                }),
              ),
            )
            .catch(notifyError()),
    },
    renderLoader: null,
  }),
)(ConfirmRemoveObject);
