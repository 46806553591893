import PropTypes from 'prop-types';
import forEach from 'lodash/forEach';
import compact from 'lodash/compact';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import get from 'lodash/get';
import some from 'lodash/some';
import isEqual from 'lodash/isEqual';
import startsWith from 'lodash/startsWith';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import isSubset from '../../../common/utils/isSubset';
import FormFieldSelect from '../../forms/FormFieldSelect';
import {
  isTransitionAllowed,
  normalizeStateMachine,
} from '../../../common/utils/stateMachine';

export const getPayload = (stateMachine, current, previous) => {
  const payload = [];
  forEach(stateMachine.coordinates, ({ name }) => {
    if (name !== 'state') {
      const currentValue = get(current, name);
      const previousValue = get(previous, name);
      if (!isEqual(currentValue, previousValue)) {
        payload.push(name);
      }
    }
  });
  return payload;
};

export const getNonEditableKeys = (stateMachine, payload, from, to) => {
  const { coordinates, transitions } = normalizeStateMachine(stateMachine);
  return map(
    filter(coordinates, (coordinate) => {
      if (coordinate.name === 'state') {
        return false;
      }
      return !some(transitions, (transition) => {
        return isTransitionAllowed(transition, from, to, [
          ...payload,
          coordinate.name,
        ]);
      });
    }),
    'name',
  );
};

export const coincidesWithNonEditableKeys = (nonEditableKeys, key) => {
  return some(nonEditableKeys, (anotherKey) => {
    // NOTE: The last condition is probably too restrictive but I doubt this will be a practical issue
    //       because state coordinates will be top level fields in almost all situations that I can think of.
    return (
      anotherKey === key ||
      startsWith(key, `${anotherKey}.`) ||
      startsWith(anotherKey, `${key}.`)
    );
  });
};

const FormFieldState = React.forwardRef(
  (
    { stateMachine: stateMachineProp, previousState, payload, ...otherProps },
    forwardedRef,
  ) => {
    const { t } = useTranslation();
    const stateMachine = normalizeStateMachine(stateMachineProp);
    const stateOptions = useMemo(() => {
      const allowedStates = {};
      forEach(stateMachine.transitions, (transition) => {
        const fromState = transition.from || transition.atState;
        if (fromState === '*' || (fromState && fromState === previousState)) {
          let targetStates;
          if (transition.atState === '*') {
            targetStates = previousState;
          } else if (transition.atState) {
            targetStates = [transition.atState];
          } else if (transition.to === '*') {
            targetStates = map(stateMachine.states, 'state');
          } else if (transition.to) {
            targetStates = [transition.to];
          }
          forEach(targetStates, (state) => {
            const { disabled = true } = allowedStates[state] || {};
            allowedStates[state] = {
              disabled: disabled && !isSubset(payload, transition.payload),
            };
          });
        }
      });
      return compact(
        map(stateMachine.states, (state) => {
          const value = state.state;
          if (value === previousState || allowedStates[value]) {
            return {
              disabled: allowedStates[value]
                ? allowedStates[value].disabled
                : true,
              label: t(
                `stateMachines:${
                  stateMachine.modelName
                }.states.${value.toLowerCase()}`,
              ),
              value,
            };
          }
          return null;
        }),
      );
    }, [
      t,
      payload,
      previousState,
      stateMachine.modelName,
      stateMachine.states,
      stateMachine.transitions,
    ]);

    const extra = useMemo(() => {
      const transitions = filter(stateMachine.transitions, (transition) => {
        return isTransitionAllowed(
          transition,
          previousState,
          otherProps.input.value,
          payload,
        );
      });
      const previousStateLabel = t(
        `stateMachines:${
          stateMachine.modelName
        }.states.${previousState.toLowerCase()}`,
      );
      if (isEmpty(payload)) {
        return `${t('forms:currentState.label', {
          state: previousStateLabel,
        })}`;
      }
      if (!isEmpty(transitions)) {
        return `${t('forms:currentState.label', {
          state: previousStateLabel,
        })}, ${t('forms:transition.label', {
          transition: map(transitions, (transition) => {
            return t(
              `stateMachines:${stateMachine.modelName}.transitions.${transition.actionType}`,
            );
          }).join(', '),
        })}`;
      }
      return null;
    }, [
      t,
      payload,
      previousState,
      otherProps.input.value,
      stateMachine.modelName,
      stateMachine.transitions,
    ]);

    return (
      <FormFieldSelect
        ref={forwardedRef}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...otherProps}
        options={stateOptions}
        extra={extra}
      />
    );
  },
);

FormFieldState.propTypes = {
  previousState: PropTypes.string,
  stateMachine: PropTypes.shape({
    modelName: PropTypes.string.isRequired,
    states: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
    coordinates: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
      }),
    ),
    transitions: PropTypes.arrayOf(
      PropTypes.shape({
        from: PropTypes.string,
        to: PropTypes.string,
      }),
    ),
  }).isRequired,
  payload: PropTypes.arrayOf(PropTypes.string),
};

FormFieldState.defaultProps = {
  previousState: null,
  payload: null,
};

export default FormFieldState;
