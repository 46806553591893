import React from 'react';
import { useTranslation } from 'react-i18next';
import { ADMIN_SEARCH_USER } from '../../common/permissions';
import UsersTable from './components/UsersTable';
import UsersPageBar from './components/UsersPageBar';
import useDocumentTitle from '../../utils/useDocumentTitle';
import Card from '../../components/Card';
import usePermissionsRealm from '../../utils/usePermissionsRealm';

const SettingsUsers = () => {
  const { t } = useTranslation();
  useDocumentTitle([t('settings'), t('users')]);
  const { domainsOptions } = usePermissionsRealm(ADMIN_SEARCH_USER);
  return (
    <Card>
      <div className="stack-4" data-testid="users-table">
        <UsersPageBar domains={domainsOptions} />
        <UsersTable domains={domainsOptions} />
      </div>
    </Card>
  );
};

export default SettingsUsers;
