import React from 'react';
import { useTranslation } from 'react-i18next';
import { ADMIN_SEARCH_AUDIT_LOGS } from '../../common/permissions';
import AuditLogsFilters from './components/AuditLogsFilters';
import AuditLogsTable from './components/AuditLogsTable';
import useDocumentTitle from '../../utils/useDocumentTitle';
import Card from '../../components/Card';
import usePermissionsRealm from '../../utils/usePermissionsRealm';

const SettingsLogs = () => {
  const { t } = useTranslation();
  useDocumentTitle([t('settings'), t('logs')]);
  const { domainsOptions } = usePermissionsRealm(ADMIN_SEARCH_AUDIT_LOGS);
  return (
    <Card>
      <div className="stack-4" data-testid="logs-table">
        <AuditLogsFilters domains={domainsOptions} />
        <AuditLogsTable domains={domainsOptions} />
      </div>
    </Card>
  );
};

export default SettingsLogs;
