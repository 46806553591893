import PropTypes from 'prop-types';
import React from 'react';
import map from 'lodash/map';
import uniqBy from 'lodash/uniqBy';
import { withState, compose } from 'recompose';
import { ddp } from '@zedoc/ddp-connector';
import { createFilterOptionFactory } from '@zedoc/text';
import {
  PropTypesInput,
  PropTypesMeta,
  PropTypesOption,
} from '@zedoc/react-questionnaire';
import FormFieldWrapper from '../../common/components/FormFieldWrapper';
import AutoComplete from '../../common/components/AutoComplete';
import Input from '../../common/components/Input';
import { apiTerminologyExpandValueSet } from '../../common/api/terminology';

const getFilterOption = createFilterOptionFactory();
const toOptions = (result) =>
  map(
    result && result.expansion && result.expansion.contains,
    ({ code, display }) => ({
      value: code,
      label: display,
    }),
  );

class FormFieldSuggestions extends React.Component {
  // NOTE: We need to do it to prevent results disappearing
  //       while the next query is evaluated.
  static getDerivedStateFromProps(props, state) {
    const { expandQueryResult } = props;
    const newState = {
      ...state,
    };
    if (expandQueryResult && expandQueryResult !== state.expandQueryResult) {
      newState.expandQueryResult = expandQueryResult;
    }
    return newState;
  }

  constructor(props) {
    super(props);
    this.state = {
      expandQueryResult: null,
    };
    this.handleSearch = this.handleSearch.bind(this);
  }

  handleSearch(searchText) {
    const { setSearchText } = this.props;
    if (setSearchText) {
      setSearchText(searchText);
    }
  }

  render() {
    const {
      label,
      input,
      meta,
      required,
      placeholder,
      prefixSearchOnly,
      notFoundContent,
      options,
      'data-testid': datatestid,
      disabled,
    } = this.props;
    const { expandQueryResult } = this.state;

    return (
      <FormFieldWrapper
        label={label}
        required={required}
        meta={meta}
        disabled={disabled}
      >
        <AutoComplete
          notFoundContent={notFoundContent}
          dataSource={map(
            uniqBy([...options, ...toOptions(expandQueryResult)], 'value'),
            'label',
          )}
          filterOption={getFilterOption({
            prefixSearchOnly,
          })}
          onSearch={this.handleSearch}
          placeholder={placeholder}
          style={{
            width: '100%',
          }}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...input}
        >
          <Input data-testid={datatestid} />
        </AutoComplete>
      </FormFieldWrapper>
    );
  }
}

FormFieldSuggestions.propTypes = {
  input: PropTypesInput,
  meta: PropTypesMeta,
  label: PropTypes.string,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  prefixSearchOnly: PropTypes.bool,
  notFoundContent: PropTypes.string,
  searchText: PropTypes.string,
  setSearchText: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  expandQueryResult: PropTypes.any,
  mode: PropTypes.string,
  options: PropTypes.arrayOf(PropTypesOption),
  'data-testid': PropTypes.string,
  disabled: PropTypes.bool,
};

FormFieldSuggestions.defaultProps = {
  input: null,
  meta: null,
  label: null,
  required: false,
  placeholder: null,
  prefixSearchOnly: false,
  notFoundContent: 'Nothing found',
  searchText: null,
  setSearchText: null,
  expandQueryResult: null,
  mode: null,
  options: null,
  'data-testid': 'select',
  disabled: false,
};

export default compose(
  withState('searchText', 'setSearchText', null),
  ddp({
    queries: (_, { valueSetId, searchText, prefixSearchOnly }) => ({
      expandQueryResult: valueSetId
        ? apiTerminologyExpandValueSet.withParams({
            searchText,
            prefixSearchOnly,
            id: valueSetId,
          })
        : null,
    }),
    renderLoader: null,
  }),
)(FormFieldSuggestions);
