/**
 * @typedef {import('json-schema-to-ts').FromSchema<typeof AnswerValue>} TypeAnswerValue
 */

const AnswerValue = /** @type {const} */ ({
  anyOf: [
    {
      type: ['null', 'string', 'number', 'boolean'],
    },
    {
      type: 'array',
      items: {
        type: 'string',
      },
    },
    {
      type: 'array',
      items: {
        type: 'number',
      },
    },
  ],
});

export default AnswerValue;
