/* eslint-disable react/prop-types */
import find from 'lodash/find';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { ddp } from '@zedoc/ddp-connector';
import {
  Question,
  QUESTION_TYPE__SELECT_ONE,
  QUESTION_TYPE__SELECT_MANY,
  QUESTION_TYPE__SHORT_TEXT,
  QUESTION_TYPE__FORMULA,
  QUESTION_TYPE__FREE_TEXT,
  QUESTION_TYPE__DATE,
  QUESTION_TYPE__TIME,
  // QUESTION_TYPE__UPLOAD,
  // QUESTION_TYPE__COLLECTION,
  QUESTION_TYPE__EMPTY,
  QUESTION_TYPE__SWITCH,
  QUESTION_TYPE__NUMBER,
  QUESTION_TYPE__NUMBER_WITH_UNIT,
  // QUESTION_CHUNK__OTHER_FIELD,
} from '@zedoc/questionnaire';
import { Field, ValueSetPropTypes } from '@zedoc/react-questionnaire';
import { apiTerminologyExpandValueSet } from '../../../common/api/terminology';
import FormFieldDate from '../../../common/components/FormFieldDate';
import FormFieldNumber from '../../../common/components/FormFieldNumber';
import FormFieldWrapper from '../../../common/components/FormFieldWrapper';
import FormFieldText from '../../../components/forms/FormFieldText';
import FormFieldSearch from '../../../components/forms/FormFieldSearch';
// import FormFieldSelect from '../../../components/forms/FormFieldSelect';
import FormFieldSwitch from '../../../components/forms/FormFieldSwitch';
import FormFieldUnsupported from '../../../common/components/FormFieldUnsupported';

const getTooltip = (questionId) => {
  switch (questionId) {
    case 'a1_name':
      return 'projectWizard:projectName.tooltip';
    case 'a6_timezone':
      return 'projectWizard:timezone.tooltip';
    case 'a7_logo_url':
      return 'projectWizard:logo.tooltip';
    case 'ac1_id':
      return 'projectWizard:recipientIdentifier.tooltip';
    case 'ad7_type':
      return 'projectWizard:milestoneType.tooltip';
    case 'e3_type':
      return 'projectWizard:notificationType.tooltip';
    case 'e12_assignee_types':
      return 'projectWizard:notificationAssigneeType.tooltip';
    case 'e6_delivery':
      return 'projectWizard:notificationDeliveryType.tooltip';
    case 'e8_selected_tracks_only':
      return 'projectWizard:notificationHasSelectedTracks.tooltip';
    case 'e8_selected_milestones_only':
      return 'projectWizard:notificationHasSelectedMilestones.tooltip';
    case 'e1_delay_minutes':
      return 'projectWizard:notificationDelayMinutes.tooltip';
    case 'e7_templates':
      return 'projectWizard:notificationMessageTemplates.tooltip';
    case 'ad10_recurring':
      return 'projectWizard:milestoneIsRecurring.tooltip';
    case 'ad14_selected_tracks_only':
      return 'projectWizard:milestoneIsSelectedTracksOnly.tooltip';
    case 'f0_name':
      return 'projectWizard:messageName.tooltip';
    case 'f1_purpose':
      return 'projectWizard:messagePurpose.tooltip';
    case 'f2_language':
      return 'projectWizard:messageLanguage.tooltip';
    case 'f3_subject':
      return 'projectWizard:messageSubject.tooltip';
    case 'f4_content':
      return 'projectWizard:messageContent.tooltip';
    case 'f5_html_content':
      return 'projectWizard:messageContentHTML.tooltip';
    default:
      return null;
  }
};

const FormFieldPreviewLabel = ({ value, expandQueryResult }) => {
  if (!value) {
    return null;
  }

  if (!expandQueryResult) {
    return value;
  }

  const { contains } = expandQueryResult.expansion;

  const res = find(contains, (item) => item.code === value);

  return res ? res.display : value;
};

const FormFieldPreviewContainer = compose(
  ddp({
    queries: (state, { valueSetId }) => ({
      expandQueryResult: valueSetId
        ? apiTerminologyExpandValueSet.withParams({
            id: valueSetId,
            // FIXME: Performance
            limit: 0,
          })
        : null,
    }),
    renderLoader: null,
  }),
)(({ input: { value }, expandQueryResult, label, tooltip }) => {
  return value ? (
    <FormFieldWrapper label={label} tooltip={tooltip}>
      <span className="break-words">
        <FormFieldPreviewLabel
          value={value}
          expandQueryResult={expandQueryResult}
        />
      </span>
    </FormFieldWrapper>
  ) : null;
});

const FormInput = ({
  question,
  valueSet,
  disabled,
  valueSetId,
  nameId,
  isEditing,
}) => {
  const { t } = useTranslation();

  const tooltip = t(getTooltip(question.id));
  if (!isEditing) {
    return (
      <Field
        component={FormFieldPreviewContainer}
        label={question.title}
        tooltip={tooltip}
        valueSetId={valueSetId}
        nameId={nameId}
      />
    );
  }
  switch (question.type) {
    case QUESTION_TYPE__EMPTY:
      return null;
    case QUESTION_TYPE__SELECT_ONE:
    case QUESTION_TYPE__SELECT_MANY: {
      let localValueSetId;
      if (valueSet && valueSet.identifier) {
        localValueSetId = [
          valueSet.identifier,
          valueSet.version || 'x.x.x',
        ].join('@');
      }
      return (
        <Field
          data-testid={`form-field-${question.getSlug()}`}
          component={FormFieldSearch}
          name="value"
          label={question.title}
          required={question.isRequired()}
          mode={question.type === QUESTION_TYPE__SELECT_MANY ? 'multiple' : ''}
          placeholder={!disabled ? question.getLabel() : null}
          options={question.getChoices(valueSet)}
          allowClear={question.isOptional()}
          disabled={disabled}
          valueSetId={localValueSetId}
          shouldWrapText
          tooltip={tooltip}
        />
      );
    }
    case QUESTION_TYPE__SHORT_TEXT:
    case QUESTION_TYPE__FREE_TEXT:
      return (
        <Field
          data-testid={`form-field-${question.getSlug()}`}
          component={FormFieldText}
          name="value"
          label={question.title}
          required={question.isRequired()}
          type={
            question.type === QUESTION_TYPE__FREE_TEXT ? 'freeText' : 'text'
          }
          placeholder={!disabled ? question.getLabel() : null}
          disabled={disabled}
          tooltip={tooltip}
        />
      );
    case QUESTION_TYPE__FORMULA:
      return (
        <Field
          data-testid={`form-field-${question.getSlug()}`}
          component={FormFieldText}
          name="value"
          label={question.title}
          required={question.isRequired()}
          type="text"
          placeholder={!disabled ? question.getLabel() : null}
          disabled
        />
      );
    case QUESTION_TYPE__TIME:
      return (
        <Field
          data-testid={`form-field-${question.getSlug()}`}
          component={FormFieldText}
          name="value"
          label={question.getTitle()}
          required={question.isRequired()}
          type="text"
          placeholder={!disabled ? question.getPlaceholder('value') : null}
          disabled={disabled}
        />
      );
    case QUESTION_TYPE__DATE:
      return (
        <Field
          data-testid={`form-field-${question.getSlug()}`}
          component={FormFieldDate}
          name="value"
          label={question.title}
          required={question.isRequired()}
          placeholder={!disabled ? question.getLabel() : null}
          disabled={disabled}
        />
      );
    case QUESTION_TYPE__SWITCH:
      return (
        <Field
          data-testid={`form-field-${question.getSlug()}`}
          component={FormFieldSwitch}
          name="value"
          label={question.title}
          required={question.isRequired()}
          disabled={disabled}
          tooltip={tooltip}
        />
      );
    case QUESTION_TYPE__NUMBER:
    case QUESTION_TYPE__NUMBER_WITH_UNIT:
      return (
        <Field
          data-testid={`form-field-${question.getSlug()}`}
          component={FormFieldNumber}
          name="value"
          label={question.title}
          required={question.isRequired()}
          disabled={disabled}
          min={question.getMinValue()}
          max={question.getMaxValue()}
          step={question.getValueStep()}
          precision={question.getPrecision()}
          tooltip={tooltip}
        />
      );
    default:
      return (
        <Field
          data-testid={`form-field-${question.getSlug()}`}
          component={FormFieldUnsupported}
          questionType={question.type}
        />
      );
  }
};

FormInput.propTypes = {
  question: PropTypes.instanceOf(Question).isRequired,
  valueSet: ValueSetPropTypes,
  disabled: PropTypes.bool,
  isEditing: PropTypes.bool,
};

FormInput.defaultProps = {
  valueSet: null,
  disabled: false,
  isEditing: false,
};

export default FormInput;
