import { checkSchema } from '@zedoc/check-schema';
import isNil from 'lodash/isNil';
import Formula from '../Formula';
import { FORMULA_TYPE__CONDITIONAL } from '../../../constants';

const settingsSchema = {
  type: 'object',
  required: ['if', 'then', 'else'],
  properties: {
    if: {
      type: 'object',
    },
    then: {
      type: 'object',
    },
    else: {
      type: 'object',
    },
  },
};

const isTruthy = (value) => !isNil(value) && value !== false;

class FormulaConditional extends Formula {
  validate() {
    if (!this.settings) {
      return this.constructor.NotConfigured;
    }
    if (checkSchema(settingsSchema, this.settings)) {
      return this.constructor.NotConfigured;
    }
    return undefined;
  }

  evaluate(scope) {
    const condition = Formula.create(this.settings.if).evaluate(scope);
    if (!condition.error && isTruthy(condition.value)) {
      return Formula.create(this.settings.then).evaluate(scope);
    }
    return Formula.create(this.settings.else).evaluate(scope);
  }

  compile(questionsHierarchy) {
    const compiled = {
      ...this,
      settings: {},
    };
    if (this.settings && this.settings.if) {
      compiled.settings.if = Formula.create(this.settings.if).compile(
        questionsHierarchy,
      );
    }
    if (this.settings && this.settings.then) {
      compiled.settings.then = Formula.create(this.settings.then).compile(
        questionsHierarchy,
      );
    }
    if (this.settings && this.settings.else) {
      compiled.settings.else = Formula.create(this.settings.else).compile(
        questionsHierarchy,
      );
    }
    return compiled;
  }

  static createMapSettings(mapQuestionId) {
    return (value, key) => {
      switch (key) {
        case 'if':
        case 'then':
        case 'else':
          return Formula.create(value).remap(mapQuestionId).toRawFormula();
        default:
          return value;
      }
    };
  }
}

Formula.types[FORMULA_TYPE__CONDITIONAL] = FormulaConditional;

export default FormulaConditional;
