import moment from 'moment';
import { useTranslation } from 'react-i18next';

const useDateTimeFormat = ({ date, options }) => {
  const { i18n } = useTranslation();
  // NOTE: Use "-GB" to force dd/mm/yyyy date styling
  const locale = i18n.language === 'en' ? 'en-GB' : i18n.language;

  if (moment(date).isValid()) {
    return new Intl.DateTimeFormat(locale, options).format(date);
  }

  return null;
};

export default useDateTimeFormat;
