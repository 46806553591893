import PropTypes from 'prop-types';
import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Markdown } from '@zedoc/react-markdown';
import { Helpdesk } from '../../../ui/icons';
import Dialog from '../../Dialog';
import NavigationItem from '../NavigationItem';

const NavigationHelpdeskModal = ({ items }) => {
  const { t, i18n } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);

  const handleOnOpen = () => setIsVisible(true);
  const handleOnCancel = () => setIsVisible(false);

  const content = useMemo(() => {
    const item = items.find(({ language }) => language === i18n.language);

    return item ? item.markdown : items[0]?.markdown;
  }, [items, i18n.language]);

  return (
    <>
      <NavigationItem
        testId="menu-item-helpdesk"
        label={t('helpdesk')}
        icon={<Helpdesk />}
        onClick={handleOnOpen}
      />
      <Dialog
        size="large"
        title={t('helpdesk')}
        visible={isVisible}
        onOk={handleOnCancel}
        onCancel={handleOnCancel}
      >
        <Markdown source={content} />
      </Dialog>
    </>
  );
};

NavigationHelpdeskModal.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      language: PropTypes.string.isRequired,
      markdown: PropTypes.string.isRequired,
    }),
  ),
};

NavigationHelpdeskModal.defaultProps = {
  items: [],
};

export default NavigationHelpdeskModal;
