import ApiSpec from '../ApiSpec';
import { PROJECT_REVIEW_PROJECT } from '../../permissions';
import Id from '../../schemata/Id';

// eslint-disable-next-line import/prefer-default-export
export const forProject = new ApiSpec({
  name: 'api.collections.ProjectDashboards.forProject',
  permissions: [PROJECT_REVIEW_PROJECT],
  schema: {
    type: 'object',
    required: ['projectId'],
    properties: {
      projectId: Id,
    },
  },
});
