/* eslint-disable no-underscore-dangle */
/* eslint global-require: "off" */
/* eslint import/first: "off" */
// TODO: Disabled it for the time being
// import 'focus-visible';
import { DDPClient } from '@zedoc/ddp-client';
import { DDPConnector } from '@zedoc/ddp-connector';
import { compose, createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'localforage';
import thunk from 'redux-thunk';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import { clearAllQuestionnaires } from '@zedoc/react-questionnaire';
import { decodeQuery } from '@zedoc/url';
import { resolveOnSelector } from '@zedoc/selectors';
import App from './containers/App';
import createReducer from './store/createReducer';
import { setDeferredPrompt } from './store/ui/drawer/actions';
import Loading from './common/components/Loading';
import { createMiddleware as createQuestionnaireMiddleware } from './store/questionnaire';
import resumeTokenKeepAlive from './store/resumeTokenKeepAlive';
import settings from './common/settings';
import { del } from './common/utilsClient/multiReducer';
import './common/logger/client/register';
import {
  cleanStorage,
  createAsyncStorage,
} from './common/utilsClient/redux/storage';
import transformMetaFields from './common/utilsClient/ddp/transformMetaFields';
import * as serviceWorker from './serviceWorker';
import './utils/i18next';
import './fonts';
import { tryLoginOnStartup } from './common/oauth/utils';
import './index.css';

const ddpClient = new DDPClient({
  endpoint: settings.public.wsEndpoint,
  SocketConstructor: WebSocket,
  autoConnect: false, // we will connect manually once storage is defined
  reconnectInterval: 1000,
  handleLoginResume: function handleLoginResume() {
    return this.storage
      .get(`${this.endpoint}__login_token__`)
      .then((resume) => {
        const promise = tryLoginOnStartup(this);
        if (promise) {
          return promise;
        }
        if (!resume) {
          // NOTE: It's important to emit loginError here, because we've already
          //       triggered loggingIn. So by triggering error as well we are
          //       indicating that the login procedure was actually terminated.
          this.emit('loginError', new Error('No login token'));
          return undefined;
        }
        return this.login({ resume }, { skipQueue: true });
      });
  },
});

const ddpConnector = new DDPConnector({
  // debug: true,
  ddpClient,
  defaultLoaderComponent: Loading,
  transformRequest: transformMetaFields,
});

let enhancer;
const query = decodeQuery(window.location.search);

if (query.debug) {
  enhancer = compose(
    window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__({})
      : (x) => x,
  );
} else if (process.env.NODE_ENV !== 'production') {
  enhancer = compose(
    window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__({})
      : (x) => x,
  );
} else {
  enhancer = compose();
}

const history = createBrowserHistory();
const enhanceReducer = (reducer) =>
  persistReducer(
    {
      storage,
      key: 'zedoc',
      whitelist: ['storage', 'preferences'],
    },
    reducer,
  );

const store = createStore(
  enhanceReducer(createReducer(history)),
  {},
  compose(
    applyMiddleware(
      routerMiddleware(history),
      thunk.withExtraArgument({
        ddpConnector,
      }),
      resumeTokenKeepAlive(),
      createQuestionnaireMiddleware(),
    ),
    enhancer,
  ),
);

const persistor = persistStore(store);
ddpClient.storage = createAsyncStorage(
  store,
  resolveOnSelector(persistor, 'bootstrapped'),
);
ddpClient.connect();

ddpClient.on('loggedOut', () => {
  store.dispatch(del('ui'));
  store.dispatch(cleanStorage());
  store.dispatch(clearAllQuestionnaires());
});

ddpConnector.bindToStore(store);

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
if (root) {
  root.render(
    <App
      store={store}
      history={history}
      persistor={persistor}
      ddpConnector={ddpConnector}
    />,
  );
}

if (process.env.NODE_ENV !== 'production') {
  if (typeof module !== 'undefined' && module.hot) {
    module.hot.accept('./containers/App', () => {
      const NextApp = require('./containers/App').default;
      if (root) {
        root.render(
          <NextApp
            store={store}
            history={history}
            persistor={persistor}
            ddpConnector={ddpConnector}
          />,
        );
      }
    });

    module.hot.accept('./store/createReducer', () =>
      store.replaceReducer(
        enhanceReducer(require('./store/createReducer').default(history)),
      ),
    );
  }
}

window.addEventListener('beforeinstallprompt', (e) => {
  e.preventDefault();

  // eslint-disable-next-line no-console
  console.log('The app can be installed');

  store.dispatch(setDeferredPrompt(e));
});

window.addEventListener('appinstalled', () => {
  store.dispatch(setDeferredPrompt(null));
});

// Temporarily disable Service Worker to prevent caching of API / PW routes.
// We will revisit this in 3.1 with some form of opt-out / opt-in.
// serviceWorker.register({
//   onUpdate: (registration) => {
//     if (registration.waiting) {
//       registration.waiting.addEventListener('statechange', (event) => {
//         if (event.target.state === 'activated') {
//           window.location.reload();
//         }
//       });

//       registration.waiting.postMessage({
//         type: 'SKIP_WAITING',
//       });
//     }
//   },
// });

// Unregister Service Worker programatically for existing deployments.
serviceWorker.unregister();
