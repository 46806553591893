import moment from 'moment';
import isNil from 'lodash/isNil';
import isNaN from 'lodash/isNaN';
import isEmpty from 'lodash/isEmpty';
import has from 'lodash/has';
import keyBy from 'lodash/keyBy';
import find from 'lodash/find';
import compact from 'lodash/compact';
import isPlainObject from 'lodash/isPlainObject';
import sample from 'lodash/sample';
import size from 'lodash/size';
import each from 'lodash/each';
import isArray from 'lodash/isArray';
import isEqual from 'lodash/isEqual';
import map from 'lodash/map';
import filter from 'lodash/filter';
import { slugify } from '@zedoc/text';
import {
  cleanValue,
  createCheckSchema,
  defaultRenderErrorMessage,
  getErrorMessage,
  getPatternExample,
} from '@zedoc/check-schema';
import { hasUtcOffset } from '@zedoc/date';
import { parseJsonOrNull } from '../../utils/variables';
import {
  QUESTION_TYPE__EMPTY,
  QUESTION_TYPE__SECTION,
  QUESTION_TYPE__MATRIX,
  QUESTION_TYPE__CAT_SECTION,
  QUESTION_TYPE__MEDIA,
  QUESTION_TYPE__COLLECTION,
  QUESTION_TYPE__UNKNOWN,
  QUESTION_TYPE__FORMULA,
  QUESTION_TYPE__DATE,
  QUESTION_TYPE__DATE_TIME,
  QUESTION_TYPE__TIME,
  QUESTION_TYPE__TIMESTAMP,
  QUESTION_TYPE__PARTIAL_DATE,
  QUESTION_TYPE__YEAR,
  QUESTION_TYPE__EMAIL,
  QUESTION_TYPE__PHONE,
  QUESTION_TYPE__SELECT_ONE,
  QUESTION_TYPE__SELECT_MANY,
  QUESTION_TYPE__SHORT_TEXT,
  QUESTION_TYPE__FREE_TEXT,
  QUESTION_TYPE__SCALE,
  QUESTION_TYPE__IFRAME,
  QUESTION_CHUNK_FIELDS,
  QUESTION_TYPE__SIGNATURE,
  NULL_ANSWER__NO_INFORMATION,
  RESPONSE_SOURCE__FORMULA,
  RESPONSE_SOURCE__USER_INPUT,
  EXPECTED_NON_EMPTY_ANSWER,
  EXPECTED_ONLY_KNOWN_KEYS,
} from '../../constants';
import {
  isNilValue,
  isEmptyAnswer,
  parseValueExpr,
  getFormattedScaleValue,
} from '../../utils/question';

const identity = (x) => x;

/**
 * Represents a Question.
 * @class
 */
class Question {
  constructor(doc) {
    if (doc) {
      Object.assign(this, doc);
    }
    this.settings = this.settings || {};
    Object.defineProperty(this, 'checkSchema', {
      value: createCheckSchema({
        renderErrorMessage: this.renderErrorMessage.bind(this),
      }),
    });
  }

  static create(doc) {
    let constructor = this.types[doc.type];
    if (!constructor) {
      constructor = this.types[QUESTION_TYPE__UNKNOWN];
    }
    return new constructor(doc);
  }

  static createUnknown(doc = {}) {
    return new this.types[QUESTION_TYPE__UNKNOWN](doc);
  }

  // eslint-disable-next-line class-methods-use-this
  renderErrorMessage(error) {
    if (typeof this.defaultRenderErrorMessage === 'function') {
      return this.defaultRenderErrorMessage(error);
    }
    if (error.type === EXPECTED_NON_EMPTY_ANSWER) {
      return 'Answer is required';
    }
    if (error.type === EXPECTED_ONLY_KNOWN_KEYS) {
      return `Key ${error.key} is not allowed`;
    }
    return defaultRenderErrorMessage(error);
  }

  shouldHideAndIgnoreAnswer() {
    return !(this.votesToHide === undefined || this.votesToHide <= 0);
  }

  canHaveExamples() {
    return (
      this.type === QUESTION_TYPE__SHORT_TEXT ||
      this.type === QUESTION_TYPE__FREE_TEXT
    );
  }

  isVisible() {
    return !this.isHidden();
  }

  isForInternalUsage() {
    return !!this.forInternalUsage;
  }

  isHidden() {
    return this.shouldHideAndIgnoreAnswer() || this.isForInternalUsage();
  }

  // @deprecated
  // eslint-disable-next-line class-methods-use-this
  isHiddenInNavigationUI() {
    // TODO: This is just a temporary helper, should me moved to discharge app
    // But now we also use this helper under `zedoc-patient-web` with the second condition
    // We need to find a way to assign a unique ID to every meta question
    return false;
  }

  getWearableCharacteristic() {
    return this.variableId;
  }

  getSlug() {
    const string = compact([this.path, this.title]).join('_');
    return slugify(string).replace(/-/g, '_');
  }

  getIcon() {
    // TODO: AHHHHH. We need to define icons in one place, let's say here and then somehow add this array to the meta icon question
    // Because otherwise I need to add same values to the question manually
    const icons = [
      'bed',
      'clothes',
      'cup',
      'walk',
      'shower',
      'floor',
      'faucet',
      'car',
      'jog',
      'activity',
      'sleep',
      'anxiety',
      'depression',
      'form',
      'bathing',
      'bath',
      'bed',
      'bladder',
      'bowel',
      'comprehension',
      'dressinglower',
      'dressingupper',
      'eating',
      'expression2',
      'expression',
      'grooming',
      'memory',
      'problemsolving',
      'socialinteraction',
      'stairs',
      'toileting',
      'walk',
      'wheelchair',
    ];

    return find(
      icons,
      (icon) =>
        icon === this.getMetadataKey('meta_icon') ||
        icon === this.getMetadataKey('MmZjcn7vuA6REYxj6'),
    );
  }

  getTitle() {
    return this.title;
  }

  getDescription() {
    return this.description;
  }

  useHoursMinutes() {
    return this.settings && this.settings.useHoursMinutes;
  }

  getLabel() {
    return this.label;
  }

  getPlaceholder(fieldName = 'value') {
    const schema = this.getExpectedValueType(fieldName);
    if (schema.format) {
      return getPatternExample(schema.format);
    }
    if (schema.pattern && this.settings && this.settings.patternExample) {
      return this.settings.patternExample;
    }
    return this.label;
  }

  getCaption() {
    return this.caption || this.label || this.title;
  }

  getLimitTo() {
    return this.settings && this.settings.limitTo;
  }

  isSection() {
    return (
      this.type === QUESTION_TYPE__SECTION ||
      this.type === QUESTION_TYPE__CAT_SECTION
    );
  }

  isMatrix() {
    return this.type === QUESTION_TYPE__MATRIX;
  }

  isComposite() {
    return (
      this.variableId &&
      (this.type === QUESTION_TYPE__SECTION ||
        this.type === QUESTION_TYPE__MATRIX ||
        this.type === QUESTION_TYPE__CAT_SECTION)
    );
  }

  isCollection() {
    return this.type === QUESTION_TYPE__COLLECTION;
  }

  shouldEvaluateValueSet() {
    return !!(this.settings && this.settings.evaluateValueSet);
  }

  isSelectOne() {
    return this.type === QUESTION_TYPE__SELECT_ONE;
  }

  isSelectMany() {
    return this.type === QUESTION_TYPE__SELECT_MANY;
  }

  isScale() {
    return this.type === QUESTION_TYPE__SCALE;
  }

  isDate() {
    return this.type === QUESTION_TYPE__DATE;
  }

  isDateTime() {
    return this.type === QUESTION_TYPE__DATE_TIME;
  }

  isPartialDate() {
    return this.type === QUESTION_TYPE__PARTIAL_DATE;
  }

  isTimestamp() {
    return this.type === QUESTION_TYPE__TIMESTAMP;
  }

  isTime() {
    return this.type === QUESTION_TYPE__TIME;
  }

  isYear() {
    return this.type === QUESTION_TYPE__YEAR;
  }

  isEmail() {
    return this.type === QUESTION_TYPE__EMAIL;
  }

  isPhone() {
    return this.type === QUESTION_TYPE__PHONE;
  }

  isIframe() {
    return this.type === QUESTION_TYPE__IFRAME;
  }

  isContainer() {
    return this.isSection() || this.isCollection() || this.isMatrix();
  }

  isRequired() {
    return !this.optional;
  }

  isEmpty() {
    return this.type === QUESTION_TYPE__EMPTY;
  }

  isMedia() {
    return this.type === QUESTION_TYPE__MEDIA;
  }

  shouldUseAutocompletion() {
    return (
      (this.type === QUESTION_TYPE__SHORT_TEXT ||
        this.type === QUESTION_TYPE__FREE_TEXT) &&
      this.settings &&
      this.settings.evaluateValueSet
    );
  }

  getRawKeys() {
    const rawKeys = this.constructor.expectedRawKeys;
    return filter(rawKeys, (key) => {
      switch (key) {
        case 'other':
          return this.useOther();
        case 'text1':
          return this.useText1();
        case 'text2':
          return this.useText2();
        default:
          return true;
      }
    });
  }

  getExpectedValueType(fieldName) {
    switch (fieldName) {
      // "other" is always expected to be the same type as "value"
      case 'value':
      case 'other':
        return this.constructor.expectedValueType;
      case 'error':
        return {
          type: 'object',
        };
      case 'text1':
      case 'text2':
        return {
          type: 'string',
        };
      default:
        return {};
    }
  }

  getValueError(value, valueType = this.getExpectedValueType()) {
    if (valueType) {
      // NOTE: Basically, nil or empty value means "no answer" so unless
      //       this is a required question, no error should be printed.
      if (isNil(value) || value === '') {
        return null;
      }
      if (isPlainObject(valueType)) {
        return this.checkSchema(valueType, value);
      }
    }
    return null;
  }

  getFieldError(name, value) {
    const allowedKeys = this.getRawKeys();
    if (allowedKeys.indexOf(name) < 0) {
      return {
        message: this.renderErrorMessage({
          type: EXPECTED_ONLY_KNOWN_KEYS,
          key: name,
        }),
      };
    }
    if (name === 'other') {
      return this.getValueError(value, this.getExpectedValueType(name));
    }
    if (name === 'value') {
      const error = this.getValueError(value, this.getExpectedValueType(name));
      if (error) {
        return error;
      }
      if (
        Question.isEmptyAnswer({
          value,
        }) &&
        !this.isOptional()
      ) {
        return {
          message: this.renderErrorMessage({ type: EXPECTED_NON_EMPTY_ANSWER }),
        };
      }
      return null;
    }
    return this.getValueError(value, this.getExpectedValueType(name));
  }

  getFieldErrorMessage(name, value) {
    const error = this.getFieldError(name, value);
    if (error) {
      return getErrorMessage(error);
    }
    return error;
  }

  getErrors(answer) {
    // NOTE: At the moment, we don't want errors for container questions.
    //       Later on, we may wish to fix it, e.g. if collection has "required"
    //       flag we can enforce it to have at least one element.
    if (this.isContainer()) {
      return null;
    }
    if (!this.isRealQuestion()) {
      // e.g. formula or iframe with iframeNoResponse flag
      return null;
    }
    const errors = {};
    each(this.getRawKeys(), (name) => {
      const error = this.getFieldErrorMessage(name, answer && answer[name]);
      if (error) {
        errors[name] = error;
      }
    });
    if (isEmpty(errors)) {
      return null;
    }
    return errors;
  }

  getCleanValue(value, valueType = this.getExpectedValueType()) {
    if (isPlainObject(valueType)) {
      return cleanValue(valueType, value);
    }
    return value;
  }

  getCleanField(name, value) {
    const allowedKeys = this.getRawKeys();
    if (allowedKeys.indexOf(name) < 0) {
      return value;
    }
    return this.getCleanValue(value, this.getExpectedValueType(name));
  }

  getCleanAnswer(answer) {
    const values = {};
    each(this.getRawKeys(), (name) => {
      const value = this.getCleanField(name, answer && answer[name]);
      if (value !== undefined) {
        values[name] = value;
      }
    });
    return values;
  }

  getRandomAnswer() {
    if (this.canHaveExamples()) {
      const example = sample(this.examples);
      if (example) {
        const value = parseJsonOrNull(example.value);
        if (!isNil(value)) {
          return {
            value,
          };
        }
      }
    }
    return null;
  }

  isOptional() {
    return !!this.optional;
  }

  isDisabled() {
    // NOTE: Disabled is not a property of published questionnaire, but it can be
    //       a dynamic property evaluated based on various conditions.
    return !!this.disabled;
  }

  shouldDisableInput() {
    return this.isDisabled() || !this.isRealQuestion();
  }

  getChoices(valueSet) {
    if (valueSet) {
      return map(
        valueSet.expansion && valueSet.expansion.contains,
        ({ code, display }) => ({
          value: code,
          label: display,
        }),
      );
    }
    if (!this.choices) {
      return [];
    }
    return this.choices.map(({ value, label }) => ({
      value,
      label: label || value,
    }));
  }

  getScaleLabels() {
    let minValue = this.getMinValue();
    const scaleLabels = [];
    let queue = [];
    const emptyQueue = (maxValue) => {
      each(queue, (label, index) => {
        const value =
          minValue +
          Math.round(
            ((index + 1) * (maxValue - minValue)) / (queue.length + 1),
          );
        scaleLabels.push({
          value,
          label:
            label.label ||
            (this.shouldShowValueAndLabel() ? undefined : value.toString()),
        });
      });
      queue = [];
    };
    const nLabels = size(this.scaleLabels);
    each(this.scaleLabels, (label, index) => {
      let value = parseInt(label.value, 10);
      if (isNaN(value)) {
        if (index === 0) {
          value = this.getMinValue();
        } else if (index === nLabels - 1) {
          value = this.getMaxValue();
        }
      }
      if (isNaN(value)) {
        queue.push(label);
      } else {
        emptyQueue(value);
        scaleLabels.push({
          label:
            label.label ||
            (this.shouldShowValueAndLabel() ? undefined : value.toString()),
          value,
        });
        minValue = value;
      }
    });
    return scaleLabels;
  }

  shouldHideScaleLabels() {
    return !!(this.settings && this.settings.hideScaleLabels);
  }

  shouldShowClosestScaleLabel() {
    return !!(this.settings && this.settings.showClosestScaleLabel);
  }

  getMaxValue() {
    const defaultValue = this.isScale() ? 100 : null;
    if (has(this.settings, 'maxValue')) {
      return this.settings.maxValue;
    }
    return defaultValue;
  }

  getMinValue() {
    const defaultValue = this.isScale() ? 0 : null;
    if (has(this.settings, 'minValue')) {
      return this.settings.minValue;
    }
    return defaultValue;
  }

  getIrtConfigFormula() {
    return this.settings && this.settings.irtConfigFormula;
  }

  getStandardErrorThreshold() {
    return this.settings && this.settings.standardErrorThreshold;
  }

  getCATMaxItems() {
    return this.settings && this.settings.catMaxItems;
  }

  getValueStep() {
    return this.settings && this.settings.valueStep;
  }

  getPrecision() {
    return this.settings && this.settings.precision;
  }

  getIframeParams(formValues) {
    if (!this.isIframe()) {
      return {};
    }
    const { iframeUrl } = this.settings;
    const { iframeUseQueryParams } = this.settings;
    const { iframeUseQuestions } = this.settings;
    const params = {
      url: iframeUrl,
    };
    if (iframeUseQueryParams) {
      const query = {};
      each(iframeUseQuestions, (questionId) => {
        const answer = formValues[questionId];
        if (!isEmptyAnswer(answer)) {
          query[questionId] = answer.value;
        }
      });
      if (!isEmpty(query)) {
        params.query = query;
      }
    }
    return params;
  }

  getAppearance() {
    return this.settings && this.settings.appearance;
  }

  getMediaType() {
    return this.settings && this.settings.mediaType;
  }

  getMediaUrl() {
    return this.settings && this.settings.mediaUrl;
  }

  getBluetoothServices() {
    const questionMappings = this.getBluetoothQuestionMappings();
    if (questionMappings) {
      return questionMappings.map(({ service }) => service);
    }
    return [];
  }

  getBluetoothQuestionMappings() {
    return this.settings && this.settings.bluetoothQuestionMappings;
  }

  useText1() {
    if (this.type === QUESTION_TYPE__SIGNATURE) {
      return true;
    }

    return !!(this.settings.textField1 && this.settings.textField1.use);
  }

  useText2() {
    if (this.type === QUESTION_TYPE__SIGNATURE) {
      return true;
    }

    return !!(this.settings.textField2 && this.settings.textField2.use);
  }

  useOther() {
    return !!(this.settings.otherField && this.settings.otherField.use);
  }

  useVerticalLayout() {
    return !!this.settings.verticalLayout;
  }

  shouldShowValueAndLabel() {
    return !!this.settings.showValueAndLabel;
  }

  useRandomisedResponsesOrder() {
    return !!this.settings.randomisedResponsesOrder;
  }

  getIsNewPresentation() {
    return !!(this.settings && this.settings.isNewPresentation);
  }

  shouldHideAndIgnoreChunk(id) {
    return (
      this.votesToHideChunk !== undefined &&
      this.votesToHideChunk[id] !== undefined &&
      this.votesToHideChunk[id] > 0
    );
  }

  shouldDisplayChunk(id) {
    return !this.shouldHideAndIgnoreChunk(id);
  }

  removeHiddenChunksFromAnswer(answer) {
    let newAnswer = answer;
    each(QUESTION_CHUNK_FIELDS, ({ id, name }) => {
      if (newAnswer[name] !== undefined && this.shouldHideAndIgnoreChunk(id)) {
        newAnswer = {
          ...newAnswer,
        };
        delete newAnswer[name];
      }
    });
    return newAnswer;
  }

  showAsSearchInput() {
    return !!this.settings.asSearchInput;
  }

  otherFieldTitle() {
    if (this.useOther()) {
      return this.settings.otherField.title || 'Other';
    }
    return '';
  }

  getNumberOfChoices() {
    if (!this.choices) {
      return 0;
    }
    return this.choices.length;
  }

  hasMetadataKey(id) {
    return (
      this.metadata &&
      this.metadata.formValues &&
      has(this.metadata.formValues, id)
    );
  }

  getMetadataKey(id) {
    return (
      this.metadata &&
      this.metadata.formValues &&
      this.metadata.formValues[id] &&
      this.metadata.formValues[id].value
    );
  }

  getMetadataAsFormValues() {
    if (this.metadata && this.metadata.formValues) {
      return this.metadata.formValues;
    }
    return {};
  }

  shouldParseDate() {
    return (
      this.type === QUESTION_TYPE__TIMESTAMP ||
      this.type === QUESTION_TYPE__DATE_TIME ||
      this.type === QUESTION_TYPE__FORMULA
    ); // formula only sometimes produces a date, but it's worth checking
  }

  shouldUseCustomNumericValue() {
    return !!(this.settings && this.settings.useCustomNumericValue);
  }

  toNumericValue(value) {
    if (this.shouldUseCustomNumericValue()) {
      const choice = find(this.choices, {
        value,
      });
      if (choice && !isEmpty(choice.valueExpr)) {
        return parseValueExpr(choice.valueExpr);
      }
    }
    return parseValueExpr(value);
  }

  /**
   * Coverts a raw answer object (e.g. obtained directly from form data), and coverts it into
   * a complete response object by adding necessary metadata. By convention, we assume that
   * if answer is provided, it contains "value" key - otherwise it's considered an empty answer,
   * even though it may contain other non-empty fields.
   *
   * @param {Object} answer
   * @param {Object} options
   * @param {String} options.source
   * @param {String} options.whyEmpty
   * @param {Date} options.savedAt
   * @param {String} options.savedBy
   * @param {Object} options.hierarchy
   * @returns {Object} response
   */
  createResponse(
    answer,
    {
      source,
      whyEmpty = NULL_ANSWER__NO_INFORMATION,
      editedTs,
      savedAt = new Date(),
      hierarchyKey,
    } = {},
  ) {
    if (typeof answer !== 'object' && !isNil(answer)) {
      throw new Error(`Answer must be an object or null, got ${typeof answer}`);
    }
    const response = {
      questionId: this.id,
      questionType: this.type,
      meta: {},
    };
    if (source) {
      response.source = source;
    } else if (this.isFormula()) {
      response.source = RESPONSE_SOURCE__FORMULA;
    } else {
      response.source = RESPONSE_SOURCE__USER_INPUT;
    }
    if (editedTs) {
      response.editedTs = editedTs;
    }
    if (savedAt) {
      response.savedAt = savedAt;
    }
    if (hierarchyKey) {
      response.hierarchyKey = hierarchyKey;
    }
    if (answer) {
      response.answer = this.getCleanAnswer(answer);
      if (!isEqual(answer, response.answer)) {
        response.original = answer;
      }
    }
    const answerIsEmpty = this.constructor.isEmptyAnswer(response.answer);
    if (answerIsEmpty) {
      // NOTE: It would probably not be a good idea to delete response.answer,
      //       because even if it's answer.value is "empty", the answer object
      //       may still contain other fields, e.g. text1, text2
      response.whyEmpty = whyEmpty;
    }
    if (response.answer) {
      const errors = this.getErrors(response.answer);
      if (errors) {
        response.errors = [];
        each(errors, (message, name) => {
          response.errors.push({
            name,
            message,
          });
        });
      }
    }
    if (
      response.answer &&
      (typeof response.answer.value === 'string' ||
        typeof response.answer.value === 'number')
    ) {
      const valueNumber = this.toNumericValue(response.answer.value);
      if (!isNaN(valueNumber)) {
        response.meta.number = valueNumber;
      }
    }
    if (response.answer && this.shouldParseDate()) {
      const parsed = moment.parseZone(response.answer.value, moment.ISO_8601);
      if (parsed.isValid()) {
        // NOTE: Even if there's a utc offset information, we still want
        //       to store the date in utc. If in addition to that we also
        //       see that the string contains utc offset token, we also
        //       store utc offset in the meta object.
        response.meta.date = parsed.clone().utcOffset(0).toDate();
        if (hasUtcOffset(response.answer.value)) {
          response.meta.utcOffset = parsed.utcOffset();
        }
      }
    }
    if (!answerIsEmpty) {
      if (this.isSelectOne() || this.isSelectMany()) {
        const choices = keyBy(this.getChoices(), 'value');
        if (isArray(response.answer.value)) {
          response.meta.label = map(
            response.answer.value,
            (value) => choices[value] && choices[value].label,
          );
        } else {
          response.meta.label =
            choices[response.answer.value] &&
            choices[response.answer.value].label;
        }
      }
    }
    if (isEmpty(response.meta)) {
      delete response.meta;
    }
    return response;
  }

  getTemplateName() {
    const name = `questionnaire_question_${this.type}`;
    if (global.Template !== 'undefined' && global.Template[name]) {
      return name;
    }
    return 'questionnaire_question_unknown';
  }

  isFinalComputation() {
    return (
      this.nonFormBuilderSettings &&
      this.nonFormBuilderSettings.finalComputation
    );
  }

  isFormula() {
    return this.type === QUESTION_TYPE__FORMULA;
  }

  hasPresentableValue() {
    return this.isRealQuestion() || this.isFormula();
  }

  /**
   * Returns true if this a question for which we are expecting an answer, so not "section", no "empty" etc.
   */
  isRealQuestion() {
    return (
      this.type !== QUESTION_TYPE__SECTION &&
      this.type !== QUESTION_TYPE__MATRIX &&
      this.type !== QUESTION_TYPE__CAT_SECTION &&
      this.type !== QUESTION_TYPE__EMPTY &&
      this.type !== QUESTION_TYPE__MEDIA &&
      this.type !== QUESTION_TYPE__FORMULA &&
      !(
        this.type === QUESTION_TYPE__IFRAME &&
        this.settings &&
        this.settings.iframeNoResponse
      )
    );
  }

  shouldUseBoundVariableForInitialValue() {
    return !this.noAutoValue && !!this.variableId;
  }

  shouldUseFormulaForInitialValue() {
    return !!this.initialValue;
  }

  hasInitialValue() {
    if (!this.isRealQuestion()) {
      return false;
    }
    return (
      this.shouldUseBoundVariableForInitialValue() ||
      this.shouldUseFormulaForInitialValue()
    );
  }

  hasFormulaToEvaluate() {
    return !!(this.settings && this.settings.formula);
  }

  getNumberWithUnitValue(values) {
    let total = 0;
    let ratio = 1;
    this.getChoices().forEach(({ value }, index) => {
      const number = parseInt(value, 10) || 1;
      const amount = values[`field_${index}`] || 0; // NaN is treated as 0
      total += amount * ratio * number;
      ratio *= number;
    });
    return total;
  }

  getNumberWithUnitValueParts(value) {
    const v = [];
    let total = value;
    const options = this.getChoices();
    const ratios = options.map((el) => parseInt(el.value, 10));
    options.forEach((el, index) => {
      if (index + 1 < options.length) {
        const reminder = total % ratios[index + 1];
        v[`field_${index}`] = reminder;
        total -= reminder;
        total /= ratios[index + 1];
      } else {
        v[`field_${index}`] = total;
      }
    });
    return v;
  }

  getValueLabel(value) {
    const choice = find(this.choices, {
      value,
    });
    if (choice) {
      return choice.label || choice.value;
    }
    return null;
  }

  formatChoice(value, renderMarkdown = identity, forInternalUse, question) {
    if (isNil(value)) {
      return null;
    }
    const label = this.getValueLabel(value);
    if (label !== value) {
      if (forInternalUse || (question && question.shouldShowValueAndLabel())) {
        return renderMarkdown(`${value.toString()} - ${label}`);
      }

      return renderMarkdown(label);
    }
    return renderMarkdown(value.toString());
  }

  getAnswerLabel(answer) {
    if (isEmptyAnswer(answer)) {
      return null;
    }
    switch (this.type) {
      case QUESTION_TYPE__SELECT_MANY:
        return map(answer.value, (value) => this.getValueLabel(value));
      case QUESTION_TYPE__SELECT_ONE:
        return this.getValueLabel(answer.value);
      default:
        return null;
    }
  }

  /**
   * @param {object} answer
   * @param {object} [options]
   * @param {boolean} [options.forInternalUse]
   * @param {(markdown: string) => string} [options.renderMarkdown]
   * @returns {string | null}
   */
  formatAnswer(answer, options = {}) {
    const { renderMarkdown = identity, forInternalUse } = options;
    if (isEmptyAnswer(answer)) {
      return null;
    }
    switch (this.type) {
      case QUESTION_TYPE__SELECT_MANY:
        return map(answer.value, (value) =>
          this.formatChoice(value, renderMarkdown, forInternalUse, this),
        ).join(', ');
      case QUESTION_TYPE__SELECT_ONE:
        return this.formatChoice(
          answer.value,
          renderMarkdown,
          forInternalUse,
          this,
        );
      case QUESTION_TYPE__SCALE:
        return getFormattedScaleValue({
          forInternalUse,
          question: this,
          value: answer.value,
        });
      case QUESTION_TYPE__SIGNATURE:
        return answer.text1;
      case QUESTION_TYPE__COLLECTION:
        return null;
      default:
        return isNil(answer.value) ? null : answer.value.toString();
    }
  }

  getPlaybackTime() {
    if (has(this.settings, 'playbackTimeMinutes')) {
      return this.settings.playbackTimeMinutes;
    }
    return 0;
  }
}

Question.expectedValueType = {};
Question.expectedRawKeys = ['value'];
Question.types = {};
Question.isNilValue = isNilValue;
Question.isEmptyAnswer = isEmptyAnswer;

export default Question;
