import ApiSpec from '../ApiSpec';
import { ACTION_TYPE__REVIEW } from '../../messages';
import Id from '../../schemata/Id';
import {
  PATIENT_MILESTONE_REVIEW_ANSWERS_SHEET,
  PATIENT_MILESTONE_ANNOTATE_ANSWERS_SHEET,
  PATIENT_MILESTONE_UPDATE_ANSWERS_SHEET,
} from '../../permissions';

// SUBSCRIPTIONS

export const one = new ApiSpec({
  name: 'api.collections.AnswersSheets.one',
  actionType: ACTION_TYPE__REVIEW,
  permissions: [PATIENT_MILESTONE_REVIEW_ANSWERS_SHEET],
  schema: Id,
});

export const amendmentHistory = new ApiSpec({
  name: 'api.collections.AnswersSheets.amendmentHistory',
  actionType: ACTION_TYPE__REVIEW,
  permissions: [PATIENT_MILESTONE_REVIEW_ANSWERS_SHEET],
  schema: {
    type: 'object',
    properties: {
      answersSheetId: {
        type: 'string',
      },
    },
  },
});

// MUTATIONS/ QUERIES

export const reassignOne = new ApiSpec({
  name: 'api.collections.AnswersSheets.reassignOne',
  permissions: [PATIENT_MILESTONE_UPDATE_ANSWERS_SHEET],
  schema: {
    type: 'object',
    required: ['answersSheetId'],
    properties: {
      answersSheetId: Id,
      noteContent: {
        type: 'string',
        minLength: 1,
      },
    },
  },
});

export const clearOne = new ApiSpec({
  name: 'api.collections.AnswersSheets.clearOne',
  permissions: [PATIENT_MILESTONE_UPDATE_ANSWERS_SHEET],
  schema: {
    type: 'object',
    required: ['answersSheetId', 'noteContent'],
    properties: {
      answersSheetId: Id,
      noteContent: {
        type: 'string',
        minLength: 1,
      },
    },
  },
});

export const insertNote = new ApiSpec({
  name: 'api.collections.AnswersSheets.insertNote',
  permissions: [PATIENT_MILESTONE_ANNOTATE_ANSWERS_SHEET],
  schema: {
    type: 'object',
    required: ['answersSheetId'],
    properties: {
      answersSheetId: Id,
      noteContent: {
        type: 'string',
        minLength: 1,
      },
      noteType: {
        type: 'string',
      },
    },
  },
});

export const updateNote = new ApiSpec({
  name: 'api.collections.AnswersSheets.updateNote',
  permissions: [PATIENT_MILESTONE_ANNOTATE_ANSWERS_SHEET],
  schema: {
    type: 'object',
    required: ['answersSheetId', 'id', 'noteContent'],
    properties: {
      answersSheetId: Id,
      id: Id,
      noteContent: {
        type: 'string',
        minLength: 1,
      },
    },
  },
});

export const generatePdf = new ApiSpec({
  name: 'api.collections.AnswersSheets.generatePdf',
  permissions: [PATIENT_MILESTONE_REVIEW_ANSWERS_SHEET],
  schema: {
    type: 'object',
    required: ['answersSheetId'],
    properties: {
      answersSheetId: Id,
    },
  },
});
