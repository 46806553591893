import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import { useDDPSubscription } from '@zedoc/ddp-connector';
import { useSelector } from 'react-redux';
import { default as ProjectDashboardSelect } from '../../../../common/selectors/ProjectDashboard';
import { apiAnalyticsDataProvidersForDashboard } from '../../../../common/api/analytics';
import Grid from '../../../../common/components/Chart/Grid';
import ChartCard from '../../../../components/plates/ChartCard';
import {
  FILTER_CONDITION__INCLUDE,
  FILTER_TYPE__PROPERTY,
} from '../../../../common/constants';
import cleanFilters from '../../../../utils/cleanFilters';
import Chart from './Chart';
import { createGetFilters } from './store';

const AnalyticsCharts = ({
  dashboardId,
  recipientId,
  projectId,
  numberOfCharts,
  onChangeSubscription,
  noPopulation,
}) => {
  const rawFilters = useSelector(
    createGetFilters(`dashboards.${dashboardId}.filters`),
  );
  const filters = useMemo(
    () => cleanFilters(rawFilters, { anyOf: true }),
    [rawFilters],
  );
  const { id: subscriptionId, ready: subscriptionReady } = useDDPSubscription(
    noPopulation
      ? null
      : apiAnalyticsDataProvidersForDashboard.withParams({
          projectId,
          dashboardId,
          controlId: '$meta.id',
          filters,
        }),
  );
  const { id: recipientSubscriptionId, ready: recipientSubscriptionReady } =
    useDDPSubscription(
      recipientId &&
        apiAnalyticsDataProvidersForDashboard.withParams({
          projectId,
          dashboardId,
          controlId: '$meta.id',
          filters: [
            {
              type: FILTER_TYPE__PROPERTY,
              condition: FILTER_CONDITION__INCLUDE,
              settings: {
                id: 'recipientId',
              },
              state: {
                include: [recipientId],
              },
            },
          ],
        }),
    );
  const dashboard = useSelector(
    ProjectDashboardSelect.one().whereIdEquals(dashboardId),
  );
  useEffect(() => {
    if (onChangeSubscription) {
      onChangeSubscription(subscriptionId);
    }
  }, [onChangeSubscription, subscriptionId]);
  return (
    <Grid>
      {map(dashboard && dashboard.charts, (chart, idx) => {
        if (numberOfCharts && idx >= numberOfCharts) {
          return null;
        }

        const {
          type,
          title,
          description,
          settings,
          detailedViewTransformation,
        } = chart;
        const chartId = chart.id;
        return (
          <ChartCard
            key={chartId}
            title={title}
            description={description}
            loading={!(subscriptionReady && recipientSubscriptionReady)}
          >
            <Chart
              type={type}
              settings={settings}
              chartDataProviderId={`${apiAnalyticsDataProvidersForDashboard.getName()}.${
                noPopulation ? recipientSubscriptionId : subscriptionId
              }.${chartId}`}
              recipientChartDataProviderId={
                noPopulation
                  ? null
                  : recipientSubscriptionId &&
                    `${apiAnalyticsDataProvidersForDashboard.getName()}.${recipientSubscriptionId}.${chartId}`
              }
              detailedViewTransformation={detailedViewTransformation}
            />
          </ChartCard>
        );
      })}
    </Grid>
  );
};

AnalyticsCharts.propTypes = {
  projectId: PropTypes.string.isRequired,
  dashboardId: PropTypes.string.isRequired,
  recipientId: PropTypes.string,
  numberOfCharts: PropTypes.number,
  onChangeSubscription: PropTypes.func,
  noPopulation: PropTypes.bool,
};

AnalyticsCharts.defaultProps = {
  recipientId: null,
  numberOfCharts: null,
  onChangeSubscription: null,
  noPopulation: false,
};

export default AnalyticsCharts;
