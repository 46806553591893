import { LinkOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import copyToClipboard from '../../utilsClient/copyToClipboard';
import message from '../../utilsClient/message';
import Button from '../Button';

const Clipboard = ({ text }) => {
  const { t } = useTranslation();

  const onCopyToClipboard = () =>
    copyToClipboard(text, () => message.success('Copied to clipboard!'));

  return (
    <Button icon={<LinkOutlined />} onClick={onCopyToClipboard}>
      {t('copy')}
    </Button>
  );
};

Clipboard.propTypes = {
  text: PropTypes.string.isRequired,
};

export default Clipboard;
