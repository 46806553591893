import { compose, withProps } from 'recompose';
import { createSelector, createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { ddp } from '@zedoc/ddp-connector';
import SignedNote from '../../../../common/models/SignedNote';
import { one as oneAnswersSheet } from '../../../../common/api/collections/AnswersSheets';
import { getUserNames } from '../../../../common/api/aggregations/Projects';

import NotesModal from './NotesModal';
import {
  getActiveAnswersSheetId,
  getAnswersSheetDialogVisible,
  getActiveAnswersSheet,
  getActiveEAPP,
  getEAPPDialogVisible,
} from '../../selectors';
import {
  closeAnswersSheetDialog,
  closeEAPPDialog,
  openEditNoteDialog,
} from '../../actions';

const getNotes = (answersSheet, eapp) => {
  if (answersSheet) {
    return answersSheet.getSignedNotes();
  }

  if (eapp && eapp.notes) {
    return eapp.notes.map((note) => new SignedNote(note));
  }

  return [];
};

const getOnClose = (dispatch, answersSheet, eapp) => {
  if (answersSheet) {
    return dispatch(closeAnswersSheetDialog());
  }

  if (eapp) {
    return dispatch(closeEAPPDialog());
  }

  return [];
};

export default compose(
  ddp({
    subscriptions: (state) => {
      const answersSheetId = getActiveAnswersSheetId(state);
      return [answersSheetId && oneAnswersSheet.withParams(answersSheetId)];
    },
    queries: (state, { projectId }) => ({
      userNames:
        projectId &&
        getUserNames.withParams({
          projectId,
        }),
    }),
    renderLoader: null,
  }),
  connect(
    createStructuredSelector({
      open: createSelector(
        getAnswersSheetDialogVisible,
        getEAPPDialogVisible,
        (answersSheetDialogVisible, eappDialogVisible) =>
          answersSheetDialogVisible || eappDialogVisible,
      ),
      answersSheet: getActiveAnswersSheet,
      eapp: getActiveEAPP,
    }),
  ),
  withProps(({ dispatch, answersSheet, eapp }) => ({
    notes: getNotes(answersSheet, eapp),
    onClose: () => getOnClose(dispatch, answersSheet, eapp),
    onAddNote: () => dispatch(openEditNoteDialog({})),
  })),
)(NotesModal);
