import isEmpty from 'lodash/isEmpty';
import isPlainObject from 'lodash/isPlainObject';
import forEach from 'lodash/forEach';
import isObject from 'lodash/isObject';
import {
  EXPECTED_VALUE_TO_EXIST,
  createCheckSchema,
  defaultRenderErrorMessage,
} from '@zedoc/check-schema';

/**
 * @param {ErrorDescriptor} error
 * @returns {ErrorMessage | undefined}
 */
function collapseErrors(error) {
  if (!error) {
    return undefined;
  }
  if (error.message) {
    return error.message;
  }
  if (!isEmpty(error.errors)) {
    /** @type {ErrorMessageDictionary} */
    const errors = {};
    forEach(error.errors, (err, key) => {
      const errorAtKey = collapseErrors(err);
      if (errorAtKey) {
        errors[key] = errorAtKey;
      }
    });
    return errors;
  }
  return undefined;
}

const checkFormValueSchema = createCheckSchema({
  missingRequiredAsNestedErrors: true,
  renderErrorMessage: (error) => {
    if (error.type === EXPECTED_VALUE_TO_EXIST) {
      const title = error.schema?.title;
      if (title) {
        return `${title} is required`;
      }
    }
    return defaultRenderErrorMessage(error);
  },
});

/**
 * @template {import('json-schema-to-ts').JSONSchema7} T
 * @param {T} jsonSchema
 * @returns {(value: unknown) => void}
 */
export const makeJsonSchemaFormValidator = (jsonSchema) => {
  if (!isObject(jsonSchema)) {
    return () => {};
  }
  return function validate(value) {
    if (!isObject(value) || !isPlainObject(value)) {
      return undefined;
    }
    const cleanedValue = /** @type {Record<string, undefined>} */ (value);
    return collapseErrors(checkFormValueSchema(jsonSchema, cleanedValue));
  };
};
