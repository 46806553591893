import { TrashIcon } from '@heroicons/react/outline';
import { DDPContext } from '@zedoc/ddp-connector';
import { useStore } from 'react-redux';
import keyBy from 'lodash/keyBy';
import forEach from 'lodash/forEach';
import isNil from 'lodash/isNil';
import isPlainObject from 'lodash/isPlainObject';
import map from 'lodash/map';
import has from 'lodash/has';
import isArray from 'lodash/isArray';
import mapValues from 'lodash/mapValues';
import trim from 'lodash/trim';
import isEmpty from 'lodash/isEmpty';
import React, { useMemo, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { isValidDateString } from '@zedoc/check-schema';
import { useTranslation } from 'react-i18next';
import InputNumber from '../../../common/components/InputNumber';
import InputDate from '../../../common/components/InputDate';
import Input from '../../Input';
import Button from '../../Button';
import InputGroup from '../../InputGroup';
import {
  FILTER_CONDITION__TEXT,
  FILTER_CONDITION__SEARCH_TERMS,
  FILTER_CONDITION__INCLUDE,
  FILTER_CONDITION__EXCLUDE,
  FILTER_CONDITION__EXISTS,
  FILTER_CONDITION__DOES_NOT_EXIST,
  FILTER_CONDITION__DATE_EQUALS,
  FILTER_CONDITION__DATE_NOT_EQUAL,
  FILTER_CONDITION__DATE_SAME_OR_BEFORE,
  FILTER_CONDITION__DATE_BEFORE,
  FILTER_CONDITION__DATE_SAME_OR_AFTER,
  FILTER_CONDITION__DATE_AFTER,
  FILTER_CONDITION__EQUALS,
  FILTER_CONDITION__NOT_EQUAL,
  FILTER_CONDITION__MINIMUM,
  FILTER_CONDITION__EXCLUSIVE_MINIMUM,
  FILTER_CONDITION__MAXIMUM,
  FILTER_CONDITION__EXCLUSIVE_MAXIMUM,
  FILTER_CONDITION__EMPTY,
  FILTER_CONDITION__NON_EMPTY,
  filterConditionNeedsValue,
} from '../../../common/constants';
import { toChoice, isChoices } from '../../../utils/jsonSchema';
import { getItemsSelector } from '../../../utils/usePagination';
import FormItem from '../../forms/FormItem';
import Select from '../../Select';
import AsyncSelect from '../../Select/AsyncSelect';
import useDelay from '../useDelay';

const toString = (value) => {
  if (value === true) {
    return 'YES';
  }
  if (value === false) {
    return 'NO';
  }
  if (isNil(value)) {
    return 'NULL';
  }
  return value.toString();
};

const Filter = ({
  id,
  name,
  type,
  condition,
  meta,
  state,
  settings,
  active,
  onDelete,
  onChange,
  onSubmit,
  optionsDisplayCounts,
  optionsSelector,
  optionsSubscription,
  segmentOptions,
  segmentValue,
  onSelectSegment,
  submitFailed,
}) => {
  const { t } = useTranslation();

  let value;
  switch (condition) {
    case FILTER_CONDITION__TEXT:
    case FILTER_CONDITION__SEARCH_TERMS:
      value = state && state.text;
      break;
    case FILTER_CONDITION__INCLUDE:
      value = state && state.include;
      break;
    case FILTER_CONDITION__EXCLUDE:
      value = state && state.exclude;
      break;
    case FILTER_CONDITION__DATE_EQUALS:
    case FILTER_CONDITION__DATE_NOT_EQUAL:
    case FILTER_CONDITION__DATE_SAME_OR_BEFORE:
    case FILTER_CONDITION__DATE_BEFORE:
    case FILTER_CONDITION__DATE_SAME_OR_AFTER:
    case FILTER_CONDITION__DATE_AFTER:
    case FILTER_CONDITION__EQUALS:
    case FILTER_CONDITION__NOT_EQUAL:
    case FILTER_CONDITION__MINIMUM:
    case FILTER_CONDITION__EXCLUSIVE_MINIMUM:
    case FILTER_CONDITION__MAXIMUM:
    case FILTER_CONDITION__EXCLUSIVE_MAXIMUM:
      value = state && state.threshold;
      break;
    default:
      value = null;
  }
  let display;
  switch (condition) {
    case FILTER_CONDITION__TEXT:
    case FILTER_CONDITION__SEARCH_TERMS:
      display = value;
      break;
    case FILTER_CONDITION__DATE_EQUALS:
    case FILTER_CONDITION__DATE_NOT_EQUAL:
    case FILTER_CONDITION__DATE_SAME_OR_BEFORE:
    case FILTER_CONDITION__DATE_BEFORE:
    case FILTER_CONDITION__DATE_SAME_OR_AFTER:
    case FILTER_CONDITION__DATE_AFTER:
      display = value ? new Date(value).toLocaleDateString() : 'N/A';
      break;
    case FILTER_CONDITION__EQUALS:
    case FILTER_CONDITION__NOT_EQUAL:
    case FILTER_CONDITION__MINIMUM:
    case FILTER_CONDITION__EXCLUSIVE_MINIMUM:
    case FILTER_CONDITION__MAXIMUM:
    case FILTER_CONDITION__EXCLUSIVE_MAXIMUM:
      display = JSON.stringify(value);
      break;
    case FILTER_CONDITION__EXCLUDE:
    case FILTER_CONDITION__INCLUDE:
      // eslint-disable-next-line no-unused-vars
      display = map(value, (code) => {
        if (meta && meta.labels) {
          return meta.labels[code] || code;
        }
        return code;
      }).join(', ');
      break;
    default:
    // ...
  }
  const valueType = settings && settings.valueType;
  const handleOnChange = useCallback(
    (
      newValue,
      { newCondition = condition, newLabels = meta && meta.labels } = {},
    ) => {
      switch (newCondition) {
        case FILTER_CONDITION__TEXT:
        case FILTER_CONDITION__SEARCH_TERMS:
          onChange(id, {
            condition: newCondition,
            state: {
              ...state,
              text: typeof newValue === 'string' ? newValue : '',
            },
          });
          break;
        case FILTER_CONDITION__EQUALS:
        case FILTER_CONDITION__NOT_EQUAL:
        case FILTER_CONDITION__MINIMUM:
        case FILTER_CONDITION__EXCLUSIVE_MINIMUM:
        case FILTER_CONDITION__MAXIMUM:
        case FILTER_CONDITION__EXCLUSIVE_MAXIMUM: {
          let threshold;
          switch (valueType) {
            case 'string': {
              threshold = typeof newValue === 'string' ? newValue : '';
              break;
            }
            default: {
              threshold = typeof newValue === 'number' ? newValue : '';
              break;
            }
          }
          onChange(id, {
            condition: newCondition,
            state: {
              ...state,
              threshold,
            },
          });
          break;
        }
        case FILTER_CONDITION__DATE_EQUALS:
        case FILTER_CONDITION__DATE_NOT_EQUAL:
        case FILTER_CONDITION__DATE_SAME_OR_BEFORE:
        case FILTER_CONDITION__DATE_BEFORE:
        case FILTER_CONDITION__DATE_SAME_OR_AFTER:
        case FILTER_CONDITION__DATE_AFTER:
          onChange(id, {
            condition: newCondition,
            state: {
              ...state,
              threshold: isValidDateString(newValue) ? newValue : null,
            },
          });
          break;
        case FILTER_CONDITION__INCLUDE:
          onChange(id, {
            condition: newCondition,
            state: {
              ...state,
              include: isArray(newValue) ? newValue : [],
            },
            meta: {
              ...meta,
              labels: newLabels,
            },
          });
          break;
        case FILTER_CONDITION__EXCLUDE:
          onChange(id, {
            condition: newCondition,
            state: {
              ...state,
              exclude: isArray(newValue) ? newValue : [],
            },
            meta: {
              ...meta,
              labels: newLabels,
            },
          });
          break;
        default: {
          if (newCondition !== condition) {
            onChange(id, {
              condition: newCondition,
            });
          }
        }
      }
    },
    [id, state, meta, condition, valueType, onChange],
  );
  const handleOnDelete = useCallback(() => onDelete(id), [id, onDelete]);
  const handleOnSelectCondition = useCallback(
    (newCondition) => {
      handleOnChange(value, {
        newCondition,
      });
      if ((!active || !filterConditionNeedsValue(newCondition)) && onSubmit) {
        onSubmit();
      }
    },
    [value, handleOnChange, active, onSubmit],
  );
  const jsonSchema = meta && meta.jsonSchema;
  const knownOptions = useMemo(() => {
    if (jsonSchema) {
      if (jsonSchema.enum) {
        return map(jsonSchema.enum, (label) => ({
          value: label,
          label,
        }));
      }
      if (has(jsonSchema, 'const')) {
        return [toChoice(jsonSchema)];
      }
      if (isChoices(jsonSchema.anyOf)) {
        return map(jsonSchema.anyOf, toChoice);
      }
      if (isChoices(jsonSchema.oneOf)) {
        return map(jsonSchema.oneOf, toChoice);
      }
      if (jsonSchema.type === 'array' && jsonSchema.items) {
        if (isChoices(jsonSchema.items.anyOf)) {
          return map(jsonSchema.items.anyOf, toChoice);
        }
        if (isChoices(jsonSchema.items.oneOf)) {
          return map(jsonSchema.items.oneOf, toChoice);
        }
      }
    }
    return null;
  }, [jsonSchema]);
  const conditionsOptions = useMemo(
    () =>
      map(meta && meta.conditions, (anotherCondition) => {
        let operator;
        switch (anotherCondition) {
          case FILTER_CONDITION__INCLUDE:
            operator = t('components:Filters.operators.exactMatch');
            break;
          case FILTER_CONDITION__EQUALS:
          case FILTER_CONDITION__DATE_EQUALS:
            operator = t('components:Filters.operators.equal');
            break;
          case FILTER_CONDITION__EXCLUDE:
            operator = t('components:Filters.operators.differentFrom');
            break;
          case FILTER_CONDITION__NOT_EQUAL:
          case FILTER_CONDITION__DATE_NOT_EQUAL:
            operator = t('components:Filters.operators.notEqual');
            break;
          case FILTER_CONDITION__TEXT:
            operator = t('components:Filters.operators.text');
            break;
          case FILTER_CONDITION__DATE_SAME_OR_AFTER:
            operator = t('components:Filters.operators.sameOrAfter');
            break;
          case FILTER_CONDITION__MINIMUM:
            operator = t('components:Filters.operators.sameOrGreater');
            break;
          case FILTER_CONDITION__DATE_AFTER:
            operator = t('components:Filters.operators.after');
            break;
          case FILTER_CONDITION__EXCLUSIVE_MINIMUM:
            operator = t('components:Filters.operators.greater');
            break;
          case FILTER_CONDITION__DATE_SAME_OR_BEFORE:
            operator = t('components:Filters.operators.sameOrBefore');
            break;
          case FILTER_CONDITION__MAXIMUM:
            operator = t('components:Filters.operators.sameOrLess');
            break;
          case FILTER_CONDITION__DATE_BEFORE:
            operator = t('components:Filters.operators.before');
            break;
          case FILTER_CONDITION__EXCLUSIVE_MAXIMUM:
            operator = t('components:Filters.operators.less');
            break;
          case FILTER_CONDITION__SEARCH_TERMS:
            operator = t('components:Filters.operators.almostEqual');
            break;
          case FILTER_CONDITION__EXISTS:
          case FILTER_CONDITION__NON_EMPTY:
            operator = t('components:Filters.operators.exists');
            break;
          case FILTER_CONDITION__DOES_NOT_EXIST:
          case FILTER_CONDITION__EMPTY:
            operator = t('components:Filters.operators.doesNotExist');
            break;
          default:
            operator = ':';
        }

        return {
          value: anotherCondition,
          label: operator,
        };
      }),
    [t, meta],
  );

  const getSubscription = useCallback(
    (searchText) => {
      if (!optionsSubscription) {
        return null;
      }
      const useSearch = !(meta && meta.noSearch);
      switch (condition) {
        case FILTER_CONDITION__INCLUDE:
        case FILTER_CONDITION__EXCLUDE: {
          const params = {
            type,
            condition,
            state: state ?? {},
            settings,
          };
          if (knownOptions && knownOptions.length < 1000) {
            // NOTE: We are expecting at most this number of results.
            //       "+1" is needed to accommodate NULL value.
            params.resultsPerPage = knownOptions.length + 1;
          } else {
            params.searchText = useSearch ? trim(searchText) : undefined;
          }
          return optionsSubscription(params);
        }
        default:
          return null;
      }
    },
    [optionsSubscription, meta, type, condition, state, settings, knownOptions],
  );

  const getFilterOptions = useCallback(
    (fetchedOptions, subscriptionId) => {
      const allOptions = [...fetchedOptions];
      let currentValue;
      switch (condition) {
        case FILTER_CONDITION__INCLUDE:
          currentValue = state && state.include;
          break;
        case FILTER_CONDITION__EXCLUDE:
          currentValue = state && state.exclude;
          break;
        default:
        // ...
      }
      const byValue = keyBy(allOptions, 'value');
      const getKnownLabel = (val) => meta && meta.labels && meta.labels[val];
      if (isArray(currentValue)) {
        forEach(currentValue, (val) => {
          if (!byValue[val]) {
            byValue[val] = {
              value: val,
              label: getKnownLabel(val),
            };
            allOptions.push(byValue[val]);
          }
        });
      }
      const knownOptionsByValue = keyBy(knownOptions, 'value');
      return map(allOptions, (option) => {
        let label;
        if (
          knownOptionsByValue[option.value] &&
          knownOptionsByValue[option.value].label
        ) {
          label = knownOptionsByValue[option.value].label;
        } else {
          label = isNil(option.label) ? toString(option.value) : option.label;
        }
        return {
          value: option.value,
          label: optionsDisplayCounts
            ? `${label} (${option[`_count_${subscriptionId}`] || 0})`
            : label,
          chipLabel: label,
        };
      });
    },
    [condition, state, meta, knownOptions, optionsDisplayCounts],
  );

  // Value field

  const handleOnValueChange = useCallback(
    (eventOrValue) => {
      if (eventOrValue && eventOrValue.target) {
        handleOnChange(eventOrValue.target.value);
      } else {
        handleOnChange(eventOrValue);
      }
    },
    [handleOnChange],
  );

  const handleOnSelectValueChange = useCallback(
    (newValue) => {
      if (isArray(newValue)) {
        handleOnChange(
          newValue.map((item) => item.value),
          {
            newLabels: mapValues(
              keyBy(newValue, 'value'),
              (option) => option.chipLabel || option.label,
            ),
          },
        );
      } else if (isPlainObject(newValue)) {
        handleOnChange(newValue.value, {
          newLabels: {
            [newValue.value]: newValue.chipLabel || newValue.label,
          },
        });
      }
    },
    [handleOnChange],
  );

  const ddpConnector = useContext(DDPContext);
  const store = useStore();
  const delay = useDelay({
    noInitialDelay: true,
  });

  const handleLoadOptions = useCallback(
    async (inputValue) => {
      const shouldContinue = await delay(2000);
      if (!shouldContinue) {
        return [];
      }
      const { id: subscriptionId, resource } =
        ddpConnector.subsManager.getOrCreateResource(
          getSubscription(inputValue),
        );
      const handle = resource.require();
      return handle.promise
        .then(() => {
          const selector = getItemsSelector(
            optionsSelector,
            `_pagination_${subscriptionId}`,
          );
          return getFilterOptions(selector(store.getState()), subscriptionId);
        })
        .then(
          (filterOptions) => {
            handle.release();
            return filterOptions;
          },
          (error) => {
            handle.release();
            throw error;
          },
        );
    },
    [
      ddpConnector,
      delay,
      optionsSelector,
      getSubscription,
      getFilterOptions,
      store,
    ],
  );

  const renderFilterInput = () => {
    switch (condition) {
      case FILTER_CONDITION__INCLUDE:
      case FILTER_CONDITION__EXCLUDE: {
        return (
          <FormItem
            validateStatus={submitFailed && !value ? 'danger' : undefined}
            help={
              submitFailed && !value ? t('forms:validation.requiredField') : ''
            }
          >
            <AsyncSelect
              key={segmentValue}
              data-testid="filters-filter-input"
              value={getFilterOptions([])}
              onChange={handleOnSelectValueChange}
              isMulti
              placeholder={t('forms:value.placeholder')}
              loadOptions={handleLoadOptions}
              defaultOptions
            />
          </FormItem>
        );
      }
      case FILTER_CONDITION__TEXT:
      case FILTER_CONDITION__SEARCH_TERMS: {
        return (
          <FormItem
            validateStatus={submitFailed && !value ? 'danger' : undefined}
            help={
              submitFailed && !value ? t('forms:validation.requiredField') : ''
            }
          >
            <Input
              data-testid="filters-filter-input"
              value={value || ''}
              onChange={handleOnValueChange}
              placeholder={t('forms:value.placeholder')}
            />
          </FormItem>
        );
      }
      case FILTER_CONDITION__DATE_EQUALS:
      case FILTER_CONDITION__DATE_NOT_EQUAL:
      case FILTER_CONDITION__DATE_SAME_OR_BEFORE:
      case FILTER_CONDITION__DATE_BEFORE:
      case FILTER_CONDITION__DATE_SAME_OR_AFTER:
      case FILTER_CONDITION__DATE_AFTER:
        return (
          <FormItem
            validateStatus={submitFailed && !value ? 'danger' : undefined}
            help={
              submitFailed && !value ? t('forms:validation.requiredField') : ''
            }
          >
            <InputDate
              data-testid="filters-filter-input"
              style={{
                width: 'auto',
              }}
              value={value || null}
              onChange={handleOnValueChange}
              onBlur={onSubmit}
            />
          </FormItem>
        );
      case FILTER_CONDITION__EQUALS:
      case FILTER_CONDITION__NOT_EQUAL:
      case FILTER_CONDITION__MINIMUM:
      case FILTER_CONDITION__EXCLUSIVE_MINIMUM:
      case FILTER_CONDITION__MAXIMUM:
      case FILTER_CONDITION__EXCLUSIVE_MAXIMUM: {
        switch (settings && settings.valueType) {
          case 'string': {
            return (
              <FormItem
                validateStatus={submitFailed && !value ? 'danger' : undefined}
                help={
                  submitFailed && !value
                    ? t('forms:validation.requiredField')
                    : ''
                }
              >
                <Input
                  data-testid="filters-filter-input"
                  value={value || ''}
                  onChange={handleOnValueChange}
                  placeholder={t('forms:value.placeholder')}
                />
              </FormItem>
            );
          }
          default: {
            // NOTE: We treat 'number' as the default one,
            //       because valueTypes derived from JSON Schema,
            //       and currently it will only be specified for
            //       filters derived from variables.
            return (
              <FormItem
                validateStatus={submitFailed && !value ? 'danger' : undefined}
                help={
                  submitFailed && !value
                    ? t('forms:validation.requiredField')
                    : ''
                }
              >
                <InputNumber
                  data-testid="filters-filter-input"
                  value={value ?? null}
                  onChange={handleOnValueChange}
                  onBlur={onSubmit}
                  placeholder={t('forms:value.placeholder')}
                />
              </FormItem>
            );
          }
        }
      }
      default:
        return null;
    }
  };

  const handleOnSelectSegment = useCallback(
    (newSegmentValue) => {
      if (onSelectSegment) {
        onSelectSegment(id, newSegmentValue);
      }
    },
    [onSelectSegment, id],
  );

  return (
    <InputGroup>
      <FormItem
        validateStatus={submitFailed && !type ? 'danger' : undefined}
        help={submitFailed && !type ? t('forms:validation.requiredField') : ''}
      >
        <Select
          data-testid="filters-search-input"
          options={segmentOptions}
          value={segmentValue}
          onChange={handleOnSelectSegment}
          placeholder={name || t('filters', { count: 1 })}
        />
      </FormItem>
      <FormItem
        validateStatus={submitFailed && !condition ? 'danger' : undefined}
        help={
          submitFailed && !condition ? t('forms:validation.requiredField') : ''
        }
      >
        <Select
          data-testid="filters-filter-select-condition"
          value={condition}
          onChange={handleOnSelectCondition}
          options={conditionsOptions}
          placeholder={t('forms:condition.label')}
          isDisabled={isEmpty(conditionsOptions)}
        />
      </FormItem>
      {renderFilterInput()}
      <Button
        onClick={handleOnDelete}
        icon={<TrashIcon />}
        data-testid="filters-filter-delete"
      />
    </InputGroup>
  );
};

Filter.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  type: PropTypes.string,
  condition: PropTypes.string,
  meta: PropTypes.shape({
    conditions: PropTypes.arrayOf(PropTypes.string),
    labels: PropTypes.objectOf(PropTypes.string),
    // eslint-disable-next-line react/forbid-prop-types
    jsonSchema: PropTypes.any,
    noSearch: PropTypes.bool,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  state: PropTypes.objectOf(PropTypes.any),
  // eslint-disable-next-line react/forbid-prop-types
  settings: PropTypes.objectOf(PropTypes.any),
  active: PropTypes.bool,
  onDelete: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  optionsDisplayCounts: PropTypes.bool,
  optionsSubscription: PropTypes.func,
  optionsSelector: PropTypes.shape({
    all: PropTypes.func,
  }),
  segmentOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  segmentValue: PropTypes.string,
  onSelectSegment: PropTypes.func,
  submitFailed: PropTypes.bool,
};

Filter.defaultProps = {
  name: null,
  type: null,
  condition: null,
  meta: null,
  state: null,
  settings: null,
  active: false,
  optionsDisplayCounts: false,
  optionsSubscription: null,
  optionsSelector: null,
  segmentOptions: [],
  segmentValue: null,
  onSelectSegment: null,
  submitFailed: false,
};

export default Filter;
