import map from 'lodash/map';
import first from 'lodash/first';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';
import forEach from 'lodash/forEach';
import keyBy from 'lodash/keyBy';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import includes from 'lodash/includes';
import filter from 'lodash/filter';
import React, {
  useRef,
  useMemo,
  useState,
  forwardRef,
  useContext,
  useCallback,
} from 'react';
import { useDDPCall, useDDPSubscription } from '@zedoc/ddp-connector';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Random } from '@zedoc/random';
import { getLeafErrors } from '@zedoc/check-schema';
import { useReconcile } from '@zedoc/react-hooks';
import {
  patientDetails,
  apiZedocOneProject,
  apiZedocProjectVariables,
  apiZedocCreateActivity,
  apiZedocUpdateActivity,
} from '../../../common/api/zedoc';
import {
  ACTIVITY_STATE_MACHINE,
  ACTIVITY_STATE__INITIAL,
  ACTIVITY_STATE__SCHEDULED,
  ACTIVITY_STATE__SUSPENDED,
  PROJECT_MILESTONE_TYPE__CUSTOM,
} from '../../../common/constants';
import {
  PROJECT_PROFILE_ATTACH_ACTIVITY,
  PATIENT_MILESTONE_CREATE_ACTIVITY,
  PATIENT_MILESTONE_UPDATE_ACTIVITY,
  PATIENT_ACCESS_PATIENT_PII_VARIABLES,
} from '../../../common/permissions';
import { default as ProjectSelect } from '../../../common/selectors/Project';
import { default as ProjectMilestoneSelect } from '../../../common/selectors/ProjectMilestone';
import Variable from '../../../common/models/Variable';
import Activity from '../../../common/models/Activity';
import PermissionsDomain from '../../../common/models/PermissionsDomain';
import { default as RecipientSelect } from '../../../common/selectors/Recipient';
import { default as ParticipationSelect } from '../../../common/selectors/Participation';
import { default as ActivitySelect } from '../../../common/selectors/Activity';
import { default as VariableSelect } from '../../../common/selectors/Variable';
import Modal from '../Modal';
import Stack from '../../../common/components/primitives/Stack';
import Loading from '../../../common/components/Loading';
import { notifyError, notifySuccess } from '../../../utils/notify';
import Form from '../../forms/Form';
import usePermission from '../../../utils/usePermission';
import usePermissionsRealm from '../../../utils/usePermissionsRealm';
import FormFieldState, {
  getPayload,
  getNonEditableKeys,
  coincidesWithNonEditableKeys,
} from '../ProjectProfile/FormFieldState';
import FormFieldMilestone from './FormFieldMilestone';
import FormFieldContext from './FormFieldContext';

// TODO: The SUSPENDED state is formally allowed so it's present in the state machine definition
//       but we don't currently want to expose it in the ui so as not to create false expectations.
//       Once the support for it is complete the following lines should be removed.
const stateMachine = {
  ...ACTIVITY_STATE_MACHINE,
  transitions: filter(ACTIVITY_STATE_MACHINE.transitions, (transition) => {
    return (
      transition.from !== ACTIVITY_STATE__SUSPENDED &&
      transition.to !== ACTIVITY_STATE__SUSPENDED
    );
  }),
};

const ConnectedFormFieldState = forwardRef((props, forwardedRef) => {
  const { state, payload } = useContext(FormFieldContext);
  return (
    <FormFieldState
      ref={forwardedRef}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      payload={payload}
      previousState={state}
      stateMachine={stateMachine}
    />
  );
});

const ConnectedFormFieldMilestone = forwardRef((props, forwardedRef) => {
  const { trackId, projectId } = useContext(FormFieldContext);
  return (
    <FormFieldMilestone
      ref={forwardedRef}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      trackId={trackId}
      projectId={projectId}
    />
  );
});

const EditActivity = ({
  projectId,
  recipientId,
  participationId,
  activityId,
  onCancel,
  onSubmitted,
  visible,
}) => {
  const { t, i18n } = useTranslation();
  const activityForm = useRef();

  const participation = useSelector(
    ParticipationSelect.one().whereIdEquals(participationId),
  );

  const { trackId: participationTrackId } = participation || {};

  const { ready: projectReady } = useDDPSubscription(
    projectId &&
      apiZedocOneProject.withParams({
        projectId,
      }),
  );

  const project = useSelector(
    useMemo(
      () =>
        ProjectSelect.one()
          .whereIdEquals(projectId)
          .lookup({
            from: VariableSelect.all().satisfying((variable) =>
              variable.isActivity(),
            ),
            as: 'variables',
            foreignKey: '_id',
            key: (doc, docId, variables) => {
              return map(
                doc.variables,
                ({ id, compulsory, disableUserEdits }) => {
                  const variable = first(variables[id]);
                  if (variable) {
                    return new Variable({
                      ...variable.raw,
                      disableUserEdits,
                      compulsory,
                    });
                  }
                  return new Variable({
                    _id: id,
                    compulsory,
                    disableUserEdits,
                  });
                },
              );
            },
          }),
      [projectId],
    ),
  );

  const { variables } = project || {};

  const recipient = useSelector(
    RecipientSelect.one().whereIdEquals(recipientId),
  );

  const activity = useSelector(ActivitySelect.one().whereIdEquals(activityId));

  const { ready: variablesReady } = useDDPSubscription(
    projectId &&
      apiZedocProjectVariables.withParams({
        projectId,
      }),
  );

  const { ready: patientDetailsReady } = useDDPSubscription(
    projectId &&
      recipientId &&
      patientDetails.withParams({
        projectId,
        recipientId,
      }),
  );

  const { ddpCall, ddpIsPending } = useDDPCall();

  const trackId = activity ? activity.trackId : participationTrackId;

  const defaultCustomMilestone = useSelector(
    ProjectMilestoneSelect.one()
      .where({
        projectId,
        type: PROJECT_MILESTONE_TYPE__CUSTOM,
      })
      .satisfying((milestone) => {
        if (!trackId || !milestone.selectedTracksOnly) {
          return true;
        }
        return includes(milestone.selectedTracks, trackId);
      })
      .sort({
        index: 1,
      }),
  );

  const defaultMilestone = useSelector(
    ProjectMilestoneSelect.one()
      .where({
        projectId,
      })
      .satisfying((milestone) => {
        if (!trackId || !milestone.selectedTracksOnly) {
          return true;
        }
        return includes(milestone.selectedTracks, trackId);
      })
      .sort({
        index: 1,
      }),
  );

  const defaultMilestoneId = defaultCustomMilestone
    ? defaultCustomMilestone._id
    : defaultMilestone && defaultMilestone._id;

  const { domainsReady, permissionsDomains: allowedDomains } =
    usePermissionsRealm([PATIENT_MILESTONE_CREATE_ACTIVITY], {
      scope: project ? project.getDomains() : [],
    });

  const defaultActivity = useMemo(() => {
    return new Activity({
      state: ACTIVITY_STATE__SCHEDULED,
      milestoneId: defaultMilestoneId,
      ownership: map(
        PermissionsDomain.extractFundamentalDomains(map(allowedDomains, '_id')),
        (domain) => ({
          domain,
        }),
      ),
    });
  }, [allowedDomains, defaultMilestoneId]);

  const evaluateNextActivity = useCallback(
    (formValues) => {
      const rawActivity = activity
        ? cloneDeep(activity.raw)
        : cloneDeep(defaultActivity.raw);
      const variablesById = keyBy(variables, '_id');
      forEach(formValues.variables, (value, variableId) => {
        const variable = variablesById[variableId];
        if (variable && variable.isActivity() && value !== undefined) {
          variable.setValue(rawActivity, value);
        }
      });
      return new Activity(rawActivity);
    },
    [activity, defaultActivity, variables],
  );

  const [nextActivity, setNextActivity] = useState(activity || defaultActivity);

  const handleOnChange = useCallback(
    (formValues) => {
      setNextActivity(evaluateNextActivity(formValues));
    },
    [evaluateNextActivity],
  );

  const state = activity ? activity.state : ACTIVITY_STATE__INITIAL;

  // NOTE: If nextActivity was never updated, it's theoretically possible
  //       that it will be nullish.
  const nextState = nextActivity ? nextActivity.state : state;

  const payload = useReconcile(
    useMemo(() => {
      return getPayload(ACTIVITY_STATE_MACHINE, nextActivity, activity);
    }, [nextActivity, activity]),
  );

  const nonEditableKeys = useReconcile(
    useMemo(() => {
      return getNonEditableKeys(
        ACTIVITY_STATE_MACHINE,
        payload,
        state,
        nextState,
      );
    }, [payload, state, nextState]),
  );

  const validateConstraints = useCallback(
    (formValues) => {
      const rawActivity = evaluateNextActivity(formValues).raw;
      const modelErrors = getLeafErrors(Activity.validate(rawActivity));
      const formErrors = {
        variables: {},
      };
      forEach(variables, (variable) => {
        const variableKey = variable.getKey(rawActivity);
        if (
          variable.isActivity() &&
          !coincidesWithNonEditableKeys(nonEditableKeys, variableKey)
        ) {
          const error = get(modelErrors, variableKey);
          if (error) {
            formErrors.variables[variable._id] = error;
          }
        }
      });
      if (!isEmpty(formErrors.variables)) {
        activityForm.current.setErrors(formErrors);
        return Promise.reject(
          new Error('confirmations:validateQuestionnaire.error'),
        );
      }
      return Promise.resolve(formValues);
    },
    [variables, nonEditableKeys, evaluateNextActivity],
  );

  const handleOnOk = useCallback(() => {
    if (ddpIsPending) {
      return;
    }
    if (activityId) {
      activityForm.current
        .submit()
        .then(validateConstraints)
        .then((formValues) => {
          return ddpCall(
            apiZedocUpdateActivity.withParams({
              correlationId: Random.id(),
              activityId,
              ...formValues,
            }),
          )
            .then(({ details }) => {
              if (onSubmitted) {
                return onSubmitted(details);
              }
              return undefined;
            })
            .then(notifySuccess(t('confirmations:editActivity.success')));
        })
        .then(onCancel)
        .catch(notifyError());
    } else {
      activityForm.current
        .submit()
        .then(validateConstraints)
        .then((formValues) => {
          return ddpCall(
            apiZedocCreateActivity.withParams(
              omitBy(
                {
                  correlationId: Random.id(),
                  participationId,
                  ...formValues,
                },
                isNil,
              ),
            ),
          )
            .then(({ details }) => {
              if (onSubmitted) {
                return onSubmitted(details);
              }
              return undefined;
            })
            .then(notifySuccess(t('confirmations:addActivity.success')));
        })
        .then(onCancel)
        .catch(notifyError());
    }
  }, [
    activityForm,
    participationId,
    activityId,
    onCancel,
    onSubmitted,
    t,
    ddpCall,
    ddpIsPending,
    validateConstraints,
  ]);

  const loading =
    !domainsReady || !projectReady || !variablesReady || !patientDetailsReady;

  const canCreateActivity = usePermission(PROJECT_PROFILE_ATTACH_ACTIVITY, {
    relativeTo: participation && participation.getDomains(),
  });

  const canUpdateActivity = usePermission(PATIENT_MILESTONE_UPDATE_ACTIVITY, {
    relativeTo: activity && activity.getDomains(),
  });

  const canSeePII = usePermission([PATIENT_ACCESS_PATIENT_PII_VARIABLES], {
    relativeTo: recipient && recipient.getDomains(),
  });

  const schema = useMemo(() => {
    const newSchema = {
      type: 'object',
      properties: {
        variables: {
          type: 'object',
          required: [],
          properties: {},
          dependencies: {},
        },
      },
    };
    forEach(variables, (variable) => {
      if (variable) {
        if (variable.isPII() && !canSeePII) {
          newSchema.properties.variables.properties[variable._id] = false;
        } else {
          newSchema.properties.variables.properties[variable._id] =
            variable.getJsonSchema({
              projectId,
              language: i18n.language,
              allowedDomains,
            });
          if (variable.compulsory) {
            newSchema.properties.variables.required.push(variable._id);
          }
        }
        // switch (variable._id) {
        //   case VARIABLE_ID__PATIENT_BASELINE: {
        //     newSchema.properties.variables.properties[`${variable._id}:overwrite`] = {
        //       type: 'boolean',
        //     };
        //     break;
        //   }
        //   default: {
        //     // ...
        //   }
        // }
      }
    });
    return newSchema;
  }, [canSeePII, allowedDomains, variables, i18n.language, projectId]);

  const initialValues = useMemo(() => {
    if (loading) {
      return null;
    }
    const allVariables = {
      variables: {},
    };
    const context = {
      activity: activity || defaultActivity,
    };
    forEach(variables, (variable) => {
      const value = variable.getFromContext(context);
      if (!isNil(value)) {
        allVariables.variables[variable._id] = value;
      }
    });
    return allVariables;
  }, [loading, variables, activity, defaultActivity]);

  const isNewActivity = !activityId;

  const fields = useMemo(() => {
    const newFields = {
      '': {
        children: ['variables'],
      },
      variables: {
        label: '',
        children: [],
      },
    };
    forEach(variables, (variable) => {
      const field = {
        testLabel: variable.name,
        disabled:
          !variable.isEditable(isNewActivity) ||
          coincidesWithNonEditableKeys(nonEditableKeys, variable.getKey()),
      };
      newFields[`variables.${variable._id}`] = field;
      newFields.variables.children.push(variable._id);
      if (variable.isPII() && !canSeePII) {
        // NOTE: The reason this is needed is because schema for PII fields
        //       will be "false" and so it will not have any "title" assigned to it.
        const variableSchema = variable.getJsonSchema({
          projectId,
          language: i18n.language,
        });
        if (variableSchema) {
          field.label = variableSchema.title;
        }
      }
      if (variable.isActivityState()) {
        field.component = ConnectedFormFieldState;
      }
      if (variable.isActivityMilestoneId()) {
        field.component = ConnectedFormFieldMilestone;
      }
    });
    return newFields;
  }, [
    canSeePII,
    i18n.language,
    variables,
    isNewActivity,
    projectId,
    nonEditableKeys,
  ]);

  const fieldContext = useReconcile({
    projectId,
    recipientId,
    participationId,
    trackId,
    state,
    payload,
  });

  return (
    <Modal
      data-testid="activity-dialog"
      title={activity || loading ? t('editActivity') : t('addActivity')}
      onOk={handleOnOk}
      okText={t('save')}
      isOkDisabled={
        (!!activity && !canUpdateActivity) || (!activity && !canCreateActivity)
      }
      onCancel={onCancel}
      visible={visible}
      confirmLoading={loading || ddpIsPending}
    >
      <Stack space={4}>
        {!loading && (
          <FormFieldContext.Provider value={fieldContext}>
            <Form
              data-testid="activity-form"
              key={activityId}
              ref={activityForm}
              name={
                activityId
                  ? `activity_${participationId}_${activityId}`
                  : `activity_${participationId}`
              }
              initialValues={initialValues}
              onChange={handleOnChange}
              schema={schema}
              fields={fields}
            />
          </FormFieldContext.Provider>
        )}
        {loading && <Loading />}
      </Stack>
    </Modal>
  );
};

EditActivity.propTypes = {
  projectId: PropTypes.string.isRequired,
  recipientId: PropTypes.string,
  participationId: PropTypes.string,
  activityId: PropTypes.string,
  onCancel: PropTypes.func,
  onSubmitted: PropTypes.func,
  visible: PropTypes.bool,
};

EditActivity.defaultProps = {
  recipientId: null,
  participationId: null,
  activityId: null,
  onCancel: null,
  onSubmitted: null,
  visible: true,
};

export default EditActivity;
