import ApiSpec from '../ApiSpec';
import Id from '../../schemata/Id';
import {
  PROJECT_ATTACH_MILESTONE,
  // PROJECT_CREATE_MILESTONE,
  PROJECT_UPDATE_MILESTONE,
  PROJECT_DELETE_MILESTONE,
} from '../../permissions';
import { QUESTIONNAIRE_ASSIGNEE_TYPE_VALUES } from '../../constants';

export const createMilestone = new ApiSpec({
  name: 'api.collections.Projects.createMilestone',
  permissions: [
    // FIXME: Use PROJECT_CREATE_MILESTONE to determine the resulting milestone ownership.
    PROJECT_ATTACH_MILESTONE,
    // PROJECT_CREATE_MILESTONE,
  ],
  schema: {
    type: 'object',
    required: ['projectId', 'name'],
    properties: {
      projectId: Id,
      name: {
        type: 'string',
        minLength: 1,
      },
      description: {
        type: 'string',
      },
      questionnaires: {
        type: 'array',
        items: {
          type: 'object',
          required: ['identifier', 'assigneeType'],
          properties: {
            identifier: {
              type: 'string',
              minLength: 1,
            },
            assigneeType: {
              type: 'string',
              enum: QUESTIONNAIRE_ASSIGNEE_TYPE_VALUES,
            },
            delegatedToPatient: {
              type: 'boolean',
            },
          },
        },
      },
    },
  },
});

export const updateMilestone = new ApiSpec({
  name: 'api.collections.Projects.updateMilestone',
  permissions: [PROJECT_UPDATE_MILESTONE],
  schema: {
    type: 'object',
    required: ['milestoneId', 'name', 'questionnaires'],
    properties: {
      milestoneId: Id,
      name: {
        type: 'string',
        minLength: 1,
      },
      description: {
        type: 'string',
      },
      questionnaires: {
        type: 'array',
        items: {
          type: 'object',
          required: ['identifier', 'assigneeType'],
          properties: {
            identifier: {
              type: 'string',
              minLength: 1,
            },
            assigneeType: {
              type: 'string',
              enum: QUESTIONNAIRE_ASSIGNEE_TYPE_VALUES,
            },
            delegatedToPatient: {
              type: 'boolean',
            },
          },
        },
        minItems: 1,
      },
    },
  },
});

export const removeCustomMilestone = new ApiSpec({
  name: 'api.collections.Projects.removeCustomMilestone',
  permissions: [PROJECT_DELETE_MILESTONE],
  schema: {
    type: 'object',
    required: ['milestoneId'],
    properties: {
      milestoneId: Id,
    },
  },
});
