import {
  DeleteOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
  EditOutlined,
} from '@ant-design/icons';
import map from 'lodash/map';
// import isEmpty from 'lodash/isEmpty';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Questionnaire as QuestionnaireModel,
  Question as QuestionModel,
} from '@zedoc/questionnaire';
import {
  Element,
  // Field,
} from '@zedoc/react-questionnaire';
import Button from '../../../common/components/Button';
import Sidebar from '../../../common/components/primitives/Sidebar';
import Box from '../../../common/components/primitives/Box';
import Cluster from '../../../common/components/primitives/Cluster';
import Stack from '../../../common/components/primitives/Stack';
import Collection from '../../../common/components/Collection';
import { CollapseItem } from '../../../common/components/Collapse';
import Modal from '../../../components/dialogs/Modal';
// import FormFieldError from './FormFieldError';

const CollectionNested = ({
  elements,
  question,
  questionnaire,
  onAppend,
  Section,
  // eslint-disable-next-line react/prop-types
  questionsIds,
  // eslint-disable-next-line react/prop-types
  valueSetId,
  // eslint-disable-next-line react/prop-types
  nameId,
  // eslint-disable-next-line react/prop-types
  isEditing,
}) => {
  const { t } = useTranslation();

  const [visible, setVisible] = useState(false);

  const handleModalOpen = () => setVisible(true);
  const handleModalClose = () => setVisible(false);

  const title = question.getTitle();
  const items = map(elements, (elementId, i) => ({
    key: elementId,
    children: (
      <Element key={elementId} id={elementId}>
        {({ handleRemove, handleMoveUp, handleMoveDown }) => (
          <Sidebar
            space={1}
            side="right"
            align="flex-start"
            sidebar={
              <Stack space={1}>
                <Button
                  icon={<ArrowUpOutlined />}
                  disabled={i === 0}
                  onClick={handleMoveUp}
                  ghost
                  block
                />
                <Button
                  icon={<ArrowDownOutlined />}
                  disabled={i === elements.length - 1}
                  onClick={handleMoveDown}
                  ghost
                  block
                />
              </Stack>
            }
          >
            <Box.Primary boxShadow="base">
              <CollapseItem
                title={`${pluralize(title, 1)} ${i + 1}`}
                titleToken="title3"
                content={
                  <Stack>
                    <Section
                      questionnaire={questionnaire}
                      sectionId={question.id}
                      questionsIds={questionsIds}
                      valueSetId={valueSetId}
                      nameId={nameId}
                      isEditing={isEditing}
                      isNested
                    />
                    <Cluster justify="flex-end">
                      <Button
                        data-testid={`button-remove-${title}`}
                        type="danger"
                        icon={<DeleteOutlined />}
                        onClick={handleRemove}
                      >
                        {t('remove')}
                      </Button>
                    </Cluster>
                    {/* <Field
                      component={FormFieldError}
                      name="value"
                    /> */}
                  </Stack>
                }
              />
            </Box.Primary>
          </Sidebar>
        )}
      </Element>
    ),
  }));

  return (
    <>
      <Button
        data-testid={`button-${title}`}
        type="primary"
        icon={<EditOutlined />}
        onClick={handleModalOpen}
        block
      >
        {/* {'('}
        {!isEmpty(elements) ? elements.length : 0}
        {') '} */}
        {title}
      </Button>
      <Modal
        title={title}
        visible={visible}
        onCancel={handleModalClose}
        footer={
          <Button
            data-testid="button-close"
            onClick={handleModalClose}
            hasBorder={false}
          >
            {t('close')}
          </Button>
        }
      >
        <Collection
          data-testid={question.getSlug()}
          items={items}
          // Empty div to align add button to the right
          title={<div />}
          addTitle={`${t('add')} ${pluralize(title.toLowerCase(), 1)}`}
          // FIXME: Translate
          emptyDescription={`No ${title}`}
          onAdd={onAppend}
        />
      </Modal>
    </>
  );
};

CollectionNested.propTypes = {
  elements: PropTypes.arrayOf(PropTypes.string),
  question: PropTypes.instanceOf(QuestionModel).isRequired,
  questionnaire: PropTypes.instanceOf(QuestionnaireModel).isRequired,
  onAppend: PropTypes.func.isRequired,
  Section: PropTypes.elementType.isRequired,
};

CollectionNested.defaultProps = {
  elements: [],
};

export default CollectionNested;
